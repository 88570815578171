import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import './styles/footer.css';

const Footer = () => (
  <footer>
    <div className="footer-content">
      <div className="footer-content footer-content-left">
        <img src="/wp-content/uploads/2019/02/company_logo2.png" height={50} style={{paddingLeft: '40px'}} alt="Signature Flooring" />
      </div>
      <div className="footer-content footer-content-right">
        <div className="footer-content-right right-top">
          <div className="right-top col-1">
            <div>
              <span className="footer-column-header">Resources</span><br/>
              <a href="/r/resources/specs" className="footer-links">Specifications</a><br/>
              <a href="/r/resources/installation" className="footer-links">Installation</a><br/>
              <a href="/r/resources/sundries" className="footer-links">Sundries</a><br/>
              <a href="/r/resources/maintenance-resources" className="footer-links">Care & Maintenance</a><br/>
              <a href="/r/resources/warranties" className="footer-links">Warranties</a><br/>
              <a href="/r/resources/antimicrobial" className="footer-links">Antimicrobial</a><br/>
              <a href="/r/resources/cads" className="footer-links">CADS</a><br/>
              {/*<a href="##" className="footer-links">White Papers</a><br/>*/}
              </div>
            </div>
            <div className="right-top col-2">
              <div>
              <span className="footer-column-header">Company</span><br/>
              <a href="/aboutus" className="footer-links">About Us</a><br/> 
              <a href="/sustainability" className="footer-links">Sustainability</a><br/>
              <a href="/tradeshows" className="footer-links">Trade Shows</a><br/>
              </div>
            </div>
            <div className="right-top col-3">
              <div>
              <span className="footer-column-header">Media</span><br/>
              <a href="/media/pressreleases" className="footer-links">Press Releases</a><br/>
              <a href="/media/ads" className="footer-links">Ads</a><br/>
              <a href="/media/videos" className="footer-links">Videos</a><br/>
              </div>
            </div>
            <div className="right-top col-4">
              <div>
                <a href="/salesreps"><button className="find-rep-button">Find a Rep</button></a>
              </div>
            </div>
          </div>
          <div className="footer-content-right right-bottom">
            <div className="footer-social-icons">
              <a href="https://www.facebook.com/signaturefloorsforsignaturespaces" target="_new"><i className="fa fa-facebook-f"></i></a>&nbsp;
              <a href="https://www.instagram.com/signature__flooring/" target="_new"><i className="fa fa-instagram"></i></a>&nbsp;
              <a href="https://www.pinterest.com/Signature__Flooring/" target="_new"><i className="fa fa-pinterest"></i></a>&nbsp;
              <a href="https://www.linkedin.com/company/floors-for-signature-spaces/" target="_new"><i className="fa fa-linkedin"></i></a>&nbsp;
            </div>
            <div className="footer-copyright">
              <a href="##">Terms of Use</a>&nbsp;|&nbsp; 
              <a href="##">Privacy Policy</a>&nbsp;|&nbsp;
              {'\u00A9'}2024 Signature Flooring. All rights reserved.
            </div>
          </div>
        </div>
      </div>
     </footer>   
    
);

export default Footer;
