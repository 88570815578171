import React, { Component } from 'react'
import axios from 'axios'
import Slider from 'react-slick'
import './styles/homeblogs.css'

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "grey", height: "50px", width: "40px", marginRight: "-15px", paddingTop: "15px", paddingLeft: "10px" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "grey", height: "50px", width: "40px", marginLeft: "-15px", paddingTop: "15px", paddingLeft: "10px" }}
      onClick={onClick}
    />
  );
}

class HomeBlogs extends Component {

  constructor(props) {
    super(props)

    this.state = {
      whatsnewitems: []
    }
  }

  componentWillMount() {
    axios.get('/wp-json/wp/v2/whats-new?filter[orderby]=display_order&order=desc&per_page=100')
    .then(response => {
      this.setState({
        whatsnewitems: response.data
      })
      
    })
    .catch(errors => console.log(errors))
  }

  render() {
    const { whatsnewitems } = this.state
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };
    return (
      <div className="home-blog-wrapper">
        <div className="home-blog-wrapper home-blog-header">
          <p className="home-blog-header-text">What's New</p>
        </div>
        <div className="home-blog-wrapper home-blog-entries">
          <div className="container">
          {/*<div className="card-group card-group-scroll">*/}
          <div>
            <Slider {...settings}>
            { whatsnewitems.map((item) => (
              <div className="cardPadding" key={item.id}>
              <div className="home-slider-card">
              <a href={item.acf.link} target="_new"><img className="card-img-top" height={'380px'} src={item.acf.image.url} alt={item.title.rendered} /></a>
              <div className="home-slider-card-body">
              <a href={item.acf.link} style={{textDecoration: 'none'}} target="_new"><h5 className="card-title" style={{paddingTop: '5px'}} dangerouslySetInnerHTML={{__html: item.title.rendered}} /></a>
              </div>
              </div>
            </div>
            ))}
            </Slider>
          </div>
        </div>
        </div>
      </div>
    )
  }
}
export default HomeBlogs
