import React from 'react'

import "./styles/signaturenowcomponent.css";

const SignatureNow = () => {
  return (
    
    <div className="signow-wrapper">

    <a href="/now">
      <div className="signow-content-inner">
        <img src="/wp-content/uploads/2023/02/SignatureNOW_HomePage_v6.jpg" width={1280} alt="Signature Now" />
      </div>
    </a>
    </div>
    
  )
}

export default SignatureNow
