import React, { Component } from 'react'
import { Checkbox, CheckboxGroup } from 'react-checkbox-group'
import './styles/productcategories.css'
import axios from 'axios'
import {Helmet} from "react-helmet";

class ProductCategories extends Component {

  constructor(props) {
    super(props)

    this.state = {
      SignatureFlooringSettings: window.SignatureFlooringSettings,
      categoryName: this.props.match.params.categoryname,
      products: [],
      uniqueStylesFound: [],
      uniqueBackingsFound: [],
      uniqueDyeMethodsFound: [],
      uniqueFaceWeightsFound: [],
      uniqueHardSurfaceSizesFound: [],
      uniqueThicknessFound: [],
      uniqueWearLayersFound: [],
      uniqueFinishesFound: [],
      uniqueInstallMethodsFound: [],
      uniqueColorsFound: [],
      selectedStyles: [],
      filterStyles: [],
      filteredFaceWeights: '',
      filterConstruction: [],
      filterDyeMethod: [],
      filterBackings: [],
      filteredPatternTypes: [],
      filteredEndUses: [],
      filteredSegments: [],
      filteredBackings: [],
      filteredHardSurfaceSizes: [],
      filteredThickness: [],
      filteredWearLayers: [],
      filteredFinishes: [],
      filteredInstallMethods: [],
      filteredColors: [],
      filteredShades: [],
      filteredSignatureNowProducts: false,
      signatureNowChecked: false,
      constructionTypes: ["ColorPoint", "Infinity", "Tufted"],
      hardSurfaceShades: ["Brown", "Creame","Gold", "Grey", "Red"],
      colorOptions: ["grey","red","taupe","beige","black","blue","bright","brown","gold","green", "orange", "purple"],
      segments: ["Hospitality", "Senior Living", "Both"],
      patternTypes: ["Solid", "Organic","Linear", "Geometric", "Graphic"],
      enduses: ["Public Space", "Rooms", "Corridor"],
      numberOfFilters: 0,
      numStylesSelected: 0,
      numFaceWeightsSelected: 0,
      numConstructionsSelected: 0,
      numDyeMethodsSelected: 0,
      numBackingsSelected: 0,
      numPatternTypesSelected: 0,
      numEndUsesSelected: 0,
      numSegmentsSelected: 0,
      numHardSurfaceSizesSelected: 0,
      numThicknessSelected: 0,
      numWearLayersSelected: 0,
      numFinishesSelected: 0,
      numInstallMethodsSelected: 0,
      numColorsSelected: 0,
      numHardSurfaceShadeSelected: 0,
      numSignatureNowProducts: 0,
    }

    this.displayFilterOption = this.displayFilterOption.bind(this)
    this.displayMobileFilterOptions = this.displayMobileFilterOptions.bind(this)
    this.getStyleNames = this.getStyleNames.bind(this)
    this.setFaceWeightFilterOptions = this.setFaceWeightFilterOptions.bind(this)
    this.filterSignatureNowProducts = this.filterSignatureNowProducts.bind(this)
    this.getBackings = this.getBackings.bind(this)
    this.getFaceWeights = this.getFaceWeights.bind(this)
    this.setStyleFilterOptions = this.setStyleFilterOptions.bind(this)
    this.setBackingFilterOptions = this.setBackingFilterOptions.bind(this)
    this.filterWithOptions = this.filterWithOptions.bind(this)
    this.constructionTypeFilterOptions = this.constructionTypeFilterOptions.bind(this)
    this.patternTypesFilterOptions = this.patternTypesFilterOptions.bind(this)
    this.segmentsFilterOptions = this.segmentsFilterOptions.bind(this)
    this.endusesFilterOptions = this.endusesFilterOptions.bind(this)
    this.clearAllFilters = this.clearAllFilters.bind(this)
    this.dyeMethodFilterOptions = this.dyeMethodFilterOptions.bind(this)
    this.getHardSurfaceSizes = this.getHardSurfaceSizes.bind(this)
    this.setHardSurfaceSizeFilterOptions = this.setHardSurfaceSizeFilterOptions.bind(this)
    this.getThickness = this.getThickness.bind(this)
    this.setThicknessFilterOptions = this.setThicknessFilterOptions.bind(this)
    this.getWearLayers = this.getWearLayers.bind(this)
    this.setWearLayersFilterOptions = this.setWearLayersFilterOptions.bind(this)
    this.getFinishes = this.getFinishes.bind(this)
    this.setFinishesFilterOptions = this.setFinishesFilterOptions.bind(this)
    this.getInstallMethods = this.getInstallMethods.bind(this)
    this.setInstallMethodsFilterOptions = this.setInstallMethodsFilterOptions.bind(this)
    this.setColorRangeFilterOptions= this.setColorRangeFilterOptions.bind(this)
    this.setHardSurfaceShadesFilterOptions = this.setHardSurfaceShadesFilterOptions.bind(this)

    
    

  }

  componentDidMount() {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const isSignatureNow = params.get('signaturenow');
    console.log('sn', isSignatureNow);
    axios.get('/wp-json/wp/v2/productinfo/?per_page=100&filter[meta_key]=product_category&filter[meta_value]='+this.state.categoryName+'&filter[orderby]=title&order=asc')
      .then(response => {
        this.setState({
          products: response.data
        })

      }).then(()=> {
        this.getStyleNames(this.state.products)
      }).then(() => {
        this.getBackings(this.state.products)
      }).then(() => {
        this.getFaceWeights(this.state.products)
      }).then(() => {
        this.getDyeMethods(this.state.products)
      }).then(() => {
        this.getHardSurfaceSizes(this.state.products)
      }).then(() => {
        this.getThickness(this.state.products)
      }).then(() => {
        this.getWearLayers(this.state.products)
      }).then(() => {
        this.getFinishes(this.state.products)
      }).then(() => {
        this.getInstallMethods(this.state.products)
      }).then(() => {
        localStorage.setItem('products', JSON.stringify(this.state.products))
        localStorage.setItem('numFilters', 0)
      }).then(() => {
        if (isSignatureNow) {
          this.setState({ filteredSignatureNowProducts: true}, () => this.filterWithOptions())
          this.setState({signatureNowChecked: true})
        }
      })
      .catch(errors => console.log(errors))
  }


  clearAllFilters() {
    window.location.reload()
  }

  getStyleNames(products) {
    const uniqueStyles = this.state.uniqueStylesFound

    products.forEach(function(style) {
      if (uniqueStyles.indexOf(style.title.rendered) === -1) {
        uniqueStyles.push(style.title.rendered)
      }
    })
    this.setState({ uniqueStylesFound: uniqueStyles})
  }

  getHardSurfaceSizes(products) {
    const uniqueHardSurfaceSizes = this.state.uniqueHardSurfaceSizesFound

    products.forEach(function(hsSize) {
      if (uniqueHardSurfaceSizes.indexOf(hsSize.acf.hardsurface_size) === -1) {
        uniqueHardSurfaceSizes.push(hsSize.acf.hardsurface_size)
      }
    })
    this.setState({ uniqueHardSurfaceSizesFound: uniqueHardSurfaceSizes.sort()})
  }

  getThickness(products) {
    const uniqueThickness = this.state.uniqueThicknessFound

    products.forEach(function(thickness) {
      if (uniqueThickness.indexOf(thickness.acf.overall_thickness) === -1) {
        uniqueThickness.push(thickness.acf.overall_thickness)
      }
    })
    this.setState({ uniqueThicknessFound: uniqueThickness.sort()})
  }

  getWearLayers(products) {
    const uniqueWearLayers = this.state.uniqueWearLayersFound

    products.forEach(function(wearLayers) {
      if (uniqueWearLayers.indexOf(wearLayers.acf.wear_layers_thickness) === -1) {
        uniqueWearLayers.push(wearLayers.acf.wear_layers_thickness)
      }
    })
    this.setState({ uniqueWearLayersFound: uniqueWearLayers.sort()})
  }

  getFinishes(products) {
    const uniqueFinishes = this.state.uniqueFinishesFound

    products.forEach(function(finishes) {
      if (uniqueFinishes.indexOf(finishes.acf.finish) === -1) {
        uniqueFinishes.push(finishes.acf.finish)
      }
    })
    this.setState({ uniqueFinishesFound: uniqueFinishes.sort()})
  }

  getInstallMethods(products) {
    const uniqueInstallMethods = this.state.uniqueInstallMethodsFound

    products.forEach(function(installMethods) {
      if (uniqueInstallMethods.indexOf(installMethods.acf.install_methods) === -1) {
        uniqueInstallMethods.push(installMethods.acf.install_methods)
      }
    })
    this.setState({ uniqueInstallMethodsFound: uniqueInstallMethods.sort()})
  }

  getFaceWeights(products) {
    const uniqueFaceWeights = this.state.uniqueFaceWeightsFound

    products.forEach(function(faceWeight) {
      if (uniqueFaceWeights.indexOf(faceWeight.acf.product_details_tufted_face_weight) === -1) {
        uniqueFaceWeights.push(faceWeight.acf.product_details_tufted_face_weight)
      }
    })
    this.setState({ uniqueFaceWeightsFound: uniqueFaceWeights.sort()})
  }

  getBackings(products) {
    const uniqueBackings = this.state.uniqueBackingsFound

    products.forEach(function(backing) {
      if (uniqueBackings.indexOf(backing.acf.product_details_secondary_backing) === -1) {
        uniqueBackings.push(backing.acf.product_details_secondary_backing)
      }
    })
    this.setState({ uniqueBackingsFound: uniqueBackings})
  }

  getDyeMethods(products) {
    const uniqueDyeMethods = this.state.uniqueDyeMethodsFound

    products.forEach(function(dyeMethod) {
      if (uniqueDyeMethods.indexOf(dyeMethod.acf.product_details_dye_method) === -1) {
        uniqueDyeMethods.push(dyeMethod.acf.product_details_dye_method)
      }
    })
    this.setState({ uniqueDyeMethodsFound: uniqueDyeMethods})
  }

  displayFilterOption(id, link) {
    var e = document.getElementById(id)
    var l = document.getElementById(link)
    if(e.style.display === 'block') {
      e.style.display = 'none'
      l.innerHTML = '+'
    } else {
      e.style.display = 'block'
      l.innerHTML = '-'
    }
  }
  displayMobileFilterOptions(id) {
    var e = document.getElementById(id)
    if(e.style.display === 'block') {
      e.style.display = 'none'
    } else {
      e.style.display = 'block'
    }
  }

  setColorRangeFilterOptions(selColor) {
    console.log('color', selColor);
    if ( this.state.filteredColors.indexOf(selColor) !== -1 && this.state.filteredColors !== 'undefined') {
      let existingColors = [...this.state.filteredColors]
      let index = existingColors.indexOf(selColor)
      
      existingColors.splice(index,1)
      this.setState({filteredColors: existingColors}, () => this.filterWithOptions())
    } else {
      var filteredColors = {}
      if (this.state.filteredColors !== 'undefined') {
        filteredColors = this.state.filteredColors
      }
      filteredColors.push(selColor)

      
      this.setState({
        filteredColors: filteredColors,
        numColorsSelected: filteredColors.length
      }, () => this.filterWithOptions())
    }
    

  }

  setStyleFilterOptions(newStyles) {
    var filteredStyles = {}
    if (newStyles.length !== 0) {
      filteredStyles = newStyles
    } else {
      filteredStyles = this.state.uniqueStylesFound
    }
    this.setState({
      filterStyles: filteredStyles,
      numStylesSelected: newStyles.length
    }, () => this.filterWithOptions())
  }

  setFaceWeightFilterOptions(faceWeights) {
    var filteredFaceWeights = {}
    if (faceWeights.length !== 0) {
      filteredFaceWeights = faceWeights
    } else {
      filteredFaceWeights = this.state.uniqueFaceWeightsFound
    }
    this.setState({
      filteredFaceWeights: filteredFaceWeights,
      numFaceWeightsSelected: faceWeights.length
    }, () => this.filterWithOptions())
  }

  filterSignatureNowProducts(signaturenow) {
    
    if (signaturenow.target.checked === false) {
      this.setState({ signatureNowChecked: false})
    } else {
      this.setState({ signatureNowChecked: true})
    }
    this.setState({ 
      filteredSignatureNowProducts: signaturenow.target.checked
    }, () => this.filterWithOptions())
  }

  setHardSurfaceSizeFilterOptions(hsSize) {
    var filteredHardSurfaceSizes = {}
    if (hsSize.length !== 0) {
      filteredHardSurfaceSizes = hsSize
    } else {
      filteredHardSurfaceSizes = this.state.uniqueHardSurfaceSizesFound
    }
    this.setState({
      filteredHardSurfaceSizes: filteredHardSurfaceSizes,
      numHardSurfaceSizesSelected: hsSize.length
    }, () => this.filterWithOptions())
  }

  setThicknessFilterOptions(thickness) {
    var filteredThickness = {}
    if (thickness.length !== 0) {
      filteredThickness = thickness
    } else {
      filteredThickness = this.state.uniqueThicknessFound
    }
    this.setState({
      filteredThickness: filteredThickness,
      numThicknessSelected: thickness.length
    }, () => this.filterWithOptions())
  }

  setFinishesFilterOptions(finish) {
    var filteredFinishes = {}
    if (finish.length !== 0) {
      filteredFinishes = finish
    } else {
      filteredFinishes = this.state.uniqueFinishesFound
    }
    this.setState({
      filteredFinishes: filteredFinishes,
      numFinishesSelected: finish.length
    }, () => this.filterWithOptions())
  }

  setInstallMethodsFilterOptions(installMethod) {
    var filteredInstallMethods = {}
    if (installMethod.length !== 0) {
      filteredInstallMethods = installMethod
    } else {
      filteredInstallMethods = this.state.uniqueInstallMethodsFound
    }
    this.setState({
      filteredInstallMethods: filteredInstallMethods,
      numInstallMethodsSelected: installMethod.length
    }, () => this.filterWithOptions())
  }

  setWearLayersFilterOptions(wearLayers) {
    var filteredWearLayers = {}
    if (wearLayers.length !== 0) {
      filteredWearLayers = wearLayers
    } else {
      filteredWearLayers = this.state.uniqueWearLayersFound
    }
    this.setState({
      filteredWearLayers: filteredWearLayers,
      numWearLayersSelected: wearLayers.length
    }, () => this.filterWithOptions())
  }

  setBackingFilterOptions(backings) {
    
    var filteredBackings = {}
    if (backings.length !== 0) {
      filteredBackings = backings
    } else {
      filteredBackings = this.state.uniqueBackingsFound
    }
    this.setState({
      filteredBackings: filteredBackings,
      numBackingsSelected: backings.length
    }, () => this.filterWithOptions())
  }

  constructionTypeFilterOptions(constructionTypes) {
    var filteredConstructionTypes = {}
    if (constructionTypes.length !== 0) {
      filteredConstructionTypes = constructionTypes
    } else {
      filteredConstructionTypes = this.state.constructionTypes
    }
    this.setState({
      filterConstruction: filteredConstructionTypes,
      numConstructionsSelected: constructionTypes.length
    }, () => this.filterWithOptions())
  }

  setHardSurfaceShadesFilterOptions(hsShade) {
    
    var filteredHardSurfaceShades = {}
    if (hsShade.length !== 0) {
      filteredHardSurfaceShades = hsShade
    } else {
      filteredHardSurfaceShades = this.state.hardSurfaceShades
    }
    this.setState({
      filteredShades: filteredHardSurfaceShades,
      numHardSurfaceShadeSelected: hsShade.length
    }, () => this.filterWithOptions())
  }

  dyeMethodFilterOptions(dyeMethod) {
    var filteredDyeMethods = {}
    if (dyeMethod.length !== 0) {
      filteredDyeMethods = dyeMethod
    } else {
      filteredDyeMethods = this.state.uniqueDyeMethodsFound
    }
    this.setState({
      filterDyeMethod: filteredDyeMethods,
      numDyeMethodsSelected: dyeMethod.length
    }, () => this.filterWithOptions())
  }

  patternTypesFilterOptions(patternTypes) {
    var filteredPatternTypes = {}
    if (patternTypes.length !== 0) {
      filteredPatternTypes = patternTypes
    } else {
      filteredPatternTypes = this.state.patternTypes
    }
    this.setState({
      filteredPatternTypes: filteredPatternTypes,
      numPatternTypesSelected: patternTypes.length
    }, () => this.filterWithOptions())
  }

  segmentsFilterOptions(segments) {
    var filteredSegments = {}
    var trueSegmentsLength = 0
    if (segments.length === 1 && segments[0] === 'Both') {
      segments = []
    }
    if (segments.length !== 0) {
      filteredSegments = segments
      if (filteredSegments.indexOf("Both") === -1) {
        filteredSegments.push("Both")
      }
    } else {
      filteredSegments = this.state.segments
    }
    trueSegmentsLength = segments.length
    if (segments.indexOf("Both") !== -1) {
      trueSegmentsLength = segments.length -1
    }
    this.setState({
      filteredSegments: filteredSegments,
      numSegmentsSelected: trueSegmentsLength
    }, () => this.filterWithOptions())
  }

  endusesFilterOptions(enduses) {
    var filteredEndUses = {}
    if (enduses.length !== 0) {
      filteredEndUses = enduses
    } else {
      filteredEndUses = this.state.enduses
    }
    this.setState({
      filteredEndUses: filteredEndUses,
      numEndUsesSelected: enduses.length
    }, () => this.filterWithOptions())
  }

  filterWithOptions() {

    this.setState({ 
      numberOfFilters: 
        this.state.numStylesSelected + 
        this.state.numFaceWeightsSelected +
        this.state.numConstructionsSelected +
        this.state.numDyeMethodsSelected +
        this.state.numBackingsSelected + 
        this.state.numPatternTypesSelected +
        this.state.numEndUsesSelected +
        this.state.numSegmentsSelected +
        this.state.numHardSurfaceSizesSelected +
        this.state.numThicknessSelected +
        this.state.numWearLayersSelected +
        this.state.numFinishesSelected +
        this.state.numInstallMethodsSelected + 
        this.state.numColorsSelected + 
        this.state.numHardSurfaceShadeSelected + 
        this.state.numSignatureNowProducts
      })
    //var res = JSON.parse(localStorage.getItem('products'))
    var res = []
    
    var arr = JSON.parse(localStorage.getItem('products'))
    
    if (this.state.filterStyles.length !== 0) {

    
      var brr = this.state.filterStyles
      res = arr.filter(f => brr.includes(f.title.rendered))
      this.setState({products: res})
    
    }
    if (this.state.filterConstruction.length !== 0) {

    
      var stateConstruction = this.state.filterConstruction
      if (res.length !== 0) {
    
        res = JSON.stringify(res)
        res = JSON.parse(res)
        res = res.filter(f => stateConstruction.includes(f.acf.technology))
      } else {
    
        res = arr.filter(f => stateConstruction.includes(f.acf.technology))
      }
    
      this.setState({products: res})
    }

    if (this.state.filterDyeMethod.length !== 0) {

    
      var stateDyeMethod = this.state.filterDyeMethod
      if (res.length !== 0) {
    
        res = JSON.stringify(res)
        res = JSON.parse(res)
        res = res.filter(f => stateDyeMethod.includes(f.acf.product_details_dye_method))
      } else {
    
        res = arr.filter(f => stateDyeMethod.includes(f.acf.product_details_dye_method))
      }
    
      this.setState({products: res})
    }

    if (this.state.filteredHardSurfaceSizes.length !== 0) {

      var stateHardSurfaceSizes = this.state.filteredHardSurfaceSizes
      if (res.length !== 0) {
    
        res = JSON.stringify(res)
        res = JSON.parse(res)
        res = res.filter(f => stateHardSurfaceSizes.includes(f.acf.hardsurface_size))
      } else {
    
        res = arr.filter(f => stateHardSurfaceSizes.includes(f.acf.hardsurface_size))
      }
    
      this.setState({products: res})
    }

    if (this.state.filteredThickness.length !== 0) {

      var stateThickness = this.state.filteredThickness
      if (res.length !== 0) {
    
        res = JSON.stringify(res)
        res = JSON.parse(res)
        res = res.filter(f => stateThickness.includes(f.acf.overall_thickness))
      } else {
    
        res = arr.filter(f => stateThickness.includes(f.acf.overall_thickness))
      }
    
      this.setState({products: res})
    }

    if (this.state.filteredWearLayers.length !== 0) {

      var stateWearLayers = this.state.filteredWearLayers
      if (res.length !== 0) {
    
        res = JSON.stringify(res)
        res = JSON.parse(res)
        res = res.filter(f => stateWearLayers.includes(f.acf.wear_layers_thickness))
      } else {
    
        res = arr.filter(f => stateWearLayers.includes(f.acf.wear_layers_thickness))
      }
    
      this.setState({products: res})
    }

    if (this.state.filteredInstallMethods.length !== 0) {

      var stateInstallMethods = this.state.filteredInstallMethods
      if (res.length !== 0) {
    
        res = JSON.stringify(res)
        res = JSON.parse(res)
        res = res.filter(f => stateInstallMethods.includes(f.acf.install_methods))
      } else {
    
        res = arr.filter(f => stateInstallMethods.includes(f.acf.install_methods))
      }
    
      this.setState({products: res})
    }

    if (this.state.filteredFinishes.length !== 0) {

      var stateFinishes = this.state.filteredFinishes
      if (res.length !== 0) {
    
        res = JSON.stringify(res)
        res = JSON.parse(res)
        res = res.filter(f => stateFinishes.includes(f.acf.finish))
      } else {
    
        res = arr.filter(f => stateFinishes.includes(f.acf.finish))
      }
    
      this.setState({products: res})
    }

    if (this.state.filteredPatternTypes.length !== 0) {

    
      var statePatternTypes = this.state.filteredPatternTypes
      if (res.length !== 0) {
    
        res = JSON.stringify(res)
        res = JSON.parse(res)
        res = res.filter(f => statePatternTypes.includes(f.acf.pattern_type))
      } else {
    
        res = arr.filter(f => statePatternTypes.includes(f.acf.pattern_type))
      }
    
      this.setState({products: res})
    }

    if (this.state.filteredSegments.length !== 0) {

    
      var stateSegments = this.state.filteredSegments
      // there are other options selected.
      if (res.length !== 0) {
    
        res = JSON.stringify(res)
        res = JSON.parse(res)
        res = res.filter(f => stateSegments.includes(f.acf.segment))
      } else {
      // there are no other options selected
    
        //res = arr.filter(f => stateSegments.includes(f.acf.segments))

        res = arr.filter(f => stateSegments.includes(f.acf.segment))
      }
    
      this.setState({products: res})
    }

    if (this.state.filteredEndUses.length !== 0) {

    
      var stateEndUses = this.state.filteredEndUses
      if (res.length !== 0) {
    
        res = JSON.stringify(res)
        res = JSON.parse(res)
        res = res.filter(f => stateEndUses.includes(f.acf.end_use))
      } else {
    
        res = arr.filter(f => stateEndUses.includes(f.acf.end_use))
      }
    
      this.setState({products: res})
    }

    if (this.state.filteredBackings.length !== 0) {

    
      var stateBackings = this.state.filteredBackings
      if (res.length !== 0) {
    
        res = JSON.stringify(res)
        res = JSON.parse(res)
        res = res.filter(f => stateBackings.includes(f.acf.product_details_secondary_backing))
      } else {
    
        res = arr.filter(f => stateBackings.includes(f.acf.product_details_secondary_backing))
      }
    
      this.setState({products: res})
    }

    if (this.state.filteredColors.length !== 0) {

      var stateColors = this.state.filteredColors
    
      if (res.length !== 0) {
    
        res = JSON.stringify(res)
        res = JSON.parse(res)

        res = res.filter(x => x.acf.product_details_colorways.find(col => {
          return stateColors.includes(col.color_family);
        }))
      } else {
        res = arr.filter(x => x.acf.product_details_colorways.find(col => {
          return stateColors.includes(col.color_family);
        }))
      }
    
      this.setState({products: res})
    }

    if (this.state.filteredShades.length !== 0) {
    
      var hsShades = this.state.filteredShades

      if (res.length !== 0) {
    
        res = JSON.stringify(res)
        res = JSON.parse(res)

        res = res.filter(x => x.acf.product_details_colorways.find(col => {
          return hsShades.includes(col.color_family);
        }))
      } else {
        res = arr.filter(x => x.acf.product_details_colorways.find(col => {
          return hsShades.includes(col.color_family);
        }))
      }
    
      this.setState({products: res})
    }

    if (this.state.filteredFaceWeights.length !== 0) {

    
      var stateFaceWeights = this.state.filteredFaceWeights
      if (res.length !== 0) {
    
        res = JSON.stringify(res)
        res = JSON.parse(res)
        res = res.filter(f => stateFaceWeights.includes(f.acf.product_details_tufted_face_weight))
      } else {
    
        res = arr.filter(f => stateFaceWeights.includes(f.acf.product_details_tufted_face_weight))
      }
    
      this.setState({products: res})
    }

    if (this.state.filteredSignatureNowProducts === true) {
      if (res.length !== 0) {
        res = JSON.stringify(res)
        res = JSON.parse(res)
    
        res = res.filter(function (e) {
          return e.acf.signature_now_product === true
        })
    
      } else {
        res = arr.filter(function (e) {
          return e.acf.signature_now_product === true
        })
        this.setState({ products: res})
      }
    }

    if (this.state.filteredSignatureNowProducts === false) {
      if (res.length !== 0) {
    
        res = res
      } else {
    
        res = arr
      }
      
      this.setState({ products: res })
    }
}

  render() {
    const { SignatureFlooringSettings, categoryName, products, uniqueBackingsFound, uniqueHardSurfaceSizesFound, uniqueFaceWeightsFound, numberOfFilters, signatureNowChecked } = this.state
    const noEndUse = ["Rugs", "Carpet Tile", "LVT", "SPC", "WPC", "Rubber Wall Base"]
    const hardSurfaceCat = ["wpc", "lvt", "WPC", "LVT", "Loose Lay", "LOOSE LAY", "spc", "SPC", "Rubber Wall Base"]
    var headerPtOne;
	var headerPtTwo;
	var headerPtThree;
	var headerPtFour;
    var header = [];
    const NoOfProducts = this.state.products.length;
    var rugflag = false;
    if(categoryName === "rugs"){
      rugflag = true;
    }
    if(categoryName === "Carpet Tile"){
      headerPtOne = "Carpet Tiles and Planks";
    }
    else {
      const words = categoryName.split(" ");
      headerPtOne = words.map((word) => { 
      return word[0].toUpperCase() + word.substring(1); 
      }).join(" ");
    }
    headerPtOne += " by Signature Flooring."
	if(categoryName === "Rubber Wall Base"){
      headerPtOne = "TPR Rubber Wall Base";
    }
    if(NoOfProducts >= 7 && !rugflag){
      header += headerPtOne + "  Browse all " + NoOfProducts + " Styles or use our extensive filters to narrow your search.";
    }
	else if(categoryName === "rugs"){
	  header += headerPtOne;
	  headerPtTwo = "Ignite Your Creativity with Signature Spark for Rugs";
	  headerPtThree = "An easy to use custom design and inspiration website where you can recolor custom carpet patterns or running line products into rugs. For specific details and guidelines contact your local Signature Sales representative for more information.";
      headerPtFour = "Click on any product below to view in Signature Spark.";	  
    }
	else{
      header += headerPtOne;
    }
    console.log(categoryName);
    
    return (
      <div>
            <Helmet>
            <title>{headerPtOne}</title>
<meta name="description" content={header}/>
            </Helmet>
      <div id="category-wrapper">
       <div id="category-container">
        <div className="category-header">
          <h1>{categoryName}</h1>
          <p style={{textAlign: 'center'}}>{header}</p>
		  { (categoryName) === 'rugs' &&
			<p style={{textAlign: 'center', fontWeight: 'bold'}}>{headerPtTwo}</p>
		  }
		  { (categoryName) === 'rugs' &&
			<p style={{textAlign: 'center'}}>{headerPtThree}</p>
		  }
		  { (categoryName) === 'rugs' &&
			<p style={{textAlign: 'center'}}>{headerPtFour}</p>
		  }
        </div>
        <div className="category-filterbar">
          <p className="category-filterbar-left">
            Filters ({numberOfFilters})
          </p>
          <p className="category-filterbar-right">
            <a href="##" onClick={this.clearAllFilters}>X Clear All</a>
          </p>
        </div>
        <div className="category-main-content">
          <div className="category-main-content filter">
            <div className="filter-item">
              <div className="left" style={{ width: '100%'}}>
                <input type="checkbox" name="signaturenow" value="signaturenow" onChange={this.filterSignatureNowProducts} checked={signatureNowChecked} /> SignatureNow
              </div>
            </div>
            { hardSurfaceCat.indexOf(categoryName) === -1 &&
            <div className="filter-item">
              <div className="left">
                Face Weight
              </div>
              <div className="right"><a href="##" id="faceWeight-link" onClick={() => this.displayFilterOption('faceWeight-options','faceWeight-link')}>+</a></div>
              <div id="faceWeight-options" className="filter-item">
                <div className="filter-options">
                <CheckboxGroup 
                  checkboxDepth={3} 
                  name="styles" 
                  onChange={this.setFaceWeightFilterOptions}
                >
                { uniqueFaceWeightsFound.map( faceWeight => (
                  <div key={faceWeight}>
                    <label>
                      <Checkbox value={faceWeight} /> {faceWeight}
                    </label>
                  </div>
                ))}
                </CheckboxGroup>

                </div>
              </div>
            </div>
            }
            { (hardSurfaceCat.indexOf(categoryName) !== -1) &&
            <div className="filter-item">
              <div className="left">
                Sizes
              </div>
              <div className="right"><a href="##" id="hsSize-link" onClick={() => this.displayFilterOption('hsSize-options','hsSize-link')}>+</a></div>
              <div id="hsSize-options" className="filter-item">
                <div className="filter-options">
                <CheckboxGroup 
                  checkboxDepth={3} 
                  name="styles" 
                  onChange={this.setHardSurfaceSizeFilterOptions}
                >
                { uniqueHardSurfaceSizesFound.map( hsSize => (
                  <div key={hsSize}>
                    <label>
                      <Checkbox value={hsSize} /> {hsSize}
                    </label>
                  </div>
                ))}
                </CheckboxGroup>

                </div>
              </div>
            </div>
            }
            { hardSurfaceCat.indexOf(categoryName) === -1 &&
            <div className="filter-item">
              <div className="left">
                Construction
              </div>
              <div className="right"><a href="##" id="construction-options-link" onClick={() => this.displayFilterOption('construction-type-options','construction-options-link')}>+</a></div>
              <div id="construction-type-options" className="filter-item">
                <div className="filter-options">
                <CheckboxGroup 
                  checkboxDepth={3} 
                  name="constructionType" 
                  onChange={this.constructionTypeFilterOptions}
                >
                  { this.state.constructionTypes.map( cList => (
                  <div key={cList}>
                    <label>
                      <Checkbox value={cList} /> {cList}
                    </label>
                  </div>
                  ))}
                </CheckboxGroup> 
                </div>
              </div>
            </div>
            }
            { hardSurfaceCat.indexOf(categoryName) !== -1 &&
              <div className="filter-item">
              <div className="left">
                Overall Thickness
              </div>
              <div className="right"><a href="##" id="thickness-options-link" onClick={() => this.displayFilterOption('thickness-options','thickness-options-link')}>+</a></div>
              <div id="thickness-options" className="filter-item">
                <div className="filter-options">
                <CheckboxGroup 
                  checkboxDepth={3} 
                  name="overalThickness" 
                  onChange={this.setThicknessFilterOptions}
                >
                  { this.state.uniqueThicknessFound.map( thickness => (
                  <div key={thickness}>
                    <label>
                      <Checkbox value={thickness} /> {thickness}
                    </label>
                  </div>
                  ))}
                </CheckboxGroup> 
                </div>
              </div>
            </div>
            }

            { hardSurfaceCat.indexOf(categoryName) !== -1 &&
              <div className="filter-item">
              <div className="left">
                Wear Layer
              </div>
              <div className="right"><a href="##" id="wearlayer-options-link" onClick={() => this.displayFilterOption('wearlayer-options','wearlayer-options-link')}>+</a></div>
              <div id="wearlayer-options" className="filter-item">
                <div className="filter-options">
                <CheckboxGroup 
                  checkboxDepth={3} 
                  name="wearLayer" 
                  onChange={this.setWearLayersFilterOptions}
                >
                  { this.state.uniqueWearLayersFound.map( wearLayer => (
                  <div key={wearLayer}>
                    <label>
                      <Checkbox value={wearLayer} /> {wearLayer}
                    </label>
                  </div>
                  ))}
                </CheckboxGroup> 
                </div>
              </div>
            </div>
            }

            { hardSurfaceCat.indexOf(categoryName) !== -1 &&
              <div className="filter-item">
              <div className="left">
                Finish
              </div>
              <div className="right"><a href="##" id="finish-options-link" onClick={() => this.displayFilterOption('finish-options','finish-options-link')}>+</a></div>
              <div id="finish-options" className="filter-item">
                <div className="filter-options">
                <CheckboxGroup 
                  checkboxDepth={3} 
                  name="finsih" 
                  onChange={this.setFinishesFilterOptions}
                >
                  { this.state.uniqueFinishesFound.map( finish => (
                  <div key={finish}>
                    <label>
                      <Checkbox value={finish} /> {finish}
                    </label>
                  </div>
                  ))}
                </CheckboxGroup> 
                </div>
              </div>
            </div>
            }

            { hardSurfaceCat.indexOf(categoryName) !== -1 &&
              <div className="filter-item">
              <div className="left">
                Install Method
              </div>
              <div className="right"><a href="##" id="install-method-options-link" onClick={() => this.displayFilterOption('install-method-options','install-method-options-link')}>+</a></div>
              <div id="install-method-options" className="filter-item">
                <div className="filter-options">
                <CheckboxGroup 
                  checkboxDepth={3} 
                  name="installMethod" 
                  onChange={this.setInstallMethodsFilterOptions}
                >
                  { this.state.uniqueInstallMethodsFound.map( installMethod => (
                  <div key={installMethod}>
                    <label>
                      <Checkbox value={installMethod} /> {installMethod}
                    </label>
                  </div>
                  ))}
                </CheckboxGroup> 
                </div>
              </div>
            </div>
            }

            { hardSurfaceCat.indexOf(categoryName) === -1 &&
            <div className="filter-item">
              <div className="left">
                Dye Method
              </div>
              <div className="right"><a href="##" id="dye-methods-options-link" onClick={() => this.displayFilterOption('dye-methods-options','dye-methods-options-link')}>+</a></div>
              <div id="dye-methods-options" className="filter-item">
                <div className="filter-options">
                <CheckboxGroup 
                  checkboxDepth={3} 
                  name="dyeMethod" 
                  onChange={this.dyeMethodFilterOptions}
                >
                  { this.state.uniqueDyeMethodsFound.map( dMethod => (
                  <div key={dMethod}>
                    <label>
                      <Checkbox value={dMethod} /> {dMethod}
                    </label>
                  </div>
                  ))}
                </CheckboxGroup>
                </div>
              </div>
            </div>
            }
            { hardSurfaceCat.indexOf(categoryName) === -1 &&
            <div className="filter-item">
              <div className="left">
                Backing
              </div>
              <div className="right"><a href="##" id="backing-options-link" onClick={() => this.displayFilterOption('backing-options','backing-options-link')}>+</a></div>
              <div id="backing-options" className="filter-item">
                <div className="filter-options">
                <CheckboxGroup 
                  checkboxDepth={3} 
                  name="backings" 
                  onChange={this.setBackingFilterOptions}
                >
                { uniqueBackingsFound.map( backing => (
                  <div key={backing}>
                    <label>
                      <Checkbox value={backing} /> {backing}
                    </label>
                  </div>
                ))}
                </CheckboxGroup>

                </div>
              </div>
            </div>
            }
            { hardSurfaceCat.indexOf(categoryName) === -1 &&
            <div className="filter-item">
              <div className="left">
                Pattern Type
              </div>
              <div className="right"><a href="##" id="patterntypes-link" onClick={() => this.displayFilterOption('patterntypes-options','patterntypes-link')}>+</a></div>
              <div id="patterntypes-options" className="filter-item">
                <div className="filter-options">
                <CheckboxGroup 
                  checkboxDepth={3} 
                  name="patternTypes" 
                  onChange={this.patternTypesFilterOptions}
                >
                  { this.state.patternTypes.map( pTypes => (
                  <div key={pTypes}>
                    <label>
                      <Checkbox value={pTypes} /> {pTypes}
                    </label>
                  </div>
                  ))}
                </CheckboxGroup>
                </div>
              </div>
            </div>
            }
            {( noEndUse.indexOf(this.state.categoryName) === -1) &&
              <div className="filter-item">
              <div className="left">
                End Use 
              </div>
              <div className="right"><a href="##" id="enduses-link" onClick={() => this.displayFilterOption('enduses-options','enduses-link')}>+</a></div>
              <div id="enduses-options" className="filter-item">
                <div className="filter-options">
                <CheckboxGroup 
                  checkboxDepth={3} 
                  name="enduses" 
                  onChange={this.endusesFilterOptions}
                > 
                  { this.state.enduses.map( eUses => (
                  <div key={eUses}>
                    <label>
                      <Checkbox value={eUses} /> {eUses}
                    </label>
                  </div>
                  ))}
                </CheckboxGroup>
                </div>
              </div>
            </div>
            }
            {( noEndUse.indexOf(this.state.categoryName) === 1) &&
              <div className="filter-item">
              <div className="left">
                End Use 
              </div>
              <div className="right"><a href="##" id="enduses-link" onClick={() => this.displayFilterOption('enduses-options','enduses-link')}>+</a></div>
              <div id="enduses-options" className="filter-item">
                <div className="filter-options">
                <CheckboxGroup 
                  checkboxDepth={3} 
                  name="enduses" 
                > 
                  { this.state.enduses.map( eUses => (
                  <div key={eUses}>
                    <label>
                      <Checkbox value={eUses} /> {eUses}
                    </label>
                  </div>
                  ))}
                </CheckboxGroup>
                </div>
              </div>
            </div>
            }
            <div className="filter-item">
              <div className="left">
                Segment
              </div>
              <div className="right"><a href="##" id="segments-link" onClick={() => this.displayFilterOption('segments-options','segments-link')}>+</a></div>
              <div id="segments-options" className="filter-item">
                <div className="filter-options">
                <CheckboxGroup 
                  checkboxDepth={3} 
                  name="segments" 
                  onChange={this.segmentsFilterOptions}
                >
                  <div>
                    <label>
                      <Checkbox value="Hospitality" /> Hospitality
                    </label>
                  </div>
                  <div>
                    <label>
                      <Checkbox value="Senior Living" /> Senior Living
                    </label>
                  </div>
                </CheckboxGroup>
                </div>
              </div>
            </div>
            { hardSurfaceCat.indexOf(categoryName) === -1 &&
            <div className="filter-item">
              <div className="left">
                Color Range
              </div>
              <div className="right"><a href="##" id="color-range-link" onClick={() => this.displayFilterOption('color-range-options','color-range-link')}>+</a></div>
              <div id="color-range-options" className="filter-item">
                <div className="filter-options">
                  <img onClick={() => {this.setColorRangeFilterOptions('Grey')}} style={{padding:'4px', cursor: 'pointer'}} src="/wp-content/uploads/2019/03/grey.png" alt="grey" />
                  <img onClick={() => {this.setColorRangeFilterOptions('Red')}} style={{padding:'4px', cursor: 'pointer'}} src="/wp-content/uploads/2019/03/Redburgandy.png" alt="red-burgandy" />
                  <img onClick={() => {this.setColorRangeFilterOptions('Taupe')}} style={{padding:'4px', cursor: 'pointer'}} src="/wp-content/uploads/2019/03/Taupe.png" alt="taupe" />
                  <img onClick={() => {this.setColorRangeFilterOptions('Beige')}} style={{padding:'4px', cursor: 'pointer'}} src="/wp-content/uploads/2019/03/beige.png" alt="beige" />
                  <img onClick={() => {this.setColorRangeFilterOptions('Black')}} style={{padding:'4px', cursor: 'pointer'}} src="/wp-content/uploads/2019/03/black.png" alt="black" />
                  <img onClick={() => {this.setColorRangeFilterOptions('Blue')}} style={{padding:'4px', cursor: 'pointer'}} src="/wp-content/uploads/2019/03/blueteal.png" alt="blue-teal" />
                  <img onClick={() => {this.setColorRangeFilterOptions('Bright')}} style={{padding:'4px', cursor: 'pointer'}} src="/wp-content/uploads/2019/03/bright.png" alt="bright" />
                  <img onClick={() => {this.setColorRangeFilterOptions('Brown')}} style={{padding:'4px', cursor: 'pointer'}} src="/wp-content/uploads/2019/03/brown.png" alt="brown" />
                  <img onClick={() => {this.setColorRangeFilterOptions('Gold')}} style={{padding:'4px', cursor: 'pointer'}} src="/wp-content/uploads/2019/03/goldyellow.png" alt="gold-yellow" />
                  <img onClick={() => {this.setColorRangeFilterOptions('Green')}} style={{padding:'4px', cursor: 'pointer'}} src="/wp-content/uploads/2019/03/green.png" alt="green" />
                  <img onClick={() => {this.setColorRangeFilterOptions('Orange')}} style={{padding:'4px', cursor: 'pointer'}} src="/wp-content/uploads/2019/03/orangerust.png" alt="orange-rust" />
                  <img onClick={() => {this.setColorRangeFilterOptions('Purple')}} style={{padding:'4px', cursor: 'pointer'}} src="/wp-content/uploads/2019/03/purple.png" alt="purple" />
                </div>
              </div>
            </div>
            }
            { hardSurfaceCat.indexOf(categoryName) !== -1 &&
              <div className="filter-item">
              <div className="left">
                Shades
              </div>
              <div className="right"><a href="##" id="color-range-link" onClick={() => this.displayFilterOption('color-range-options','color-range-link')}>+</a></div>
              <div id="color-range-options" className="filter-item">
                <div className="filter-options">
                <CheckboxGroup 
                  checkboxDepth={3} 
                  name="hardSurfaceShades"
                  onChange={this.setHardSurfaceShadesFilterOptions} 
                > 
                  { this.state.hardSurfaceShades.map( hShades => (
                  <div key={hShades}>
                    <label>
                      <Checkbox value={hShades} /> {hShades}
                    </label>
                  </div>
                  ))}
                </CheckboxGroup>
                </div>
              </div>
            </div>
            }
          </div>

          <div className="category-main-content results">
            { products.map( product => (
              <div className="product" key={product.id}>
              <div className="product-wrapper">
              {product.acf.style_image_spark_url ? ( 
                <div className="style-customize-container">
                  <img src={product.acf.product_details_main_image.url} alt={product.title.rendered} width={239} height={239} className="style-customize-image"/>
                  <div className="style-customize-button">
                    <div className="style-customize-button-text">
                    <a href={
                          `https://spark.signatureflooring.com/products/open/?ID=` +
                          product.acf.design_id +
                          product.acf.style_image_spark_url
                          }
                          target="_new">
                          Customize
                        </a>                
                    </div>
                  </div>
                </div>
              ) : (
                <a href={SignatureFlooringSettings.path + "productinfo/"+product.slug}>
                {product.acf.signature_now_product && 
                      <div className="notify-badge">
                        <div style={{height: '40px', right: '-20px'}}></div>
                        <span>NOW</span>
                      </div>
                    } 
                  <img src={product.acf.product_details_main_image.url} alt={product.title.rendered} width={239} height={239} />
                  <p className="style-title-text">{product.title.rendered} <span className="">{product.acf.style_number}</span></p>
                </a>
              )}
              {product.acf.style_image_spark_url &&
                <p>{product.title.rendered} <span className="">{product.acf.style_number}</span></p>
              }
              </div>
            </div>
            ))}
          </div>
        </div>
      </div>
      </div>
{/****************************************** */
/****************************************** */
/****************************************** */
/****************************************** */}

      <div id="category-wrapper-mobile">
        <div className="category-header-mobile">
          <h1>{categoryName}</h1>
          <p style={{textAlign: 'center'}}>{this.state.products.length} Styles</p>
        </div>
        <div className="filter-button-holder-mobile">
          <a href="##" id="mobile-filter-button" onClick={() => this.displayMobileFilterOptions('filter-mobile-id')}><button className="filter-button-mobile">Filter</button></a>
        </div>


        <div id="filter-mobile-id" className="filter-mobile">
        <div id="filter-mobile-options" className="filter-mobile filter">
        { hardSurfaceCat.indexOf(categoryName) === -1 &&
            <div className="filter-item">
              <div className="left">
                Face Weight
              </div>
              <div className="right"><a href="##" id="faceWeightMobile-link" onClick={() => this.displayFilterOption('faceWeightMobile-options','faceWeightMobile-link')}>+</a></div>
              <div id="faceWeightMobile-options" className="filter-item">
                <div className="filter-options">
                <CheckboxGroup 
                  checkboxDepth={3} 
                  name="styles" 
                  onChange={this.setFaceWeightFilterOptions}
                >
                { uniqueFaceWeightsFound.map( faceWeight => (
                  <div key={faceWeight}>
                    <label>
                      <Checkbox value={faceWeight} /> {faceWeight}
                    </label>
                  </div>
                ))}
                </CheckboxGroup>

                </div>
              </div>
            </div>
            }
            { (hardSurfaceCat.indexOf(categoryName) !== -1) &&
            <div className="filter-item">
              <div className="left">
                Sizes
              </div>
              <div className="right"><a href="##" id="hsSizeMobile-link" onClick={() => this.displayFilterOption('hsSizeMobile-options','hsSizeMobile-link')}>+</a></div>
              <div id="hsSizeMobile-options" className="filter-item">
                <div className="filter-options">
                <CheckboxGroup 
                  checkboxDepth={3} 
                  name="styles" 
                  onChange={this.setHardSurfaceSizeFilterOptions}
                >
                { uniqueHardSurfaceSizesFound.map( hsSize => (
                  <div key={hsSize}>
                    <label>
                      <Checkbox value={hsSize} /> {hsSize}
                    </label>
                  </div>
                ))}
                </CheckboxGroup>

                </div>
              </div>
            </div>
            }
            { hardSurfaceCat.indexOf(categoryName) === -1 &&
            <div className="filter-item">
              <div className="left">
                Construction
              </div>
              <div className="right"><a href="##" id="constructionMobile-options-link" onClick={() => this.displayFilterOption('constructionMobile-type-options','constructionMobile-options-link')}>+</a></div>
              <div id="constructionMobile-type-options" className="filter-item">
                <div className="filter-options">
                <CheckboxGroup 
                  checkboxDepth={3} 
                  name="constructionType" 
                  onChange={this.constructionTypeFilterOptions}
                >
                  { this.state.constructionTypes.map( cList => (
                  <div key={cList}>
                    <label>
                      <Checkbox value={cList} /> {cList}
                    </label>
                  </div>
                  ))}
                </CheckboxGroup> 
                </div>
              </div>
            </div>
            }
            { hardSurfaceCat.indexOf(categoryName) !== -1 &&
              <div className="filter-item">
              <div className="left">
                Overall Thickness
              </div>
              <div className="right"><a href="##" id="thicknessMobile-options-link" onClick={() => this.displayFilterOption('thicknessMobile-options','thicknessMobile-options-link')}>+</a></div>
              <div id="thicknessMobile-options" className="filter-item">
                <div className="filter-options">
                <CheckboxGroup 
                  checkboxDepth={3} 
                  name="overalThickness" 
                  onChange={this.setThicknessFilterOptions}
                >
                  { this.state.uniqueThicknessFound.map( thickness => (
                  <div key={thickness}>
                    <label>
                      <Checkbox value={thickness} /> {thickness}
                    </label>
                  </div>
                  ))}
                </CheckboxGroup> 
                </div>
              </div>
            </div>
            }

            { hardSurfaceCat.indexOf(categoryName) !== -1 &&
              <div className="filter-item">
              <div className="left">
                Wear Layer
              </div>
              <div className="right"><a href="##" id="wearlayerMobile-options-link" onClick={() => this.displayFilterOption('wearlayerMobile-options','wearlayerMobile-options-link')}>+</a></div>
              <div id="wearlayerMobile-options" className="filter-item">
                <div className="filter-options">
                <CheckboxGroup 
                  checkboxDepth={3} 
                  name="wearLayer" 
                  onChange={this.setWearLayersFilterOptions}
                >
                  { this.state.uniqueWearLayersFound.map( wearLayer => (
                  <div key={wearLayer}>
                    <label>
                      <Checkbox value={wearLayer} /> {wearLayer}
                    </label>
                  </div>
                  ))}
                </CheckboxGroup> 
                </div>
              </div>
            </div>
            }

            { hardSurfaceCat.indexOf(categoryName) !== -1 &&
              <div className="filter-item">
              <div className="left">
                Finish
              </div>
              <div className="right"><a href="##" id="finishMobile-options-link" onClick={() => this.displayFilterOption('finishMobile-options','finishMobile-options-link')}>+</a></div>
              <div id="finishMobile-options" className="filter-item">
                <div className="filter-options">
                <CheckboxGroup 
                  checkboxDepth={3} 
                  name="finsih" 
                  onChange={this.setFinishesFilterOptions}
                >
                  { this.state.uniqueFinishesFound.map( finish => (
                  <div key={finish}>
                    <label>
                      <Checkbox value={finish} /> {finish}
                    </label>
                  </div>
                  ))}
                </CheckboxGroup> 
                </div>
              </div>
            </div>
            }

            { hardSurfaceCat.indexOf(categoryName) !== -1 &&
              <div className="filter-item">
              <div className="left">
                Install Method
              </div>
              <div className="right"><a href="##" id="install-methodMobile-options-link" onClick={() => this.displayFilterOption('install-methodMobile-options','install-methodMobile-options-link')}>+</a></div>
              <div id="install-methodMobile-options" className="filter-item">
                <div className="filter-options">
                <CheckboxGroup 
                  checkboxDepth={3} 
                  name="installMethod" 
                  onChange={this.setInstallMethodsFilterOptions}
                >
                  { this.state.uniqueInstallMethodsFound.map( installMethod => (
                  <div key={installMethod}>
                    <label>
                      <Checkbox value={installMethod} /> {installMethod}
                    </label>
                  </div>
                  ))}
                </CheckboxGroup> 
                </div>
              </div>
            </div>
            }

            { hardSurfaceCat.indexOf(categoryName) === -1 &&
            <div className="filter-item">
              <div className="left">
                Dye Method
              </div>
              <div className="right"><a href="##" id="dye-methodsMobile-options-link" onClick={() => this.displayFilterOption('dye-methodsMobile-options','dye-methodsMobile-options-link')}>+</a></div>
              <div id="dye-methodsMobile-options" className="filter-item">
                <div className="filter-options">
                <CheckboxGroup 
                  checkboxDepth={3} 
                  name="dyeMethod" 
                  onChange={this.dyeMethodFilterOptions}
                >
                  { this.state.uniqueDyeMethodsFound.map( dMethod => (
                  <div key={dMethod}>
                    <label>
                      <Checkbox value={dMethod} /> {dMethod}
                    </label>
                  </div>
                  ))}
                </CheckboxGroup>
                </div>
              </div>
            </div>
            }
            { hardSurfaceCat.indexOf(categoryName) === -1 &&
            <div className="filter-item">
              <div className="left">
                Backing
              </div>
              <div className="right"><a href="##" id="backing-optionsMobile-link" onClick={() => this.displayFilterOption('backing-optionsMobile','backing-optionsMobile-link')}>+</a></div>
              <div id="backing-optionsMobile" className="filter-item">
                <div className="filter-options">
                <CheckboxGroup 
                  checkboxDepth={3} 
                  name="backings" 
                  onChange={this.setBackingFilterOptions}
                >
                { uniqueBackingsFound.map( backing => (
                  <div key={backing}>
                    <label>
                      <Checkbox value={backing} /> {backing}
                    </label>
                  </div>
                ))}
                </CheckboxGroup>

                </div>
              </div>
            </div>
            }
            { hardSurfaceCat.indexOf(categoryName) === -1 &&
            <div className="filter-item">
              <div className="left">
                Pattern Type
              </div>
              <div className="right"><a href="##" id="patterntypesMobile-link" onClick={() => this.displayFilterOption('patterntypesMobile-options','patterntypesMobile-link')}>+</a></div>
              <div id="patterntypesMobile-options" className="filter-item">
                <div className="filter-options">
                <CheckboxGroup 
                  checkboxDepth={3} 
                  name="patternTypes" 
                  onChange={this.patternTypesFilterOptions}
                >
                  { this.state.patternTypes.map( pTypes => (
                  <div key={pTypes}>
                    <label>
                      <Checkbox value={pTypes} /> {pTypes}
                    </label>
                  </div>
                  ))}
                </CheckboxGroup>
                </div>
              </div>
            </div>
            }
            {( noEndUse.indexOf(this.state.categoryName) === -1) &&
              <div className="filter-item">
              <div className="left">
                End Use 
              </div>
              <div className="right"><a href="##" id="endusesMobile-link" onClick={() => this.displayFilterOption('endusesMobile-options','endusesMobile-link')}>+</a></div>
              <div id="endusesMobile-options" className="filter-item">
                <div className="filter-options">
                <CheckboxGroup 
                  checkboxDepth={3} 
                  name="enduses" 
                  onChange={this.endusesFilterOptions}
                > 
                  { this.state.enduses.map( eUses => (
                  <div key={eUses}>
                    <label>
                      <Checkbox value={eUses} /> {eUses}
                    </label>
                  </div>
                  ))}
                </CheckboxGroup>
                </div>
              </div>
            </div>
            }
            {( noEndUse.indexOf(this.state.categoryName) === 1) &&
              <div className="filter-item">
              <div className="left">
                End Use 
              </div>
              <div className="right"><a href="##" id="endusesMobile-link" onClick={() => this.displayFilterOption('endusesMobile-options','endusesMobile-link')}>+</a></div>
              <div id="endusesMobile-options" className="filter-item">
                <div className="filter-options">
                <CheckboxGroup 
                  checkboxDepth={3} 
                  name="enduses" 
                > 
                  { this.state.enduses.map( eUses => (
                  <div key={eUses}>
                    <label>
                      <Checkbox value={eUses} /> {eUses}
                    </label>
                  </div>
                  ))}
                </CheckboxGroup>
                </div>
              </div>
            </div>
            }
            <div className="filter-item">
              <div className="left">
                Segment
              </div>
              <div className="right"><a href="##" id="segmentsMobile-link" onClick={() => this.displayFilterOption('segmentsMobile-options','segmentsMobile-link')}>+</a></div>
              <div id="segmentsMobile-options" className="filter-item">
                <div className="filter-options">
                <CheckboxGroup 
                  checkboxDepth={3} 
                  name="segments" 
                  onChange={this.segmentsFilterOptions}
                >
                  <div>
                    <label>
                      <Checkbox value="Hospitality" /> Hospitality
                    </label>
                  </div>
                  <div>
                    <label>
                      <Checkbox value="Senior Living" /> Senior Living
                    </label>
                  </div>
                </CheckboxGroup>
                </div>
              </div>
            </div>
            { hardSurfaceCat.indexOf(categoryName) === -1 &&
            <div className="filter-item">
              <div className="left">
                Color Range
              </div>
              <div className="right"><a href="##" id="color-rangeMobile-link" onClick={() => this.displayFilterOption('color-rangeMobile-options','color-rangeMobile-link')}>+</a></div>
              <div id="color-rangeMobile-options" className="filter-item">
                <div className="filter-options">
                  <img onClick={() => {this.setColorRangeFilterOptions('grey')}} style={{padding:'4px', cursor: 'pointer'}} src="/wp-content/uploads/2019/03/grey.png" alt="grey" />
                  <img onClick={() => {this.setColorRangeFilterOptions('red')}} style={{padding:'4px', cursor: 'pointer'}} src="/wp-content/uploads/2019/03/Redburgandy.png" alt="red-burgandy" />
                  <img onClick={() => {this.setColorRangeFilterOptions('taupe')}} style={{padding:'4px', cursor: 'pointer'}} src="/wp-content/uploads/2019/03/Taupe.png" alt="taupe" />
                  <img onClick={() => {this.setColorRangeFilterOptions('beige')}} style={{padding:'4px', cursor: 'pointer'}} src="/wp-content/uploads/2019/03/beige.png" alt="beige" />
                  <img onClick={() => {this.setColorRangeFilterOptions('black')}} style={{padding:'4px', cursor: 'pointer'}} src="/wp-content/uploads/2019/03/black.png" alt="black" />
                  <img onClick={() => {this.setColorRangeFilterOptions('blue')}} style={{padding:'4px', cursor: 'pointer'}} src="/wp-content/uploads/2019/03/blueteal.png" alt="blue-teal" />
                  <img onClick={() => {this.setColorRangeFilterOptions('bright')}} style={{padding:'4px', cursor: 'pointer'}} src="/wp-content/uploads/2019/03/bright.png" alt="bright" />
                  <img onClick={() => {this.setColorRangeFilterOptions('brown')}} style={{padding:'4px', cursor: 'pointer'}} src="/wp-content/uploads/2019/03/brown.png" alt="brown" />
                  <img onClick={() => {this.setColorRangeFilterOptions('gold')}} style={{padding:'4px', cursor: 'pointer'}} src="/wp-content/uploads/2019/03/goldyellow.png" alt="gold-yellow" />
                  <img onClick={() => {this.setColorRangeFilterOptions('green')}} style={{padding:'4px', cursor: 'pointer'}} src="/wp-content/uploads/2019/03/green.png" alt="green" />
                  <img onClick={() => {this.setColorRangeFilterOptions('orange')}} style={{padding:'4px', cursor: 'pointer'}} src="/wp-content/uploads/2019/03/orangerust.png" alt="orange-rust" />
                  <img onClick={() => {this.setColorRangeFilterOptions('purple')}} style={{padding:'4px', cursor: 'pointer'}} src="/wp-content/uploads/2019/03/purple.png" alt="purple" />
                </div>
              </div>
            </div>
            }
            { hardSurfaceCat.indexOf(categoryName) !== -1 &&
              <div className="filter-item">
              <div className="left">
                Shades
              </div>
              <div className="right"><a href="##" id="color-rangeMobile-link" onClick={() => this.displayFilterOption('color-rangeMobile-options','color-rangeMobile-link')}>+</a></div>
              <div id="color-rangeMobile-options" className="filter-item">
                <div className="filter-options">
                <CheckboxGroup 
                  checkboxDepth={3} 
                  name="hardSurfaceShades"
                  onChange={this.setHardSurfaceShadesFilterOptions} 
                > 
                  { this.state.hardSurfaceShades.map( hShades => (
                  <div key={hShades}>
                    <label>
                      <Checkbox value={hShades} /> {hShades}
                    </label>
                  </div>
                  ))}
                </CheckboxGroup>
                </div>
              </div>
            </div>
            }
        </div>
        <div className="filter-mobile-buttons">
        <a href="##" id="mobile-filter-clear-button" style={{paddingLeft:'5px'}} onClick={this.clearAllFilters}><button className="filter-clear-button-mobile">Clear</button></a>&nbsp;
        <a href="##" id="mobile-filter-done-button" onClick={() => this.displayMobileFilterOptions('filter-mobile-id')}><button className="filter-done-button-mobile">Done</button></a>
        </div>
        </div>

      {/** Product list block */}
      <div className="product-list-wrapper-mobile">
        { products.map( product => (
          <div className="product-mobile" key={product.id}>
            <div className="product-wrapper-mobile">
            {product.acf.style_image_spark_url ? ( 
            <a href={
              `https://spark.signatureflooring.com/products/open/?ID=` +
              product.acf.design_id +
              product.acf.style_image_spark_url
              }
             target="_new">
            <div className="product-block">
              <div className="style-customize-button-text-mobile">
                {product.acf.signature_now_product && 
                    <div className="notify-badge-mobile">
                      <div style={{height: '40px', left: '190px'}}></div>
                      <span>NOW</span>
                    </div>
                  }  
              <img src={product.acf.product_details_main_image.url} alt={product.title.rendered} width={128} height={128} />
              <br/>{product.title.rendered} <span className="">{product.acf.style_number}</span><br/>
              </div>
            </div>
            </a>
            ) : ( 
            <a href={SignatureFlooringSettings.path + "productinfo/"+product.slug}>
              <div className="product-block">
                {product.acf.signature_now_product && 
                    <div className="notify-badge-mobile">
                      <div style={{height: '40px'}}></div>
                      <span>NOW</span>
                    </div>
                  }  
              <img src={product.acf.product_details_main_image.url} alt={product.title.rendered} width={128} height={128} /><br/>{product.title.rendered} <span className="">{product.acf.style_number}</span><br/>
              </div>
            </a>
            )}
            </div>
          </div>
        ))}
      </div>
      </div>
    </div>
    )
  }
}

export default ProductCategories