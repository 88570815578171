import React, { useState, useEffect } from 'react';
import './styles/salesreps.css'


const SalesReps = () => {
  const [salesReps, setSalesReps] = useState({country: 'United States', reps: [], baseReps: []});
  const [countryNavLinkClass, setCountryNavLinkClass] = useState(['active-link', '', '']);
  

  const fetchSalesReps = async (country = 'United States') => {
		const salesRepsApiCall = await fetch(`/wp-json/wp/v2/salesreps?per_page=100&filter[meta_key]=country&filter[meta_value]=${country}&orderby=slug&order=asc`);
    const salesRepResponse = await salesRepsApiCall.json();
    await setSalesReps({country: country, reps: salesRepResponse, baseReps: salesRepResponse});
  }

  const filterSalesReps = (e) => {
    e.preventDefault();
    console.log(e.target.value)
    if (e.target.value !== 'ALL') {
      const results = salesReps.baseReps.filter(rep => rep.acf.states_covered.includes(e.target.value));
      setSalesReps({country: salesReps.country, reps: results, baseReps: salesReps.baseReps})
    } else {
      setSalesReps({country: salesReps.country, reps: salesReps.baseReps, baseReps: salesReps.baseReps})
    }
    
  }

  const setNavLink = (navItem) => {
    if (navItem === 0) {
      setCountryNavLinkClass(['active-link', '', ''])    
    } else if (navItem === 1) {
      setCountryNavLinkClass(['', 'active-link', ''])
    } else if (navItem === 2) {
      setCountryNavLinkClass(['', '', 'active-link'])
    }
  }

  useEffect( () => {
    fetchSalesReps();
  }, []);

  
  

  return (
    <div>
        <div className="sales-rep-header">
          <div className="header-content">
            <h2>Find a Rep</h2>
            <nav>
              <ul>
                <li><a href="##" className={countryNavLinkClass[0]} onClick={() => {setNavLink(0); fetchSalesReps('United States')}}>United States<br/></a></li>
                <li><a href="##" className={countryNavLinkClass[1]}  onClick={() => {setNavLink(1); fetchSalesReps('Canada')}}>Canada<br/></a></li>
                <li><a href="##" className={countryNavLinkClass[2]}  onClick={() => {setNavLink(2); fetchSalesReps('International')}}>International<br/></a></li>
              </ul>
            </nav>
          </div>
        </div>
        { salesReps.country === 'United States' &&
        <div className="sales-rep-filter-form">
          <ul>
            
            <li>
              <label>State</label><br/>
              <select id="frmState" name="frmState" onChange={(e) => filterSalesReps(e)}>
                <option value="ALL">All</option>
                <option value="Alabama">Alabama</option>
                <option value="Alaska">Alaska</option>
                <option value="Arizona">Arizona</option>
                <option value="Arkansas">Arkansas</option>
                <option value="California">California</option>
                <option value="Colorado">Colorado</option>
                <option value="Connecticut">Connecticut</option>
                <option value="Delaware">Delaware</option>
                <option value="District Of Columbia">District Of Columbia</option>
                <option value="Florida">Florida</option>
                <option value="Georgia">Georgia</option>
                <option value="Hawaii">Hawaii</option>
                <option value="Idaho">Idaho</option>
                <option value="Illinois">Illinois</option>
                <option value="Indiana">Indiana</option>
                <option value="Iowa">Iowa</option>
                <option value="Kansas">Kansas</option>
                <option value="Kentucky">Kentucky</option>
                <option value="Louisiana">Louisiana</option>
                <option value="Maine">Maine</option>
                <option value="Maryland">Maryland</option>
                <option value="Massachusetts">Massachusetts</option>
                <option value="Michigan">Michigan</option>
                <option value="Minnesota">Minnesota</option>
                <option value="Mississippi">Mississippi</option>
                <option value="Missouri">Missouri</option>
                <option value="Montana">Montana</option>
                <option value="Nebraska">Nebraska</option>
                <option value="Nevada">Nevada</option>
                <option value="New Hampshire">New Hampshire</option>
                <option value="New Jersey">New Jersey</option>
                <option value="New Mexico">New Mexico</option>
                <option value="New York">New York</option>
                <option value="North Carolina">North Carolina</option>
                <option value="North Dakota">North Dakota</option>
                <option value="Ohio">Ohio</option>
                <option value="Oklahoma">Oklahoma</option>
                <option value="Oregon">Oregon</option>
                <option value="Pennsylvania">Pennsylvania</option>
                <option value="Rhode Island">Rhode Island</option>
                <option value="South Carolina">South Carolina</option>
                <option value="South Dakota">South Dakota</option>
                <option value="Tennessee">Tennessee</option>
                <option value="Texas">Texas</option>
                <option value="Utah">Utah</option>
                <option value="Vermont">Vermont</option>
                <option value="Virginia">Virginia</option>
                <option value="Washington">Washington</option>
                <option value="West Virginia">West Virginia</option>
                <option value="Wisconsin">Wisconsin</option>
                <option value="Wyoming">Wyoming</option>
              </select>
            </li>
            
          </ul>
        </div>
}
        <div className="sales-reps-wrapper">
          <div className="sales-reps-data">
            {salesReps.reps.map( sRep => (
              <div className="sales-rep-cell" key={sRep.id}>
                <div className="sales-rep-image">
                  <img src={sRep.acf.rep_image.url} alt={sRep.title.rendered} style={{width: '154px', height: '154px', borderRadius: '50%'}} />
                </div>
                <div className="sales-rep-details">
                  <h3>{sRep.title.rendered}</h3>
                  <h5>{sRep.acf.region}</h5>
                  <p>
                    <i className="fa fa-phone"></i> {sRep.acf.phone}
                    <br/>
                    <i className="fa fa-envelope"></i> {sRep.acf.email_address}
                  </p>
                  { sRep.acf.segments &&
                    <div className="rep-segments">
                      { sRep.acf.segments === 'Hospitality' &&
                        <span className="segment-hospitality">Hospitality</span>
                      }
					  { sRep.acf.segments === 'Senior Living' &&
                        <>
                          <span className="segment-hospitality">Hospitality</span>&nbsp;
                          <span className="segment-senior-living">Senior Living</span>
                        </>
                      }
                      { sRep.acf.segments === 'Commercial' &&
                        <span className="segment-senior-living">Commercial</span>
                      }
                      { sRep.acf.segments === 'H&C' &&
                        <>
                          <span className="segment-hospitality">Hospitality</span>&nbsp;
                          <span className="segment-senior-living">Commercial</span>
                        </>
                      }
					  { sRep.acf.segments === 'Hospitality and Senior Living' &&
                        <>
                          <span className="segment-hospitality">Hospitality</span>&nbsp;
                          <span className="segment-senior-living">Senior Living</span>
                        </>
                      }
                    </div>
                  }                 
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="sales-rep-hear-from-you">
          <h2>We would love to hear from you.</h2>
          <div className="sales-rep-contact-ways-wrapper">
              <div className="contact-ways1">
                <span className="contact-ways-header"><p>Phone & Email</p></span>
                <span className="contact-ways">
                  <p>(800) 809-7086</p>
                  <p>(706) 270-5799</p>
                  <p>(706) 270-8779 fax</p>
                </span>
                <span className="contact-email">info@signatureflooring.com</span>
              </div>

              <div className="contact-ways2">
                <span className="contact-ways-header"><p>Office</p></span>
                <span className="contact-ways">
                  <p>2222 South Hamilton Street</p>
                  <p>Dalton, Georgia 30721</p>
                </span>
              </div>

              <div className="contact-ways3">
                <span className="contact-ways-header"><p>Mailbox</p></span>
                <span className="contact-ways">
                  <p>P.O. Box 1328</p>
                  <p>Dalton, Georgia 30722</p>
                </span>
              </div>
          </div>
        </div>
      </div>
  )
}

export default SalesReps;