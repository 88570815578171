import React, { Component } from 'react'
import Slider from 'react-slick'
import axios from 'axios'
import './styles/hospitalityfeaturedstyles.css'

class OutboundCollectionFeaturedStylesMobile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      featuredStyles: []
    }
  }

  componentWillMount() {
    axios.get('/wp-json/wp/v2/featured-styles-bdny?filter[meta_key]=bdny_collection_name&filter[meta_value]=OutboundCollection&filter[orderby]=bdny_display_order&order=asc')
    .then(response => {
      this.setState({
        featuredStyles: response.data
      }, () => console.log(this.state.featuredStyles))
      
    })
    .catch(errors => console.log(errors))
  }

  render() {
    const { featuredStyles } = this.state
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false
    }

    return (
      <div className="ds-featuredstyles-wrapper">
        <div className="ds-featuredstyles-wrapper ds-featuredstyles-header">
        </div>
        <div className="ds-featuredstyles-wrapper ds-featuredstyles-entries">
          <div className="container">
          {/*<div className="card-group card-group-scroll">*/}
          <div>
            <Slider {...settings}>
            { featuredStyles.map((item) => (
              <div className="cardPadding" key={item.id}>
              <div className="ds-slider-card">
              <a href={item.acf.bdny_featured_style_image_url} target="_new"><img className="card-img-top" height={280} width={324} src={item.acf.bdny_style_image.url} alt={item.title.rendered} /></a>
              <div className="card-body">
                <h5 className="card-title">{item.title.rendered}</h5>
                <p><a href={item.acf.design_studio_link} target="_new" style={{fontFamily: 'Adelle Sans Lt', fontSize: '16px', color: '#777', textDecoration: 'none'}}></a></p>
              </div>
            </div>
            </div>
            ))}            
            </Slider>
          </div>
        </div>
        </div>
      </div>
    )
  }
}

export default OutboundCollectionFeaturedStylesMobile
