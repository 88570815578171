import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";
import CustomEasy from "../Components/PageComponents/CustomEasy";
import EmergeFeaturedStyles from "../Components/BDNY2021Components/EmergeFeaturedStyles";
import EmergeFeaturedStylesMobile from "../Components/BDNY2021Components/EmergeFeaturedStylesMobile";
import JourneyFeaturedStyles from "../Components/BDNY2021Components/JourneyFeaturedStyles";
import JourneyFeaturedStylesMobile from "../Components/BDNY2021Components/JourneyFeaturedStylesMobile";
import UrbanEscapesFeaturedStyles from "../Components/BDNY2021Components/UrbanEscapesFeaturedStyles";
import UrbanEscapesFeaturedStylesMobile from "../Components/BDNY2021Components/UrbanEscapesFeaturedStylesMobile";
import "./styles/bdny2021.css";

const BDNY2021 = () => {
  let opts = {
    width: "100%",
    height: "625"
  };
  return (
    <div className="bdny-2021-wrapper">

      <div className="bdny-2021-header">
        <div className="bdny-2021-header-content">
        </div>
      </div>

      <div className="spark-video" style={{ backgroundColor: "white" }}>
        <p><br></br></p>
        <p style={{ margin: "0% 20% 0% 20%", textAlign: "center" }}>Signature Flooring is pleased to introduce the Emerge Collection which will come to life at the premier hospitality show, Boutique Design New York (BDNY), held November 14-15 at the Jacob K. Javits Convention Center in New York. From the beautiful details of butterfly wings to the vast flora upon which they alight, the Emerge Collection creates a scenic pathway throughout hospitality spaces.</p>
        <p><br></br></p>
        <p style={{ margin: "0% 20% 0% 20%", textAlign: "center" }}>Signature’s latest collection Emerge rises from the physical, emotional and mental feelings we have all encountered over the past year and half. As a butterfly symbolizes strength through life, endurance, change and hope, we found it to be inspirational in our ideas, thoughts and designs for this collection and our theme for BDNY. We could not be more thrilled with this thoughtful and beautiful collection, but is much more than flooring, it represents a reflection of our past and a transformation of renewed purpose.</p>
        <p><br></br></p>
        <p><br></br></p>
      </div>

      <div className="bdny-video" style={{ backgroundColor: "white" }}>
        {/*<h6>Placeholder for Angie Video 1</h6>*/}
        <YouTube videoId="Gmc_--sbKAU" opts={opts} />
      </div>

      <div className="spark-video" style={{ backgroundColor: "white" }}>
        <p><br></br></p>
        <h3>Emerge Collection</h3>
        <p style={{ margin: "0% 20% 0% 20%", textAlign: "center" }}>From beautiful details of butterfly wings to the vast flora upon which they alight, let your imagination fly with the EMERGE Collection creating scenic pathways throughout hospitality spaces.</p>
        <p><br></br></p>
        <p><br></br></p>
      </div>

      <div className="bdny-video" style={{ backgroundColor: "white" }}>
        {/*<h6>Placeholder for Beth Video 1</h6>*/}
        <YouTube videoId="cVOFJqua7RM" opts={opts} />
      </div>

      <div className="bdny-marketing-block">
        <div className="smb-graphic">
        <img src="/wp-content/uploads/2021/11/SIG230_EmergePR_480x352.jpg" alt="Emerge Collection" height="300px" />
        </div>
        <div className="smb-text">
          <p className="bold-text">Emerge Collection</p>
          <p>Available in fourteen coordinating patterns, the Emerge Collection allows for fluidity throughout the entire space, from public spaces to corridors to guest rooms. These organic and geometric patterns create balanced compositions and contemporary styling.</p>
          <p>The Emerge Collection is available as high performance broadloom and carpet tile with full color customization.</p>
          
          <div className="buttons-wrapper"> 
            
            <div className="design-button">
            <a
              href="/wp-content/uploads/2021/10/Emerge_Collection_Brochure_Final-compressed.pdf"
              target="_new"
              rel="noopener noreferrer"
            >
              <button className="design-row-button-large">
                Download Brochure
              </button>
            </a>
            &nbsp;&nbsp;
            <a
              href="/wp-content/uploads/2021/11/Emerge-Collection-Press-Release.pdf"
              target="_new"
              rel="noopener noreferrer"
            >
              <button className="design-row-button-large">
                Press Release
              </button>
            </a>
            </div>

          </div>
        
        </div>
        {/*<hr className="smb-hr" />*/}
      </div>

      <div className="bdny-marketing-block">
        <div className="smb-graphic">
        <img src="/wp-content/uploads/2021/11/SIG230-Legacy-Garden_480x352.jpg" alt="Emerge Collection" height="300px" />
        </div>
        <div className="smb-text">
        <p className="bold-text">Legacy Garden</p>
          <p>Signature will be donating a percentage of the profits from the Emerge Collection to our Legacy Garden in support of its mission to share produce and a beautiful space with our local community, surrounding area food banks, outreach programs and our employees.</p>
          <div className="design-button">
          <a
            href="/wp-content/uploads/2021/11/21114-BDNY-Legacy-Garden-compressed.pdf"
            target="_new"
            rel="noopener noreferrer"
          >
            <button className="design-row-button-large">
              View Legacy Story
            </button>
          </a>
          </div>
        </div>
        {/*<hr className="smb-hr" />*/}
      </div>

      <section className="ds-featuredstyles-section">
        <EmergeFeaturedStyles />
      </section>
      
      <section className="ds-featuredstyles-section-mobile">
        <EmergeFeaturedStylesMobile />
      </section>

      <div className="bdny-2021-header2">
        <div className="bdny-2021-header2-content">
        </div>
      </div>

      <div className="bdny-marketing-block">
        <div className="smb-graphic">
        <img src="/wp-content/uploads/2021/11/SIG230-Journey-Inspiration_480x352.jpg" alt="Journey Collection" height="300px" />
        </div>
        <div className="smb-text">
          <p className="bold-text">Journey Collection</p>
          <p>GUEST ROOMS</p>
          <p>Journey brings a world of rich texture and sophistication allowing designers to push their creativity without boundaries. With a global composition, this collection blends seamlessly into any design portfolio and effortlessly into many genres of style.</p>

        
        {/*<div className="design-button">
          <a
            href="/wp-content/uploads/2021/11/SIG217-Journey-brochure_final_revision4.pdf"
            target="_new"
            rel="noopener noreferrer"
          >
            <button className="design-row-button-large">
              Download Brochure
            </button>
          </a>
        </div>
        */}

        <div className="buttons-wrapper"> 
            
            <div className="design-button">
            <a
              href="/wp-content/uploads/2021/11/SIG217-Journey-brochure_final_revision4.pdf"
              target="_new"
              rel="noopener noreferrer"
            >
              <button className="design-row-button-large">
                Download Brochure
              </button>
            </a>
            &nbsp;&nbsp;
            <a
              href="/wp-content/uploads/2021/11/JOURNEY-COLLECTION-Press-Release.pdf"
              target="_new"
              rel="noopener noreferrer"
            >
              <button className="design-row-button-large">
                Press Release
              </button>
            </a>
            </div>

          </div>

        </div>
        {/*<hr className="smb-hr" />*/}
      </div>

      <div className="bdny-video" style={{ backgroundColor: "white" }}>
        {/*<h6>Placeholder for Beth Video 2</h6>*/}
        <YouTube videoId="4rJzIU7hnIo" opts={opts} />
      </div>

      <section className="ds-featuredstyles-section">
        <JourneyFeaturedStyles />
      </section>
      
      <section className="ds-featuredstyles-section-mobile">
        <JourneyFeaturedStylesMobile />
      </section>

      <div className="bdny-marketing-block">
        <div className="smb-graphic">
        <img src="/wp-content/uploads/2021/11/SIG230-Journey-Additional-Patterns_480x352.jpg" alt="Journey Additional Patterns" height="300px" />
        </div>
        <div className="smb-text">
          <p className="bold-text">Journey's Additional Pattern Options</p>
          <p>Nine additional patterns excite and delight for guest room experiences, designed for coordination and flexibility in creating unique spaces.</p>
          <div className="design-button">
          <a
            href="/wp-content/uploads/2021/11/SIG217-Journey-brochure_final_revision4.pdf"
            target="_new"
            rel="noopener noreferrer"
          >
            <button className="design-row-button-large">
              View Additional Patterns
            </button>
          </a>
        </div>
        </div>
        {/*<hr className="smb-hr" />*/}
      </div>

      <div className="spark-video" style={{ backgroundColor: "white", marginBottom: "-16px", marginTop: "10px" }}>
        <h3>SignatureNOW</h3>
        <p style={{ margin: "0% 20% 0% 20%", textAlign: "center"}}>Featuring a broadloom carpet program along with LVT flooring delivering in four weeks or less, solving your carpet needs with incredible style, service, and value.</p>
        <p><br></br></p>
        <p><br></br></p>
      </div>

      <div className="bdny-marketing-block">
        <div className="smb-graphic">
        <img src="/wp-content/uploads/2021/11/SIG231-Modern-Style-BDNY-Image.jpg" alt="Modern Style" height="300px" />
        </div>
        <div className="smb-text">
        <p className="bold-text">Modern Style</p>
          <p>Modern Style makes it simple to give any space a unique sense of character. Easy to use patterns, scales, colors and textures, working together or independently. Offering seven patterns and seven colorways for public spaces, corridors, guest rooms, rugs and cove base.</p>
          <div className="design-button">
          <a
            href="/wp-content/uploads/2021/11/SIG231-Modern-Style_final.pdf"
            target="_new"
            rel="noopener noreferrer"
          >
            <button className="design-row-button-large">
              View Modern Style
            </button>
          </a>
        </div>
        </div>
        {/*<hr className="smb-hr" />*/}
      </div>

      <div className="bdny-marketing-block" style={{ marginBottom: "10px" }}>
        <div className="smb-graphic">
        <img src="/wp-content/uploads/2021/11/SIG230_NatureCraft_ClassicGray_Weatherwash_480x352.jpg" alt="Nature Craft" height="300px" />
        </div>
        <div className="smb-text">
        <p className="bold-text">Nature Craft</p>
          <p>Nature Craft, LVT has rich colorations ranging from neutral beige to tavern brown and useable greys from weather wash to smokey timber. Available in 7&quot; x 48&quot; plank size, and 20MIL construction.</p>
          <div className="design-button">
          <a
            href="/wp-content/uploads/2019/09/4177_SIG_NatureCraft_03_02.pdf"
            target="_new"
            rel="noopener noreferrer"
          >
            <button className="design-row-button-large">
              Download Brochure
            </button>
          </a>
        </div>
        </div>
        {/*<hr className="smb-hr" />*/}
      </div>

      <div className="bdny-2021-header3">
        <div className="bdny-2021-header3-content">
        </div>
      </div>

      <div className="bdny-marketing-block">
        <div className="smb-graphic">
        <img src="/wp-content/uploads/2021/11/SIG230-Urban-Escapes_721_419x300.jpg" alt="Urban Escapes" height="300px" />
        </div>
        <div className="smb-text">
          <p className="bold-text">Urban Escapes</p>
          <p>Clean, subdued and refined graining, lays the groundwork for Urban Escapes, LVT, featuring visual sophistication with minimal design.</p>
          <p>Urban Escapes Biophilic design principles, allows for a connection with nature, creating a sense of warmth, beauty and well-being; and an enjoyment to the spaces we work, live and play.</p>
        </div>
        {/*<hr className="smb-hr" />*/}
      </div>

      <div className="bdny-video" style={{ backgroundColor: "white" }}>
        {/*<h6>Placeholder for Beth Video 3</h6>*/}
        <YouTube videoId="oSiu_DIQE_k" opts={opts} />
      </div>

      <section className="ds-featuredstyles-section">
        <UrbanEscapesFeaturedStyles />
      </section>
      
      <section className="ds-featuredstyles-section-mobile">
        <UrbanEscapesFeaturedStylesMobile />
      </section>

    </div>
  );
};

export default BDNY2021;
