import React, { Component } from 'react'
import axios from 'axios'

import './styles/customdesign.css'

class CustomDesign extends Component {

  constructor(props) {
    super(props)

    this.state = {
      customDesignTitle: '',
      customDesignContent: '',
      secondContentBlock: ''
    }
  }

  componentWillMount() {
    axios.get('/wp-json/wp/v2/pages?slug=custom-design-services')
      .then(res => {
        this.setState({
          customDesignTitle: res.data[0].title.rendered,
          customDesignContent: res.data[0].content.rendered,
          secondContentBlock: res.data[0].acf.second_content_block,
        })
      })
      .catch(errors => console.log(errors))
  }

  render() {
    const { customDesignTitle, customDesignContent, secondContentBlock } = this.state
    return (
      <div className="custom-design-wrapper">
        <div className="custom-design-header">
          <div className="custom-design-header-content">
              <h2>Custom Design Services</h2>
          </div>
        </div>
        <div className="segemnt-above-fold">
          <h3>{ customDesignTitle }</h3>
          <div dangerouslySetInnerHTML={{__html: customDesignContent}} />
        </div>
        <div dangerouslySetInnerHTML={{__html: secondContentBlock}} />       
        <section className="home-find-rep-section">
          <div className="home-find-rep-wrapper">
            <div className="home-find-rep-text">
              <p style={{textAlign:'center'}}>Ready to Get Started?</p>
              <p style={{textAlign:'center', fontFamily: 'Adelle Sans Lt', fontSize:'16px', margin: '0 auto', width:'70%', paddingBottom:'50px'}}>
                Our custom carpet design process is fast, easy and doesn’t require you to compromise your design vision to meet your deadlines. Please contact your sales representative or call Signature at 800-809-7086 to get started.
              </p>
              <a href="/salesreps"><button className="home-find-rep-button">Find Your Signature Rep</button></a>
            </div>
          </div>
        </section>

      </div>
    )
  }
}

export default CustomDesign