import React, { Component } from 'react'
import 'font-awesome/css/font-awesome.min.css';
import './styles/footermobile.css';

class FooterMobile extends Component {

  constructor(props) {
    super(props)
    this.state = {
      SignatureFlooringSettings: window.SignatureFlooringSettings
    }
    this.displayFilterOption = this.displayFilterOption.bind(this)
  }

  displayFilterOption(id, link) {
    var e = document.getElementById(id)
    console.log(e)
    
    var l = document.getElementById(link)
    console.log(l)
    if(e.style.display === 'block') {
      e.style.display = 'none'
      l.innerHTML = '+'
    } else {
      e.style.display = 'block'
      e.style.textAlign = 'left'
      l.innerHTML = '-'
    }
  }

  render() {
    return (
      <footer>
    <div className="footermobile-content">
      <div className="footermobile-logo">
      <a href={this.state.SignatureFlooringSettings.path}><img src="/wp-content/uploads/2019/02/company_logo2.png" height={50} alt="Signature Flooring" /></a>
      </div>
      <div className="footermobile-find-rep">
        <a href="/salesreps"><button className="find-rep-button">Find a Rep</button></a>
      </div>
      <div className="footermobile-category-wrapper">
        <div className="footermobile-link-cell">
          <div className="footermobile-item">
            <div className="footermobile-item-left">
              Resources
            </div>
            <div className="footermobile-item-right">
              <a href="##" id="footerResource-link" onClick={() => this.displayFilterOption('footerResource-options','footerResource-link')}>+</a>
            </div>
            <div id="footerResource-options">
              <a href="/r/resources/specs" className="footer-links">Specifications</a><br/>
              <a href="/r/resources/installation" className="footer-links">Installation</a><br/>
              <a href="/r/resources/sundries" className="footer-links">Sundries</a><br/>
              <a href="/r/resources/maintenance-resources" className="footer-links">Care & Maintenance</a><br/>
              <a href="/r/resources/warranties" className="footer-links">Warranties</a><br/>
              <a href="/r/resources/cads" className="footer-links">CADS</a><br/>
            </div>
          </div>
          <div className="footermobile-item">
            <div className="footermobile-item-left">
              Company
            </div>
            <div className="footermobile-item-right">
              <a href="##" id="footerCompany-link" onClick={() => this.displayFilterOption('footerCompany-options','footerCompany-link')}>+</a>
            </div>
            <div id="footerCompany-options">
              <a href="/aboutus" className="footer-links">About Us</a><br/> 
              <a href="/sustainability" className="footer-links">Sustainability</a><br/>
              <a href="/tradeshows" className="footer-links">Trade Shows</a><br/>
            </div>
          </div>
          <div className="footermobile-last-item">
            <div className="footermobile-item-left">
              Media
            </div>
            <div className="footermobile-item-right">
              <a href="##" id="footerMedia-link" onClick={() => this.displayFilterOption('footerMedia-options','footerMedia-link')}>+</a>
            </div>
            <div id="footerMedia-options">
              <a href="/media/pressreleases" className="footer-links">Press Releases</a><br/>
              <a href="/media/ads" className="footer-links">Ads</a><br/>
              <a href="/media/videos" className="footer-links">Videos</a><br/>
            </div>
          </div>
        </div>
      </div>
      <div className="footermobile-social-icons">
        <a href="https://www.facebook.com/signaturefloorsforsignaturespaces" target="_new"><i className="fa fa-facebook-f"></i></a>&nbsp;
        <a href="https://www.instagram.com/signature__flooring/" target="_new"><i className="fa fa-instagram"></i></a>&nbsp;
        <a href="https://www.pinterest.com/Signature__Flooring/" target="_new"><i className="fa fa-pinterest"></i></a>&nbsp;
        <a href="https://www.linkedin.com/company/floors-for-signature-spaces/" target="_new"><i className="fa fa-linkedin"></i></a>&nbsp;
      </div>
      <div className="footermobile-legal">
        <a href="##">Terms of Use</a><br/> 
        <a href="##">Privacy Policy</a><br/>
        {'\u00A9'}2024 Signature Flooring. All rights reserved.<br/>
        (800) 809-7068
      </div>
    </div>
  </footer>
    )
  }
}

export default FooterMobile
