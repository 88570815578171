import React, { Component } from 'react'
import axios from 'axios'
import './styles/segments.css'

const headerStyle = {
  width: '100%',
  height: '450px',
  background: 'linear-gradient(to bottom, transparent 0%, black 175%), url("/wp-content/uploads/2020/03/covid19-page.jpg")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center'
}

const h4Style = {
  fontFamily: 'Zahrah',
  fontSize: '30px',
  color: '#fff',
  position: 'absolute',
  top: '60%',
  marginLeft:'100px',
}

const h3Style = {
  textAlign: 'left',
  marginLeft: '75px',
  marginTop: '20px',
  paddingBottom: '20px'
}

const pStyle = {
  textAlign: 'left',
  marginLeft: '75px',
  paddingBottom: '35px',
}

const headerStyleMobile = {
  width: '100%',
  height: '450px',
  background: 'linear-gradient(to bottom, transparent 0%, black 175%), url("/wp-content/uploads/2020/03/covid19-page.jpg")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center'
}

/*const segmentDesignTypes = {
  height: '400px',
  position: 'relative',
  background: 'linear-gradient(rgba(100,52,96,.9),rgba(100,52,96,.9)), url("/wp-content/uploads/2018/12/homepage-sigaccordbg.jpg")',
  backgroundSize: 'cover',
  bottom:'0',
  top:'0'
}*/

const segmentDesignTypesMobile = {
  height: '1000px',
  position: 'relative',
  background: 'linear-gradient(rgba(100,52,96,.9),rgba(100,52,96,.9)), url("/wp-content/uploads/2018/12/homepage-sigaccordbg.jpg")',
  backgroundSize: 'cover',
  bottom:'0',
  top:'0'
}

const tradeShowsWrapper = {
  minHeight: '400px',
  background: '#643460'
}

class Covid19 extends Component {

  constructor(props) {
    super(props)

    this.state = {
      pageTitle: '',
      pageContent: ''
    }
  }

  componentWillMount() {
    axios.get('/wp-json/wp/v2/pages?slug=caring-for-the-safety-and-well-being-of-our-clients-friends-and-community')
      .then(res => {
        this.setState({
          pageTitle: res.data[0].title.rendered,
          pageContent: res.data[0].content.rendered,
        })
      })
      .catch(errors => console.log(errors))
  }


  render() {
    const { pageTitle, pageContent } = this.state
    return (
      <>
      <div className="segment-wrapper-desktop">
        <div className="hospitality-segment-header" style={headerStyle}>
          <div className="segment-header-content">
          <h4 style={h4Style} dangerouslySetInnerHTML={{__html: pageTitle}}></h4>
          </div>
        </div>        
        <div dangerouslySetInnerHTML={{__html: pageContent}}></div>

      </div>

      </>
    )
  }
}


export default Covid19
