import React, { useState, useEffect } from 'react'
import axios from 'axios'
import "./styles/home.css";
import "./styles/w3.css";
import HomeCarousel from "../Components/HomeComponents/HomeCarousel";
import HomeCarouselMobile from "../Components/HomeComponents/HomeCarouselMobile";
import HomeBlogs from "../Components/HomeComponents/HomeBlogs";
import HomeBlogsMobile from "../Components/HomeComponents/HomeBlogsMobile";
import SegmentBlocks from "../Components/PageComponents/SegmentBlocks";
import SignatureNow from "../Components/HomeComponents/SignatureNow";
import Sustainability from "../Components/HomeComponents/Sustainability";
import ProjectInstallations from "../Components/HomeComponents/ProjectInstallations";
import { Helmet } from "react-helmet";

const Home = () => {

  const [pageData, setPageData] = useState({
    pageTitle: '',
    pageContent: '',
    isLoaded: false
  });
  
  useEffect(() => {
    axios.get('/wp-json/wp/v2/pages?slug=home')
    .then((res) => {
      setPageData({
        pageTitle: res.data[0].title.rendered,
        pageContent: res.data[0].content.rendered,
        isLoaded: true
      });
    })
    .catch(err => console.log(err));
  }, []);

  return (
    <div>
      <Helmet>
          <title>Signature Flooring - Floors For Signature Spaces</title>
          <meta
            property="og:title"
            content="Signature Flooring - Floors For Signature Spaces"
          />
        </Helmet>
        <div id="home-container">
          <section className="home-hero-section">
            <HomeCarousel />
          </section>
          <section className="home-hero-section-mobile">
            <HomeCarouselMobile />
          </section>

          <section className="home-marketing-section">
            <h2>{pageData.pageTitle}</h2>
            <p
              dangerouslySetInnerHTML={{ __html: pageData.pageContent }}
              style={{ textAlign: "center", minWidth: "300px", margin: "0" }}
            />
          </section>
        </div>
		
		{/*	
        <section className="home-product-section">
            <div className="home-product-wrapper">
              <a href="/sustainability" className="photo large box-1">
                <div className="home-product-text">
                  <p className="home-product-header">Sustainability</p>
                  <p className="home-product-header"></p>
                </div>
              </a>
              <a href="/carpet/Carpet Tile" className="photo box-2">
                <div className="home-product-box-text home-product-text-mobile">
                  <p className="home-product-header">Carpet Tile</p>
                  <p className="home-product-header">Terra Cruda</p>
                </div>
              </a>
              <a href="/productinfo/urban-escapes-spc" className="photo box-3">
                <div className="home-product-box-text home-product-text-mobile">
                  <p className="home-product-header">Hard Surface</p>
                  <p className="home-product-header">Urban Escapes SPC</p>
                </div>
              </a>
			  <a href="/productinfo/trellis" className="photo box-15">
                <div className="home-product-box-text home-product-text-mobile">
                  <p className="home-product-header">Entryway System</p>
                  <p className="home-product-header">Trellis</p>
                </div>
              </a>
			  <a href="/custom_collections/boldacious-collection" className="photo box-16">
                <div className="home-product-box-text home-product-text-mobile">
                  <p className="home-product-header">Custom Broadloom</p>
                  <p className="home-product-header">BOLDacious Collection</p>
                </div>
              </a>
			  <a href="/carpet/rugs" className="photo box-4">
                <div className="home-product-box-text home-product-text-mobile">
                  <p className="home-product-header">Rugs</p>
                  <p className="home-product-header">Treasure</p>
                </div>
              </a>
              <a href="/productinfo/traven" className="photo box-5">
                <div className="home-product-box-text home-product-text-mobile">
                  <p className="home-product-header">Broadloom</p>
                  <p className="home-product-header">Traven</p>
                </div>
              </a>

            </div>
        </section>
		*/}

        <section className="home-product-section">
			<div className="home-product-wrapper">		
		  
				<div className="w3-row home-product-section-sustainability">
				
					<div className="w3-center">

						<div className="w3-col l6 s12 w3-display-container" style={{ marginBottom: "10px" }}>			
							<a href="/sustainability">
							<img src="/wp-content/uploads/2024/02/Sustainability_507x242-2.jpg" alt="Sustainability" className="w3-image"/>
							</a>
							<div className="w3-display-bottomleft w3-container w3-left-align home-product-text-header">Sustainability</div>
							<div className="w3-display-bottomleft w3-container w3-left-align home-product-text-subheader">&nbsp;</div>
						</div>

						<div className="w3-col l3 s6 w3-display-container" style={{ marginBottom: "10px" }}>
							<a href="/carpet/Carpet Tile">
							<img src="/wp-content/uploads/2024/02/CarpetTile_TerraCruda_248x242_UPDATED.jpg" alt="Carpet Tile" className="w3-image"/>
							</a>
							<div className="w3-display-bottomleft w3-container w3-left-align home-product-text-header">Carpet Tile</div>
							<div className="w3-display-bottomleft w3-container w3-left-align home-product-text-subheader">Terra Cruda</div>
						</div>	
						
						<div className="w3-col l3 s6 w3-display-container" style={{ marginBottom: "10px" }}>
							<a href="/productinfo/urban-escapes-spc">
							<img src="/wp-content/uploads/2024/02/HardSurface_UrbanEscapes_248x242_UPDATED.jpg" alt="Hard Surface" className="w3-image"/>
							</a>
							<div className="w3-display-bottomleft w3-container w3-left-align home-product-text-header">Hard Surface</div>
							<div className="w3-display-bottomleft w3-container w3-left-align home-product-text-subheader">Urban Escapes SPC</div>
						</div>

						<div className="w3-col l3 s6 w3-display-container" style={{ marginBottom: "10px" }}>			
							<a href="/productinfo/trellis">
							<img src="/wp-content/uploads/2024/02/Entryway-Systems_248x242_UPDATED.jpg" alt="Entryway System" className="w3-image"/>
							</a>
							<div className="w3-display-bottomleft w3-container w3-left-align home-product-text-header">Entryway System</div>
							<div className="w3-display-bottomleft w3-container w3-left-align home-product-text-subheader">Trellis</div>
						</div>

						<div className="w3-col l3 s6 w3-display-container" style={{ marginBottom: "10px" }}>
							<a href="/custom_collections/boldacious-collection">
							<img src="/wp-content/uploads/2024/02/CustomBroadloom_BoldaciousHC143_248x242_UPDATED.jpg" alt="Custom Broadloom" className="w3-image"/>
							</a>
							<div className="w3-display-bottomleft w3-container w3-left-align home-product-text-header">Custom Broadloom</div>
							<div className="w3-display-bottomleft w3-container w3-left-align home-product-text-subheader">BOLDacious Collection</div>
						</div>	
						
						<div className="w3-col l3 s6 w3-display-container" style={{ marginBottom: "10px" }}>
							<a href="/carpet/rugs">
							<img src="/wp-content/uploads/2024/02/Rugs_LocalStyle_Treasure_248x242_UPDATED.jpg" alt="Rugs" className="w3-image"/>
							</a>
							<div className="w3-display-bottomleft w3-container w3-left-align home-product-text-header">Rugs</div>
							<div className="w3-display-bottomleft w3-container w3-left-align home-product-text-subheader">Treasure</div>
						</div>

						<div className="w3-col l3 s6 w3-display-container" style={{ marginBottom: "10px" }}>
							<a href="/productinfo/traven">
							<img src="/wp-content/uploads/2024/02/Broadloom_Traven_248x242_UPDATED.jpg" alt="Broadloom" className="w3-image"/>
							</a>
							<div className="w3-display-bottomleft w3-container w3-left-align home-product-text-header">Broadloom</div>
							<div className="w3-display-bottomleft w3-container w3-left-align home-product-text-subheader">Traven</div>
						</div>						

					</div>
				
				</div>
				
			</div>
        </section>

		  
		  
          <SegmentBlocks />		  
		  		  
		{/*
		  <Sustainability />
		*/}
			
		{/* New Sustainability Home Page Section */}
        <section className="home-product-section">
			<div className="home-sustainability-wrapper">		
		  
				<div className="w3-row home-section-sustainability">				
		
					<div className="w3-center">

						<div className="w3-col l8 s12 w3-display-container" style={{ marginBottom: "10px" }}>			
							<a href="/sustainability">
							<img src="/wp-content/uploads/2024/03/Sustainability_NewSectionHP_700x350.jpg" alt="Sustainability" className="w3-image"/>
							</a>
							<div className="w3-display-bottomleft w3-container w3-left-align home-product-text-header-sustainability">Sustainability</div>
							<div className="w3-display-bottomleft w3-container w3-left-align home-product-text-subheader">&nbsp;</div>
						</div>

						<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "10px" }}>
							<a href="https://designforhealth.mindclick.com/vendors/signature" target="_new" rel="noopener noreferrer">
							<img src="/wp-content/uploads/2024/03/MindClick_350x350.jpg" alt="MindClick" className="w3-image"/>
							</a>
							<div className="w3-display-bottomleft w3-container w3-left-align home-product-text-header">MindClick</div>
							<div className="w3-display-bottomleft w3-container w3-left-align home-product-text-subheader">View ratings</div>
						</div>	
						
						<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "10px" }}>
							<a href="/sustainability">
							<img src="/wp-content/uploads/2024/03/SignatureCertificates_350x350.jpg" alt="Signature Certifications" className="w3-image"/>
							</a>
							<div className="w3-display-bottomleft w3-container w3-left-align home-product-text-header">Signature's Certifications</div>
							<div className="w3-display-bottomleft w3-container w3-left-align home-product-text-subheader">Learn more</div>
						</div>

						<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "10px" }}>			
							<a href="/sustainability">
							<img src="/wp-content/uploads/2024/03/SPCLVT_350x350.jpg" alt="SPC LVT" className="w3-image"/>
							</a>
							<div className="w3-display-bottomleft w3-container w3-left-align home-product-text-header">SPC LVT</div>
							<div className="w3-display-bottomleft w3-container w3-left-align home-product-text-subheader">Made In the USA</div>
						</div>

						<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "10px" }}>
							<a href="https://www.youtube.com/watch?v=4WQJAh-zJv8" target="_new" rel="noopener noreferrer">
							<img src="/wp-content/uploads/2024/03/BackingSystems_350x350.jpg" alt="Biobased Backing Systems" className="w3-image"/>
							</a>
							<div className="w3-display-bottomleft w3-container w3-left-align home-product-text-header">Biobased Backing Systems</div>
							<div className="w3-display-bottomleft w3-container w3-left-align home-product-text-subheader">View video</div>
						</div>						

					</div>
				
				</div>
				
			</div>
        </section>
          
          <SignatureNow />

          <ProjectInstallations />
          
          <section className="home-blog-section">
            <HomeBlogs />
          </section>
          <section className="home-blog-section-mobile">
            <HomeBlogsMobile />
          </section>

          <section className="home-find-rep-section">
            <div className="home-find-rep-wrapper">
              <div className="home-find-rep-text">
                <p style={{ textAlign: "center" }}>
                  To floor your Signature space contact your
                  <br /> sales representative or call us at 800-809-7086.
                </p>
                <a href="/salesreps">
                  <button className="home-find-rep-button">
                    Find Your Signature Rep
                  </button>
                </a>
              </div>
            </div>
          </section>
    </div>
  )
}

export default Home;