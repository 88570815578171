import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import './styles/signaturenow.css';

const headerStyle = {
  width: "100%",
  height: "450px",
  background:
    'url("/wp-content/uploads/2023/02/SignatureNOW_PageHeader_v4.jpg")',
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center"
};

const SignatureNow = () => {

  const [nowBroadloomProducts, setNowBroadloomProducts] = useState({});
  const [nowHardSurfaceProducts, setNowHardSurfaceProducts] = useState({});
  const SignatureFlooringSettings = window.SignatureFlooringSettings;  

  const broadloomProducts = async () => {
    const nowProductsApiCall = await fetch('/wp-json/wp/v2/productinfo/?per_page=100&filter[meta_key]=signature_now_product&filter[meta_value]=1&filter[orderby]=title&order=asc');
    const nowProductsResponse = await nowProductsApiCall.json();
    await setNowBroadloomProducts(nowProductsResponse.filter(item => item.acf.product_category === 'Broadloom'));
    await setNowHardSurfaceProducts(nowProductsResponse.filter(item => item.acf.product_category === 'LVT'));
  };

  

  useEffect(() => {
    broadloomProducts();
  }, []);

  return (
    <div>
      <Helmet>
      <title>Now broadloom/rug/flooring program &#x2d; Signature Flooring</title>
      <meta name="description" content="Now, by Signature Flooring, is a broadloom carpet, rug, cove, & LVT flooring program solving your carpet needs with incredible style, service and value."  />
    </Helmet>
  	  <div className="signaturenow-wrapper-desktop">
    	  <div className="signaturenow-header" style={headerStyle}>
      	  <div className="signaturenow-header-content"><h2></h2></div>
        </div>
				<div className="signaturenow-above-fold">
          <p>Featuring a broadloom carpet program along with LVT flooring delivering in four weeks or less, solving your carpet needs with incredible style, service and value. SignatureNow by Signature Flooring. </p>
        </div>
				<div className="signaturenow-link-types">
            <div className="link-rows">
              <div className="link-row">
                <div className="link-header">Products</div>
                <div className="signaturenow-hr"><hr style={{borderTop: '0'}} /></div>
                <div className="link-button">
                  <a href="/carpet/broadloom/?signaturenow=true">
                    <button className="link-row-button">
                      Explore Carpet
                    </button>
                  </a>
                </div>
								<div className="link-button">
                  <a href="/hardsurface/LVT">
                    <button className="link-row-button">
                      Explore LVT
                    </button>
                  </a>
                </div>
								<div className="link-button">
                  <a href="/productinfo/cove-base">
                    <button className="link-row-button">
                      Explore Cove Base
                    </button>
                  </a>
                </div>
              </div>

              <div className="link-row">
                <div className="link-header">Brochures</div>
                <div className="signaturenow-hr"><hr style={{borderTop: '0'}}/></div>
                <div className="link-button">
                  <a href="/wp-content/uploads/2020/07/LocalStyle.pdf">
                    <button className="link-row-button">Download Local Style</button>
                  </a>
                </div>
                <div className="link-button">
                  <a href="/wp-content/uploads/2023/02/2206026_SignatureFlooring_Modern_Style_Product_Brochure.pdf">
                    <button className="link-row-button">Download Modern Style</button>
                  </a>
                </div>
              </div>

              <div className="link-row">
                <div className="link-header">Resources</div>
                <div className="signaturenow-hr"><hr style={{borderTop: '0'}} /></div>
                <div className="link-button">
                  <a href="/r/resources/cads">
                    <button className="link-row-button-large">
                      View Resources
                    </button>
                  </a>
                </div>
                <div className="link-button">
                  <a href="https://www.youtube.com/watch?v=7j9PHeyEPac" target="_blank">
                    <button className="link-row-button-large">
                      Modern Style Video
                    </button>
                  </a>
                </div>
              </div>
						
            </div>
          </div>
          {/** Featured Styles */}
          {/* End design rows */}
          <div className="signaturenow-featured-styles-wrapper">
              <div className="signaturenow-featured-styles-header">Featured Styles</div>
              <div className="signaturenow-featured-styles">
                <div className="signaturenow-featured-style">
                  <img src="/wp-content/uploads/2020/07/nature-craft-signaturenow-featured.jpg" alt="Nature Craft" /><br/>
                  <p>Nature Craft<br/><span>Available in 7" x 48" plank size, 12mil and 20mil construction</span></p>
                </div>
                <div className="signaturenow-featured-style">
                  <img src="/wp-content/uploads/2023/02/B7037-Falco_8305-Asher_RoomScene_500x500.jpg" alt="Falco" /><br/>
                  <p>Falco<br/>12' Broadloom</p>
                </div>  
              </div>
              <div className="signaturenow-featured-styles">
                <div className="signaturenow-featured-style">
                  <img src="/wp-content/uploads/2023/02/B7041-Neo_4501-Hudson_RoomScene_500x500.jpg" alt="Neo" /><br/>
                  <p>Neo<br/>15' Broadloom</p>
                </div>
                <div className="signaturenow-featured-style">
                  <img src="/wp-content/uploads/2023/02/B7035-Wick_8305-Asher_RoomScene_500x500.jpg" alt="Wick" /><br/>
                  <p>Wick<br/>12' Broadloom</p>
                </div>  
              </div>
            </div>
						{/** End Featured Styles */}

            {/** Broadloom Signature Now Products */}
            <div className="signaturenow-products-wrapper">
              <div className="signaturenow-products-inner">
              <a href="/carpet/broadloom/?signaturenow=true">
              <div className="signaturenow-products-header">
                Broadloom
              </div>
              </a>
              <div className="signaturenow-products-content">
                <p>Local Style and Modern Style make it simple to give any space a unique sense of character. Easy to use patterns, scales, colors and textures, working together or independently.  Offering 14 patterns and 14 colorways for public spaces, corridors, guest rooms, rugs and cove base.</p>
              </div>
              {/** Guest Room */}
              <div className="signaturenow-products-category">
                32 oz.
              </div>
              <div className="signaturenow-products">
                { nowBroadloomProducts.length > 0 && nowBroadloomProducts.map( product => (
                  <>
                    { product.acf.application.includes("Room") &&
                    <a href={SignatureFlooringSettings.path + "productinfo/"+product.slug}>
                      <div className="signaturenow-product-block">
                        <div className="signaturenow-notify-badge">
                        <div style={{height: '40px'}}></div>
                        <span>NOW</span>
                      </div>
                        <img src={product.acf.product_details_main_image.url} alt={product.title.rendered} width={200} height={200} /><br/>
                        {product.title.rendered} <span className="">{product.acf.style_number}</span><br/>
                      </div>
                      </a>
                    }
                  </>
                ))}
              </div>
              {/** Public Spaces */}
              <div className="signaturenow-products-category">
                36 oz.
              </div>
              <div className="signaturenow-products">
                { nowBroadloomProducts.length > 0 && nowBroadloomProducts.map( product => (
                  <>
                    { product.acf.application.includes("Public Space") &&
                      <a href={SignatureFlooringSettings.path + "productinfo/"+product.slug}>
                      <div className="signaturenow-product-block">
                        <div className="signaturenow-notify-badge">
                        <div style={{height: '40px'}}></div>
                        <span>NOW</span>
                      </div>
                        <img src={product.acf.product_details_main_image.url} alt={product.title.rendered} width={200} height={200} /><br/>
                        {product.title.rendered} <span className="">{product.acf.style_number}</span><br/>
                      </div>
                      </a>
                    }
                  </>
                ))}
              </div>
             </div>         
            </div>
            {/** End Broadloom Signature Now Products */}


            {/** Hard Surface Signature Now Products */}
            <div className="signaturenow-products-wrapper">
              <div className="signaturenow-products-inner">
              <a href="/hardsurface/LVT">
                <div className="signaturenow-products-header">
                  Hard Surface
                </div>
                </a>
                <div className="signaturenow-products-content">
                  <p>The beauty of natural wood with a range of visuals including oak, maple, and elm wood grains, Nature Craft has rich colorations ranging from neutral beige to tavern brown and useable greys from weather wash to smokey timber.  Available in 7” x 48” plank size, 12MIL and 20MIL constructions.</p>
                </div>
                {/** LVT */}
                <div className="signaturenow-products-category">
                  LVT
                </div>
                <div className="signaturenow-products">
                  { nowHardSurfaceProducts.length > 0 && nowHardSurfaceProducts.map( product => (
                    <>
                      { product.acf.application.includes("Room") &&
                        <a href={SignatureFlooringSettings.path + "productinfo/"+product.slug}>
                        <div className="signaturenow-product-block">
                          <div className="signaturenow-notify-badge">
                          <div style={{height: '40px'}}></div>
                          <span>NOW</span>
                        </div>
                          <img src={product.acf.product_details_main_image.url} alt={product.title.rendered} width={200} height={200} /><br/>
                          {product.title.rendered} <span className="">{product.acf.style_number}</span><br/>
                        </div>
                      </a>
                      }
                    </>
                  ))}
                </div>
              </div>         
            </div>
            {/** End Broadloom Signature Now Products */}

            {/** Rugs Section */}
            <div className="signaturenow-rug-wrapper">
              <div className="signaturenow-rug-inner">
                <a href="/carpet/rugs">
                <div className="signaturenow-rug-header">
                  Rugs
                </div>
                </a>
                <div className="signaturenow-rug-images">
                  <div className="signaturenow-rug-images-col1">
                      <img src="/wp-content/uploads/2020/07/rug-binding.jpg" width={412} height={500} alt="Bound Rugs" /><p style={{paddingLeft: '10px'}}>Bound Rugs</p>
                  </div>
                  <div className="signaturenow-rug-images-col2">
                    <img src="/wp-content/uploads/2020/07/rug-serging.jpg" width={412} height={500} alt="Serged Rugs" /><p style={{paddingLeft: '10px'}}>Serged Rugs</p>
                  </div>
                  <div className="signaturenow-rug-images-col3">
                    <div className="signaturenow-rug-images-col3-row1">
                      <img src="/wp-content/uploads/2020/07/rug-binding-colors.jpg" width={442} height={235} alt="Color Binding Options" /><p style={{paddingLeft: '10px'}}>Color Binding Options</p>
                    </div>
                    <div className="signaturenow-rug-images-col3-row2">
                      <img src="/wp-content/uploads/2020/07/rug-serge-colors.jpg" width={320} height={225} alt="Color Serging Options" /><p style={{paddingLeft: '10px'}}>Color Serging Options</p>
                    </div>
                  </div>
                </div>
                <div className="signaturenow-rug-content">
                  <h4>Features of Local Style and Modern Style Rugs</h4>
                  <ul>
                    <li>All patterns and colorways in the Local Style and Modern Style Collections are available as rugs</li>
                    <li>Standard 12′ sizes include: 2’x’3′, 3’x5′, 5’x7′, 6’x9′ and 9’x12′</li>
                    <li>Edging options include coordinated serging or standard binding</li>
                    <li>Non-skid padding available</li>
                    <li>Standard lead time is 3 – 4 weeks. Custom Lead time is 4 – 6 weeks</li>
                    <li>Contact your Signature Representative for pricing and detailed product information</li>
                  </ul>
                </div>
              </div>
            </div>
            {/** End Rugs Section */}

            {/** Cove Base Section */}
            <div className="signaturenow-rug-wrapper">
              <div className="signaturenow-rug-inner">
                <a href="/productinfo/cove-base">
                <div className="signaturenow-rug-header">
                  Cove Base
                </div>
                </a>
                <div className="signaturenow-rug-images">
                  <div className="signaturenow-rug-images-col1">
                      <img src="/wp-content/uploads/2023/02/B7038-Lomax_8305-ASHER_WallBase_545x500.jpg" width={412} height={500} alt="Bound Rugs" /><p style={{paddingLeft: '10px'}}>Displayed with solid Cove Base</p>
                  </div>
                  <div className="signaturenow-rug-images-col2">
                    <img src="/wp-content/uploads/2020/07/ColorSwatches.jpg" width={412} height={500} alt="Serged Rugs" /><p style={{paddingLeft: '10px'}}>Cove Base Color Options</p>
                  </div>
                  <div className="signaturenow-rug-images-col3">
                    <div className="signaturenow-rug-images-col3-row1">
                      <img src="/wp-content/uploads/2020/07/rug-binding-colors.jpg" width={442} height={235} alt="Color Binding Options" /><p style={{paddingLeft: '10px'}}>Color Binding Options</p>
                    </div>
                    <div className="signaturenow-rug-images-col3-row2">
                      <img src="/wp-content/uploads/2020/07/rug-serge-colors.jpg" width={320} height={225} alt="Color Serging Options" /><p style={{paddingLeft: '10px'}}>Color Serging Options</p>
                    </div>
                  </div>
                </div>
                <div className="signaturenow-rug-content">
                  <h4>Features of Local Style and Modern Style Cove Base</h4>
                  <a href="/productinfo/cove-base">
                    <button className="signaturenow-button-large">View Cove Base Product</button>
                  </a>
                  <ul>
                    <li>Cove Base is available in coordinating solid colorways or use existing pattern up the wall</li>
                    <li>100% Solution Dyed Nylon</li>
                    <li>4″ height standard</li>
                    <li>Edging options include binding standard or coordinating serging available</li>
                    <li>Contact your Signature Representative for pricing and detailed product information</li>
                  </ul>
                </div>
              </div>
            </div>
            {/** End Cove Base Section */}

        </div>
    </div>
  )
}

export default SignatureNow
