import React, { Component } from 'react'
import axios from 'axios'
import './styles/searchpage.css'

class Search extends Component {

  constructor(props) {
    super(props)

    this.state = {
      fields: {
        frmSearchInput: this.props.match.params.searchcriteria
      },
      searchNavLinkClass: ['active-link','',''],
      products: [],
      pages: [],
      downloads: [],
      resultsType: 'products',
      SignatureFlooringSettings: window.SignatureFlooringSettings
    }

    this.handleChange = this.handleChange.bind(this)
    this.submitSearch = this.submitSearch.bind(this)
    this.showHide = this.showHide.bind(this)
  }

  componentWillMount() {
    axios.get('/wp-json/wp/v2/productinfo/?per_page=100&search='+this.state.fields.frmSearchInput+'&filter[orderby]=title&order=asc')
      .then(response => {
        this.setState({
          products: response.data
        }, () => console.log(this.state.products))
      }).then(() => {
        axios.get('/wp-json/wp/v2/pages/?per_page=100&search='+this.state.fields.frmSearchInput+'&filter[orderby]=title&order=asc')
      .then(response => {
        this.setState({
          pages: response.data
        }, () => console.log(this.state.pages))
      }).catch(errors => console.log(errors))
      }).then(() => {
        axios.get('/wp-json/wp/v2/resource-docs/?per_page=100&search='+this.state.fields.frmSearchInput+'&filter[orderby]=title&order=asc')
      .then(response => {
        this.setState({
          downloads: response.data
        }, () => console.log('downloads: ', this.state.downloads))
      }).catch(errors => console.log(errors))
    })
      .catch(errors => console.log(errors))
  }

  handleChange(e) {
    let fields = this.state.fields
    fields[e.target.name] = e.target.value
    this.setState({fields})
  }
  submitSearch(e) {
    e.preventDefault()
    console.log('search', this.state.fields.frmSearchInput)
    console.log('loc: ', window.location.assign(this.state.SignatureFlooringSettings + '/s/search/'+this.state.fields.frmSearchInput))
    window.location.assign('/s/search/'+this.state.fields.frmSearchInput+'/sss')
  }

  showHide(num) {
    if (num === 0) {
      this.setState({
        searchNavLinkClass: ['active-link','',''],
        resultsType: 'products'
      })
    }
    if (num === 1) {
      this.setState({
        searchNavLinkClass: ['','active-link',''],
        resultsType: 'pages'
      })
    }
    if (num === 2) {
      this.setState({
        searchNavLinkClass: ['','','active-link'],
        resultsType: 'downloads'
      })
    }
  }
  
  render() {
    const { SignatureFlooringSettings, resultsType, products, pages, downloads } = this.state
    console.log('search', this.state.fields.frmSearchInput)
    return (
      <div className="search-page-wrapper">
        <div className="search-page-header">
          <div className="search-page-header-form-wrapper">
            <form className="search-page-header-form" onSubmit={this.submitSearch}>
              <input 
                ref="frmSearchInput"
                type="text" 
                name="frmSearchInput" 
                value={this.state.fields.frmSearchInput || '' } 
                onChange={this.handleChange} 
            />
              <img src="/wp-content/uploads/2019/03/magnify.png" alt="search" />
            </form>
          </div>
          <nav className="search-nav-links">
            <ul>
              <li><a href="##" onClick={() => this.showHide(0)} className={this.state.searchNavLinkClass[0]}>Products({products.length})</a></li>
              <li><a href="##" onClick={() => this.showHide(1)} className={this.state.searchNavLinkClass[1]}>Pages({pages.length})</a></li>
              <li><a href="##" onClick={() => this.showHide(2)} className={this.state.searchNavLinkClass[2]}>Downloads({downloads.length})</a></li>
            </ul>
          </nav>
        </div>
        <div className="search-page-results">
        {/** Products Found */}
          { resultsType === 'products' && 
            <div>
              <div className="search-page-results-header">
                <h2>{products.length} product results returned</h2>
              </div>
              <div className="search-page-products-results-grid">
                { products.map((item) => (
                  <div className="sp-grid-col" key={item.id}>
                  <div className="product-wrapper">
                    <a href={SignatureFlooringSettings.path + "productinfo/"+item.slug}>
                      <img src={item.acf.product_details_main_image.url} alt={item.title.rendered} width={239} height={239} />
                      <p>{item.title.rendered} <span className="">{item.acf.style_number}</span></p>
                    </a>
                  </div>
                </div>
                ))}
              </div>
            </div>
          }
          {/** Pages Found */}
          { resultsType === 'pages' &&
            <div>
              <div className="search-page-results-header">
                <h2>{pages.length} page results returned</h2>
              </div>
              <div className="search-page-pages-results" style={{paddingTop: '25px'}}>
                { pages.map((item) => (
                  <div key={item.id} className="search-page-pages-item" style={{ textAlign: 'left'}}>
                  <div className="search-page-pages-item-title">
                    <a href={item.link} style={{ color: '#A28D5B', fontFamily: 'Adelle Sans', fontSize: '14px', textDecoration: 'none'}}>{ item.title.rendered}</a>
                  </div>
                  <div className="search-page-pages-item-content" style={{ fontSize: '12px'}} dangerouslySetInnerHTML={{__html: item.excerpt.rendered}} />
                  </div>
                ))}
              </div>
            </div>
          }
          {/** Downloads Found */}
          { resultsType === 'downloads' &&
            <div>
              <div className="search-page-results-header">
                <h2>{downloads.length} downloadable results returned</h2>
              </div>
              <div className="search-page-pages-results" style={{paddingTop: '25px'}}>
                { downloads.map((item) => (
                  <div key={item.id} className="search-page-pages-item" style={{ textAlign: 'left'}}>
                  <div className="search-page-pages-item-title">
                    <a href={item.acf.resource_file.url} style={{ color: '#A28D5B', fontFamily: 'Adelle Sans', fontSize: '14px', textDecoration: 'none'}}>{ item.title.rendered}</a>
                  </div>
                  </div>
                ))}
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

export default Search
