import React, { Component } from 'react'
import axios from 'axios'
import Slider from 'react-slick'
import './styles/homeblogsmobile.css'

export default class HomeBlogsMobile extends Component {

  constructor(props) {
    super(props)

    this.state = {
      whatsnewitems: []
    }
  }

  componentWillMount() {
    axios.get('/wp-json/wp/v2/whats-new?filter[orderby]=display_order&order=asc')
    .then(response => {
      this.setState({
        whatsnewitems: response.data
      })
      
    })
    .catch(errors => console.log(errors))
  }

  render() {
    const { whatsnewitems } = this.state
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false
    };
    return (
      <div className="home-blog-wrapper">
        <div className="home-blog-wrapper home-blog-header">
          <p className="home-blog-header-text">What's New</p>
        </div>
        <div className="home-blog-wrapper home-blog-entries">
          <div className="container">
          {/*<div className="card-group card-group-scroll">*/}
          <div>
            <Slider {...settings}>
            { whatsnewitems.map((item) => (
              <div className="cardPadding" key={item.id}>
              <div className="home-slider-card">
              <a href={item.acf.link} target="_new"><img className="card-img-top" height={'380px'} src={item.acf.image.url} alt={item.title.rendered} /></a>
              <div className="home-slider-card-body">
              <a href={item.acf.link} style={{textDecoration: 'none'}} target="_new"><h5 className="card-title" style={{paddingTop: '5px'}} dangerouslySetInnerHTML={{__html: item.title.rendered}} /></a>
              </div>
              </div>
            </div>
            ))}
            </Slider>
          </div>
        </div>
        </div>
      </div>
    )
  }
}
