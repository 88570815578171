import React, {useState, useEffect } from 'react';
import ProgramDetails from '../Components/ProgramComponents/ProgramDetails';
import ProgramDetailsMobile from '../Components/ProgramComponents/ProgramDetailsMobile';
import './styles/brandedprogram.css';
import './styles/w3.css';

const BrandedHotelPrograms = () => {
  const [programs, setPrograms] = useState([]);
  const fetchPrograms = async () => {
    const programsApiCall = await fetch(`/wp-json/wp/v2/programs?per_page=100&filter[orderby]=id&filter[order]=asc`);
    const programsResponse = await programsApiCall.json();
    await setPrograms(programsResponse);
  }

  useEffect( () => {
    fetchPrograms();
  }, []);

  return (
    <>
      <div className="custom-collections-wrapper-desktop" style={{ background: 'white'}}>
          <div className="custom-collections-container">
            <div className="custom-collections-header">
              <h1>Branded Hotel Programs</h1>
            </div>
            
              <div className="branded-program-body" style={{background: 'white', minHeight: '800px'}}>
              <p style={{textAlign: 'center', fontSize: '16px', lineHeight: '2em', paddingTop: '50px', paddingBottom: '25px'}}>
              Signature is a flooring industry leader focused solely on helping our clients create extraordinary spaces. Fueled by a passion for our craft and a complete dedication to it’s clients, Signature is driven to achieve unique flooring solutions that expand each client’s vision. View approved prototypes for the following hotel brands.
              </p>
              { programs.map( (program, index) => (
                <div key={program.id} style={{paddingTop: '25px', paddingBottom: '25px'}}>
                  <ProgramDetails program={program} index={(index %2 == 0 ? 'even' : 'odd')} />
                </div>
                
                ))}
              </div>
            
            
            
          </div>
        </div>
        
        <div className="custom-collections-wrapper-mobile" style={{ background: 'white'}}>
          <div className="custom-collections-container">
            <div className="custom-collections-header">
              <h1>Branded Programs</h1>
            </div>
            <div className="branded-program-body" style={{background: 'white', minHeight: '600px', paddingBottom: '25px'}}>
              <p style={{textAlign: 'center', fontSize: '16px', lineHeight: '2em', paddingTop: '50px', paddingBottom: '50px'}}>
                Signature is a flooring industry leader focused solely on helping our clients create extraordinary spaces. Fueled by a passion for our craft and a complete dedication to it’s clients, Signature is driven to achieve unique flooring solutions that expand each client’s vision. View approved prototypes for the following hotel brands.
              </p>
              { programs.map( (program, index) => (
                <div key={program.id} style={{paddingTop: '25px', paddingBottom: '25px'}}>
                  <ProgramDetailsMobile program={program} />
                </div>
              ))}
            </div>
          </div>
        </div>
        
    </>
  )
}

export default BrandedHotelPrograms;
