import React, { Component } from 'react'
import axios from 'axios'
import './styles/carousel.css';
import {
  Carousel,
  CarouselItem,
  CarouselIndicators,
  Card, CardBody, CardText, CardTitle
} from 'reactstrap';

const items = [
  { id: 0 },
  { id: 1 },
  { id: 2 },
  { id: 3 },
  { id: 4 },
  { id: 5 }
]

class HomeCarousel extends Component {
  state = {
    activeIndex: 0,
    sliders: [],
    cleanSliders: []
  }

  componentWillMount() {
    axios.get(`/wp-json/wp/v2/carousels?timestamp=${new Date().getTime()}`)
      .then(response => {
        this.setState({
          sliders: response.data
        })
        
      })
      .catch(errors => console.log(errors))
  }

  onExiting = () => {
    this.animating = true;
  }

  onExited = () => {
    this.animating = false;
  }

  next = () => {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous = () => {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex = (newIndex) => {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    
    const { activeIndex } = this.state
    const slides = this.state.sliders.map((slide) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={slide.id}
        >
          <img src={slide.acf.image.url} alt={slide.title.rendered} />
          <div className="carousel-caption-background">
            <div className="carousel-caption d-none d-md-block">
              <Card>
                <CardBody>
                  <CardTitle dangerouslySetInnerHTML={{__html: slide.title.rendered}} />
                  <CardText>
                    {slide.acf.caption}<br/>
                    <a href={slide.acf.button_link}><button className="carousel-button">{slide.acf.button_text ? slide.acf.button_text : 'View Style'}</button></a>
                  </CardText>
                </CardBody>
              </Card>
            </div>
          </div>
        </CarouselItem>
      );
    });

    return (
      <div>
        <Carousel
        interval={3000}
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}
      >
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
        {slides}
      </Carousel>
      </div>
    )
  }
}

export default HomeCarousel
