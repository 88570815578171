import React, { Component } from 'react'
import './styles/segmentblocks.css'

class SegmentBlocks extends Component {
  render() {
    return (
      <div className="home-property-section">
        <div className="home-property-wrapper">
          <a href="/segments/hospitality">
            <div className="home-property-wrapper home-property-left">
              <div className="home-property-text">
                <p className="home-property-header">Signature Hospitality</p>
                <p className="home-property-tag">Give guests an experience to remember with stunning flooring solutions for hospitality spaces.</p>
              </div>
            </div>
          </a>
          <a href="/segments/seniorliving">
            <div className="home-property-wrapper home-property-right">
              <div className="home-property-text">
                <p className="home-property-header">Signature Living</p>
                <p className="home-property-tag">Achieve beautifully designed senior living spaces with flooring tailored to meet senior living needs.</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    )
  }
}

export default SegmentBlocks
