import React, { Component } from 'react'

import './styles/customeasy.css'

class CustomEasy extends Component {
  render() {
    return (
      <div>
        <section className="cd-section">
          <div className="cd-wrapper">
            <div className="cd-text">
              <h2>We Make Custom Easy</h2>
              <p style={{textAlign: 'center'}}>We understand that designers are incredibly busy and have tight project timelines. Our custom carpet design process is fast, easy and doesn’t require you to compromise your design vision to meet your deadlines.</p>
              <a href="/customdesign" style={{ border: '0'}}><button className="cd-learn-more-button">Learn More</button></a>&nbsp;&nbsp;
              <a href="/salesreps" style={{ border: '0'}}><button className="cd-find-rep-button">Find a Rep</button></a>
            </div>
          </div>
        </section>
      </div>
    )
  }
}


export default CustomEasy
