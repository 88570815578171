import React, { Component } from 'react'
import axios from 'axios'
import ReactPlayer from 'react-player'
import './styles/mediapage.css'

class Media extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mediaNavLinkClass: ['active-link','',''],
      pageTitle: 'Press Releases',
      contentType: 'pressReleases',
      pressReleaseFiles: [],
      vidoeFiles: [],
      adFiles: [],
      pressReleases2018:[],
      pressReleases2019:[],
      pressReleases2021:[],
      pressReleases2022:[],
	  pressReleases2023:[],
	  pressReleases2024:[],
      prLinkClass: ['active-year',''],
      prYear: '2024',
      mediaType: this.props.match.params.mediatype
    }
  }

  componentWillMount() {
    axios.get('/wp-json/wp/v2/media-files/?per_page=100')
      .then(res => {
        this.setState({
          pressReleaseFiles: res.data.filter(item => item.acf.media_type === 'Press Release'),
          videoFiles: res.data.filter(item => item.acf.media_type === 'Video'),
          adFiles: res.data.filter(item => item.acf.media_type === 'Ad')
        }, () => {
          this.setState({
			pressReleases2024: this.state.pressReleaseFiles.filter(item => item.acf.year === '2024'),
			pressReleases2023: this.state.pressReleaseFiles.filter(item => item.acf.year === '2023'),
            pressReleases2022: this.state.pressReleaseFiles.filter(item => item.acf.year === '2022'),
            pressReleases2021: this.state.pressReleaseFiles.filter(item => item.acf.year === '2021'),
          }, () => {
            switch(this.state.mediaType) {
              case 'pressreleases':
                this.showHide(0)
                break
              case 'videos':
                this.showHide(1)
                break
              case 'ads':
                this.showHide(2)
                break
              default:
                this.showHide(0)
            }
          })
        })
      })
    .catch(errors => console.log(errors))
  }

  showHide(num) {
    if (num === 0) {
      this.setState({
        pageTitle: 'Press Releases',
        contentType: 'pressReleases',
        mediaNavLinkClass: ['active-link','','']
      })
    }

    if (num === 1) {
      this.setState({
        pageTitle: 'Videos',
        contentType: 'videos',
        mediaNavLinkClass: ['','active-link','']
      })
    }

    if (num === 2) {
      this.setState({
        pageTitle: 'Ads',
        contentType: 'ads',
        mediaNavLinkClass: ['','','active-link']
      })
    }
  }

  showHidePR(num) {
    if (num === 0) {
      this.setState({
        prLinkClass: ['active-year',''],
        prYear: '2024'
      })
    }
    if (num === 1) {
      this.setState({
        prLinkClass: ['','active-year'],
        prYear: '2023'
      })
    }
	if (num === 2) {
      this.setState({
        prLinkClass: ['','active-year'],
        prYear: '2022'
      })
    }
	if (num === 3) {
      this.setState({
        prLinkClass: ['','active-year'],
        prYear: '2021'
      })
    }
  }

  render() {
    const { pageTitle, contentType, mediaNavLinkClass, pressReleases2024, pressReleases2023, pressReleases2022, pressReleases2021, videoFiles, adFiles } = this.state
    return (
      <div className="media-page-wrapper">
        <div className="media-page-header">
          <div className="media-page-header-content">
              <h2>Media</h2> 
              <nav className="media-desktop-nav">
                <ul>
                  <li><a href="##" onClick={() => this.showHide(0)} className={mediaNavLinkClass[0]}>Press Releases</a></li>
                  <li><a href="##" onClick={() => this.showHide(1)} className={mediaNavLinkClass[1]}>Videos</a></li>
                  <li><a href="##" onClick={() => this.showHide(2)} className={mediaNavLinkClass[2]}>Ads</a></li>
                </ul>
              </nav>
              <nav className="media-mobile-nav" style={{ marginLeft: '-47px', paddingTop: '25px', bottom: '0'}}>
                <ul>
                  <li><a href="##" onClick={() => this.showHide(0)} className={mediaNavLinkClass[0]}>Press Releases</a></li>
                  <li><a href="##" onClick={() => this.showHide(1)} className={mediaNavLinkClass[1]}>Videos</a></li>
                  <li><a href="##" onClick={() => this.showHide(2)} className={mediaNavLinkClass[2]}>Ads</a></li>
                </ul>
              </nav>
          </div>
        </div>
        <div className="media-page-above-fold">
          <h3>{ pageTitle }</h3>
        </div>  

        <section className="media-page-main-content">
        { contentType === 'pressReleases' &&
          <div>
          <div className="media-page-pr-year-links">
            <a href="##" onClick={() => this.showHidePR(0)} className={this.state.prLinkClass[0]}>2024</a>&nbsp;&nbsp;&nbsp;&nbsp;<a href="##" onClick={() => this.showHidePR(1)} className={this.state.prLinkClass[0]}>2023</a>&nbsp;&nbsp;&nbsp;&nbsp;<a href="##" onClick={() => this.showHidePR(2)} className={this.state.prLinkClass[0]}>2022</a>&nbsp;&nbsp;&nbsp;&nbsp;<a href="##" onClick={() => this.showHidePR(3)} className={this.state.prLinkClass[0]}>2021</a>
          </div>
          <div className="media-page-pr">
            { this.state.prYear === '2021' &&
            <div className="media-page-pr-2018">
                <div className="pr-grid">
                  {pressReleases2021.map((item) => (
                    <div className="pr-item" key={item.id}>
                    <span className="pr-date">{item.acf.date}</span><br/>
                      <a href={item.acf.download_file.url} target="_new"><span dangerouslySetInnerHTML={{__html: item.title.rendered}} /></a>
                    </div>
                  ))}
                </div>
              </div>
            }
            { this.state.prYear === '2022' &&
              <div className="media-page-pr-2018">
                <div className="pr-grid">
                  {pressReleases2022.map((item) => (
                    <div className="pr-item" key={item.id}>
                    <span className="pr-date">{item.acf.date}</span><br/>
                      <a href={item.acf.download_file.url} target="_new"><span dangerouslySetInnerHTML={{__html: item.title.rendered}} /></a>
                    </div>
                  ))}
                </div>
              </div>
            }
			{ this.state.prYear === '2023' &&
              <div className="media-page-pr-2018">
                <div className="pr-grid">
                  {pressReleases2023.map((item) => (
                    <div className="pr-item" key={item.id}>
                    <span className="pr-date">{item.acf.date}</span><br/>
                      <a href={item.acf.download_file.url} target="_new"><span dangerouslySetInnerHTML={{__html: item.title.rendered}} /></a>
                    </div>
                  ))}
                </div>
              </div>
            }
			{ this.state.prYear === '2024' &&
              <div className="media-page-pr-2018">
                <div className="pr-grid">
                  {pressReleases2024.map((item) => (
                    <div className="pr-item" key={item.id}>
                    <span className="pr-date">{item.acf.date}</span><br/>
                      <a href={item.acf.download_file.url} target="_new"><span dangerouslySetInnerHTML={{__html: item.title.rendered}} /></a>
                    </div>
                  ))}
                </div>
              </div>
            }
            
            
          </div>
          </div>
        }
        { contentType === 'videos' &&
        <div>
          <div className="media-page-desktop-videos">
            { videoFiles.map((item) => (
              <div id={item.id} style={{textAlign: 'left', paddingBottom: '50px'}}>
                <span dangerouslySetInnerHTML={{__html: item.title.rendered}}></span>
                <ReactPlayer height="469px" width="100%" controls url={item.acf.link} />
              </div>
            ))}
          </div>
          <div className="media-page-mobile-videos">
          { videoFiles.map((item) => (
            <div id={item.id} style={{textAlign: 'left', paddingBottom: '50px', paddingLeft: '10px', paddingRight: '10px'}}>
              <span dangerouslySetInnerHTML={{__html: item.title.rendered}}></span>
              <ReactPlayer height="250px" width="100%" controls url={item.acf.link} />
            </div>
          ))}
        </div>
        </div>
        }
        { contentType === 'ads' &&
          <div className="media-page-ads">
            <div className="ad-grid">
              { adFiles.map((item) => (
                <div className="media-page-ad-content" key={item.id}>
                  <a href={item.acf.download_file.url} target="_new"><img src={item.acf.featured_image.url} height={'320px'} width={'240px'} alt={item.title.rendered} /></a><br/>
                  <span dangerouslySetInnerHTML={{__html: item.title.rendered}} className="ad-title"></span><br/>
                  <span className="ad-download-link"><a href={item.acf.download_file.url} target="_new">Download PDF</a></span>
                </div>    
              ))}
            </div>
          </div>
        }
        </section>
        

        <div className="media-page-need-help">
          <h2>We would love to hear from you.</h2>
          <div className="media-page-need-help-wrapper">
              <div className="contact-ways1">
                <span className="contact-ways-header"><p>Phone & Email</p></span>
                <span className="contact-ways">
                  <p>(800) 809-7086</p>
                  <p>(706) 270-5799</p>
                  <p>(706) 270-8779 fax</p>
                </span>
                <span className="contact-email">info@signatureflooring.com</span>
              </div>

              <div className="contact-ways2">
                <span className="contact-ways-header"><p>Office</p></span>
                <span className="contact-ways">
                  <p>2222 South Hamilton Street</p>
                  <p>Dalton, Georgia 30721</p>
                </span>
              </div>

              <div className="contact-ways3">
                <span className="contact-ways-header"><p>Media Contact</p></span>
                <span className="contact-ways">
                  <p>Angie Law</p>
                  <p>(706) 270-5799</p>
                  <p className="contact-email">alaw@signatureflooring.com</p>
                </span>
              </div>
          </div>
        </div>

      </div>
    )
  }
}

export default Media
