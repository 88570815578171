import React, { Component } from 'react'
import axios from 'axios'

import './styles/customcollections.css'

class CustomCollections extends Component {

  constructor(props) {
    super(props)

    this.state = {
      SignatureFlooringSettings: window.SignatureFlooringSettings,
      customCollections: []
    }
  }

  componentWillMount() {
    axios.get('/wp-json/wp/v2/custom-collections/?per_page=100&filter[orderby]=title&order=asc')
      .then(response => {
        this.setState({
          customCollections: response.data
        }, () => console.log(this.state.customCollections))
      })
      .catch(errors => console.log(errors))
  }

  render() {
    const { customCollections, SignatureFlooringSettings } = this.state
    return (
      <div>
        <div className="custom-collections-wrapper-desktop">
          <div className="custom-collections-container">
            <div className="custom-collections-header">
              <h1>Custom Collections</h1>
            </div>
            <div className="custom-collections-body">
              { customCollections.map((item) => (
                <div className="collection" key={item.id}>
                  <div className="collection-wrapper">
                    <a href={SignatureFlooringSettings.path + "custom_collections/"+item.slug} style={{color: '#444', textDecoration: 'none'}}>
						 {item.acf.collection_is_new &&  
						  <div className="collections-notify-badge">
							<div style={{height: '40px'}}></div>
							<span>NEW</span>
						  </div>
						 } 
                      <img src={item.acf.collection_image.url} alt={item.title.rendered} width={200} height={239} />
                      <p dangerouslySetInnerHTML={{__html: item.title.rendered}} style={{paddingLeft: '10px'}}></p>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>


        <div className="custom-collections-wrapper-mobile">
          <div className="custom-collections-container">
            <div className="custom-collections-header-mobile">
              <h1>Custom Collections</h1>
            </div>
            <div className="custom-collections-body-mobile" style={{paddingTop: '20px'}}>
              { customCollections.map((item) => (
                <div className="collection" key={item.id}>
                  <div className="collection-wrapper" style={{textAlign: 'center'}}>
                    <a href={SignatureFlooringSettings.path + "customcollections/"+item.slug} style={{color: '#444', textAlign: 'none'}}>
						{item.acf.collection_is_new &&  
						  <div className="collections-notify-badge-mobile">
							<div style={{height: '40px'}}></div>
							<span>NEW</span>
						  </div>
						}
                      <img src={item.acf.collection_image.url} alt={item.title.rendered} width={250} height={289} />
                      <p dangerouslySetInnerHTML={{__html: item.title.rendered}} style={{paddingLeft: '35px'}}></p>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

      </div>
    )
  }
}


export default CustomCollections
