import React, { Component } from "react";
import "./styles/cartmobile.css";
import axios from "axios";

export default class CartMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shoppingcart: JSON.parse(localStorage.getItem("shoppingcart")),
      cartitemcount: JSON.parse(localStorage.getItem("cartitemcount")),
      showCart: [true, false, false, false],
      cartNavLinkClass: ["active-cart-nav", "", ""],
      fields: {
        frmFirstName: "",
        frmLastName: "",
        frmPhone: "",
        frmEmail: "",
        frmCompany: "",
        frmRole: "",
        frmProject: "",
        frmShippingAddress: "",
        frmShippingAddressLine2: "",
        frmCity: "",
        frmState: "",
        frmZip: "",
      },
      errors: {},
      errorMessage: "",
    };

    this.selectOnchange = this.selectOnchange.bind(this);
    this.removeOnchange = this.removeOnchange.bind(this);
    this.showHide = this.showHide.bind(this);
    this.submitSampleOrder = this.submitSampleOrder.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.OrderMobileSample = this.OrderMobileSample.bind(this);
  }

  OrderMobileSample() {
    var cartproduct = {};
    cartproduct.id = this.props.colorid;
    cartproduct.name = this.props.productName;
    cartproduct.color = this.props.selectedColor;
    cartproduct.imageURL = this.props.backgroundImage;
    cartproduct.quantity = 1;
    cartproduct.styleId = this.props.styleId;
    var shoppingcart = {};

    if (localStorage && localStorage.getItem("shoppingcart")) {
      shoppingcart = JSON.parse(localStorage.getItem("shoppingcart"));
      const doesExist = shoppingcart.find((o) => o.color === cartproduct.color);
      if (doesExist === undefined) {
        if (cartproduct.color) {
          shoppingcart.unshift(cartproduct);
          localStorage.setItem("shoppingcart", JSON.stringify(shoppingcart));
        }
      }
    } else {
      shoppingcart = [];
      if (cartproduct.color !== null) {
        shoppingcart.unshift(cartproduct);
        localStorage.setItem("shoppingcart", JSON.stringify(shoppingcart));
      }
    }
    localStorage.setItem("cartitemcount", shoppingcart.length);
    this.setState(
      {
        shoppingcart: JSON.parse(localStorage.getItem("shoppingcart")),
      },
      () => this.showHide(1)
    );
  }

  showHide(num) {
    if (num === 0) {
      this.setState({
        showCart: [true, false, false, false],
        cartNavLinkClass: ["active-cart-nav", "", "", ""],
      });
    }
    if (num === 1) {
      this.setState({
        showCart: [false, true, false, false],
        cartNavLinkClass: ["", "active-cart-nav", "", ""],
      });
    }
    if (num === 2) {
      this.setState({
        showCart: [false, false, true, false],
        cartNavLinkClass: ["", "", "active-cart-nav", ""],
      });
    }
    if (num === 3) {
      this.setState({
        showCart: [false, false, false, true],
        cartNavLinkClass: ["", "", "", "active-cart-nav"],
      });
    }
  }

  selectOnchange(id, e) {
    let contents = [];
    contents = this.state.shoppingcart;
    contents = contents.map((item) => {
      if (item.id === id) item.quantity = e.target.value;
      return item;
    });
    let _cart = JSON.stringify(contents);
    localStorage.setItem("shoppingcart", _cart);
    this.setState({
      shoppingcart: JSON.parse(localStorage.getItem("shoppingcart")),
    });
  }

  removeOnchange(id) {
    let contents = [];
    contents = this.state.shoppingcart;
    contents = contents.filter((item) => item.id !== id);
    let _cart = JSON.stringify(contents);
    if (_cart.length > 2) {
      localStorage.setItem("shoppingcart", _cart);
      this.setState(
        {
          shoppingcart: JSON.parse(localStorage.getItem("shoppingcart")),
        },
        () => {
          this.setState({ cartitemcount: this.state.shoppingcart.length });
        }
      );
    } else {
      localStorage.removeItem("shoppingcart");
      this.setState({
        shoppingcart: null,
        cartitemcount: 0,
      });
    }
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    const errorMessage = "(is required)";

    //First Name
    if (!fields["frmFirstName"]) {
      formIsValid = false;
      errors["frmFirstName"] = errorMessage;
    }
    //Last Name
    if (!fields["frmLastName"]) {
      formIsValid = false;
      errors["frmLastName"] = errorMessage;
    }
    //Phone Number
    if (!fields["frmPhone"]) {
      formIsValid = false;
      errors["frmPhone"] = errorMessage;
    }

    if (typeof fields["frmPhone"] !== "undefined") {
      if (!fields["frmPhone"].match(/^[0-9]{10}$/)) {
        formIsValid = false;
        errors["frmPhone"] = "(valid phone required)";
      }
    }
    //Email
    if (!fields["frmEmail"]) {
      formIsValid = false;
      errors["frmEmail"] = errorMessage;
    }

    if (typeof fields["frmEmail"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["frmEmail"])) {
        formIsValid = false;
        errors["frmEmail"] = "(valid email required)";
      }
    }
    //Company
    if (!fields["frmCompany"]) {
      formIsValid = false;
      errors["frmCompany"] = errorMessage;
    }
    //Role
    if (!fields["frmRole"]) {
      formIsValid = false;
      errors["frmRole"] = errorMessage;
    }
    //Project
    if (!fields["frmProject"]) {
      formIsValid = false;
      errors["frmProject"] = errorMessage;
    }
    //Shipping Address
    if (!fields["frmShippingAddress"]) {
      formIsValid = false;
      errors["frmShippingAddress"] = errorMessage;
    }
    //City
    if (!fields["frmCity"]) {
      formIsValid = false;
      errors["frmCity"] = errorMessage;
    }
    //Zip
    if (!fields["frmZip"]) {
      formIsValid = false;
      errors["frmZip"] = errorMessage;
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  }

  submitSampleOrder(e) {
    e.preventDefault();
    if (this.handleValidation()) {
      var samplesordered = "";
      //const cartcontents = this.state.shoppingcart.map((e,i) =>(<p key={i}>{e.name}</p>))
      var cartcontents = this.state.shoppingcart;
      for (var i = 0; i < cartcontents.length; i++) {
        samplesordered += "<tr>";
        samplesordered += "<td>";
        samplesordered +=
          "<img src='" +
          cartcontents[i].imageURL +
          "' alt='" +
          cartcontents[i].color +
          "' height='80' width='80'>";
        samplesordered += "</td>";
        samplesordered += "<td>";
        samplesordered += "<p>" + cartcontents[i].name + "</p>";
        samplesordered += "</td>";
        samplesordered += "<td>";
        samplesordered += "<p>" + cartcontents[i].color + "</p>";
        samplesordered += "</td>";
        samplesordered += "<td>";
        samplesordered += "<p>" + cartcontents[i].quantity + "</p>";
        samplesordered += "</td>";
        samplesordered += "</tr>";
      }
      let message = `
        <table>
          <tr><td><img src="http://sigdev.wpengine.com/wp-content/uploads/2019/02/signature-flooring-email-logo.png" alt="Signature Flooring" /></td></tr>
          <tr><td><h2>Thank you for your order!</h2></td></tr>
          <tr>
            <td>
              <p>Hi ${
                this.state.fields.frmFirstName
              }, in a world full of options, we at Signature would like to thank you for choosing us.  We take great pride in giving you an experience as inspiring and authentic as the flooring created for you.</p>
              <p>This notification is to confirm we have received your sample order and will be sending you confirmation of your shipment through our Fedx tracking system.  Note:  Sample orders ship the same day, unless placed after 3PM which will ship the following day. 
              </p>
            </td>
          </tr>
        </table>
        <hr/>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Product</th>
              <th>Style</th>
              <th>Quantity</th>
            </tr>
            <tbody>
              ${samplesordered}
            </tbody>
        <hr/>
        <table>
          <tr><td><h3>Customer Information</h3><td></tr>
          <tr><td>${this.state.fields.frmFirstName} ${
        this.state.fields.frmLastName
      }</td></tr>
          <tr><td>${this.state.fields.frmRole} ${
        this.state.fields.frmCompany
      }</td><tr>
          <tr><td>Phone: ${this.state.fields.frmPhone}</td><tr>
          <tr><td>Email: ${this.state.fields.frmEmail}</td><tr>
          <tr><td>Address</td></tr>
          <tr><td>${this.state.fields.frmShippingAddress} ${
        this.state.fields.frmShippingAddressLine2
      }</td></tr>
          <tr><td>${this.state.fields.frmCity}, ${this.state.fields.frmState} ${
        this.state.fields.frmZip
      }</td></tr>
        </table>
      `;

      //send email
      axios({
        url: "https://sfmdev.yourcustomerhelp.com/send",
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        data: {
          name:
            this.state.fields.frmFirstName +
            " " +
            this.state.fields.frmLastName,
          email: this.state.fields.frmEmail,
          subject: "Thank you for your sample order",
          message: message,
        },
      }).then((response) => {
        if (response.data.msg === "success") {
          let fields = {};
          fields.frmFirstName = "";
          fields.frmLastName = "";
          fields.frmPhone = "";
          fields.frmEmail = "";
          fields.frmCompany = "";
          fields.frmRole = "";
          fields.frmProject = "";
          fields.frmShippingAddress = "";
          fields.frmShippingAddressLine2 = "";
          fields.frmCity = "";
          fields.frmState = "AL";
          fields.frmZip = "";
          localStorage.removeItem("shoppingcart");
          localStorage.removeItem("cartitemcount");
          this.setState({
            fields: fields,
            errorMessage: "",
            shoppingcart: [],
            cartitemcount: 0,
          });
          this.showHide(3);

          //Display thank you page
        } else if (response.data.msg === "fail") {
        }
      });
    } else {
      this.setState({
        errorMessage:
          "Your order information is not complete. Please review your submission and correct any errors.",
      });
    }
  }

  render() {
    console.log("shoppingCart", this.state.shoppingcart);
    return (
      <div className="cart-wrapper-mobile">
        <div className="cart-header-mobile">
          <div className="cart-header-text-mobile">
            <a className="close" href="##" onClick={this.props.close}>
              X
            </a>
            {this.state.showCart[0] && (
              <div>
                <h1>{this.props.productName}</h1>
                <p>{this.props.selectedColor}</p>
              </div>
            )}
            {this.state.showCart[1] && (
              <div>
                <h1 style={{ paddingTop: "30px", textAlign: "center" }}>
                  Sample Cart
                </h1>
                {!this.state.showCart[4] && !this.state.showCart[0] && (
                  <p className="sample-count" style={{ marginTop: "-55px" }}>
                    <br />
                    <br />
                    {this.state.cartitemcount} Style Sample
                    {this.state.cartitemcount !== 1 ? "s" : ""}
                  </p>
                )}
              </div>
            )}
            {this.state.showCart[2] && (
              <h1 style={{ paddingTop: "30px", textAlign: "center" }}>
                Checkout
              </h1>
            )}
            {this.state.showCart[3] && (
              <h1 style={{ paddingTop: "30px", textAlign: "center" }}>
                Thank You
              </h1>
            )}

            {!this.state.showCart[3] && !this.state.showCart[0] && (
              <p className="cart-top-nav">
                <a
                  href="##"
                  onClick={() => this.showHide(1)}
                  className={this.state.cartNavLinkClass[1]}
                >
                  Sample Cart
                </a>
                &nbsp;&nbsp;
                <a
                  href="##"
                  onClick={() => this.showHide(2)}
                  className={this.state.cartNavLinkClass[2]}
                >
                  Checkout
                </a>
              </p>
            )}
          </div>
        </div>
        {this.state.showCart[0] && (
          <div
            className="cart-show-image-mobile"
            style={{ textAlign: "center" }}
          >
            <img
              src={this.props.backgroundImage}
              height={300}
              width={300}
              alt="foo"
            />
            <button
              style={{ marginTop: "50px" }}
              className="sample-button"
              id="orderSampleButton"
              data-id={this.props.colorid}
              onClick={this.OrderMobileSample}
            >
              Order Sample
            </button>
          </div>
        )}
        {this.state.showCart[1] && (
          <div className="cart-items">
            {this.state.shoppingcart ? (
              <table width={"100%"}>
                <tbody>
                  {this.state.shoppingcart.map((item) => (
                    <tr key={item.id} className="cart-item-row">
                      <td style={{ width: "90px" }}>
                        <img
                          src={item.imageURL}
                          height={80}
                          width={80}
                          alt={item.color}
                        />
                      </td>
                      <td className="item-name">
                        <p className="item-header">
                          {item.styleId} {item.name}
                          <br />
                          {item.colorid} {item.color}
                        </p>
                        <form>
                          <div>
                            <label
                              className="form-label"
                              style={{ float: "left" }}
                            >
                              Qty:&nbsp;&nbsp;
                            </label>
                            <div
                              className="qty-select"
                              style={{ float: "left" }}
                            >
                              <select
                                name={item.id + "-selQuantity"}
                                value={item.quantity}
                                onChange={(e) =>
                                  this.selectOnchange(item.id, e)
                                }
                              >
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                              </select>
                            </div>
                          </div>
                        </form>{" "}
                        <a
                          href="##"
                          onClick={() => this.removeOnchange(item.id)}
                        >
                          &nbsp; Remove
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>You sample cart is currently empty.</p>
            )}
          </div>
        )}
        {this.state.showCart[2] && (
          <form onSubmit={this.submitSampleOrder}>
            <div className="checkout-form-div">
              <div className="fullRow">
                <label>First Name</label>
                <span className="error">
                  {this.state.errors["frmFirstName"]}
                </span>
                <br />
                <input
                  ref="frmFirstName"
                  type="text"
                  name="frmFirstName"
                  value={this.state.fields.frmFirstName}
                  onChange={this.handleChange}
                />
              </div>
              <div className="fullRow">
                <label>Last Name</label>
                <span className="error">
                  {this.state.errors["frmFirstName"]}
                </span>
                <br />
                <input
                  ref="frmLastName"
                  type="text"
                  name="frmLastName"
                  value={this.state.fields.frmLastName}
                  onChange={this.handleChange}
                />
              </div>
              <div className="fullRow">
                <label>Phone</label>
                <span className="error">{this.state.errors["frmPhone"]}</span>
                <br />
                <input
                  ref="frmPhone"
                  type="text"
                  name="frmPhone"
                  value={this.state.fields.frmPhone}
                  onChange={this.handleChange}
                />
              </div>
              <div className="fullRow">
                <label>Email</label>
                <span className="error">{this.state.errors["frmEmail"]}</span>
                <br />
                <input
                  ref="frmEmail"
                  type="text"
                  name="frmEmail"
                  value={this.state.fields.frmEmail}
                  onChange={this.handleChange}
                />
              </div>
              <div className="fullRow">
                <label>Company</label>
                <span className="error">{this.state.errors["frmCompany"]}</span>
                <br />
                <input
                  ref="frmCompany"
                  type="text"
                  name="frmCompany"
                  value={this.state.fields.frmCompany}
                  onChange={this.handleChange}
                />
              </div>
              <div className="fullRow">
                <label>Role</label>
                <span className="error">{this.state.errors["frmRole"]}</span>
                <br />
                <input
                  ref="frmRole"
                  type="text"
                  name="frmRole"
                  value={this.state.fields.frmRole}
                  onChange={this.handleChange}
                />
              </div>
              <div className="fullRow">
                <label>Project</label>
                <span className="error">{this.state.errors["frmProject"]}</span>
                <br />
                <input
                  ref="frmProject"
                  type="text"
                  name="frmProject"
                  value={this.state.fields.frmProject}
                  onChange={this.handleChange}
                />
              </div>
              <div className="fullRow">
                <label>Shipping Address</label>
                <span className="error">
                  {this.state.errors["frmShippingAddress"]}
                </span>
                <br />
                <input
                  ref="frmShippingAddress"
                  type="text"
                  name="frmShippingAddress"
                  value={this.state.fields.frmShippingAddress}
                  onChange={this.handleChange}
                />
              </div>
              <div className="fullRow">
                <label>Shipping Address Line 2</label>
                <br />
                <input
                  ref="frmShippingAddressLine2"
                  type="text"
                  name="frmShippingAddressLine2"
                  value={this.state.fields.frmShippingAddressLine2}
                  onChange={this.handleChange}
                />
              </div>
              <div className="fullRow">
                <label>City</label>
                <span className="error">{this.state.errors["frmCity"]}</span>
                <br />
                <input
                  ref="frmCity"
                  type="text"
                  name="frmCity"
                  value={this.state.fields.frmCity}
                  onChange={this.handleChange}
                />
              </div>
              <div className="fullRow frmStateSelect">
                <label>State</label>
                <br />
                <select
                  ref="frmState"
                  name="frmState"
                  onChange={this.handleChange}
                >
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>
              </div>
              <div className="fullRow">
                <label>Zip</label>
                <span className="error">{this.state.errors["frmZip"]}</span>
                <br />
                <input
                  ref="frmZip"
                  type="text"
                  name="frmZip"
                  value={this.state.fields.frmZip}
                  onChange={this.handleChange}
                />
              </div>
              <p>All fields are required to place an order.</p>
            </div>
            {this.state.showCart[2] && (
              <div className="checkout-buttons" style={{ marginTop: "150px" }}>
                <br />
                <br />
                <br />
                {this.state.shoppingcart && (
                  <div>
                    <button
                      type="submit"
                      className="checkout-button-front"
                      style={{ width: "272px" }}
                    >
                      Submit Order
                    </button>
                    <br />
                    <br />
                  </div>
                )}
                <button
                  className="continue-shopping"
                  onClick={() => this.showHide(1)}
                  style={{ width: "272px" }}
                >
                  Back To Cart
                </button>
                <br />
                <span className="error">{this.state.errorMessage}</span>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            )}
          </form>
        )}
        {this.state.showCart[1] && (
          <div className="cart-buttons">
            {this.state.shoppingcart && (
              <div>
                <button
                  className="checkout-button-front"
                  onClick={() => this.showHide(2)}
                  style={{ width: "272px" }}
                >
                  Checkout
                </button>
                <br />
                <br />
              </div>
            )}
            <button
              className="continue-shopping"
              onClick={this.props.close}
              style={{ width: "272px" }}
            >
              Continue Product Search
            </button>
          </div>
        )}

        {this.state.showCart[3] && (
          <div className="thank-you">
            <p>
              In a world full of options, we at Signature would like to thank
              you for choosing us. We take great pride in giving you an
              experience as inspiring and authentic as the flooring created for
              you.
            </p>
            <div className="cart-buttons">
              <button
                className="continue-shopping"
                onClick={this.props.close}
                style={{ width: "272px" }}
              >
                Continue Product Search
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
