import React, { Component } from "react";
import { Table } from "reactstrap";
import "./styles/product.css";
import ProductLargeImage from "../Components/ProductComponents/ProductLargeImage";
import axios from "axios";
import Popup from "reactjs-popup";
import ReactGA from "react-ga";
import SampleCart from "../Components/CartComponents/Cart";
import SampleCartMobile from "../Components/CartComponents/CartMobile";

class Product extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productName: this.props.match.params.productname,
      products: [],
      showSwatch: [false, true, false],
      showSwatchMobile: [false, true, false],
      swatchChangerClass: ["nonactive-link", "active-link", "nonactive-link"],
      swatchChangerClassMobile: [
        "nonactive-link",
        "active-link",
        "nonactive-link",
      ],
      backgroundImage: "",
      selectedColor: null,
      colorid: null,
      open: false,
      styleId: null,
      warrantiesFound: [],
      installationsFound: [],
      cadsFound: [],
      testsFound: [],
      adhesivesFound: [],
      productDesignId: null,
      isSignatureNow: false,
      sparkID: '',
      SignatureFlooringSettings: window.SignatureFlooringSettings,
      imageType: "cw",
      show: null,
      coordinates_customize_id: 0,
      coordinates_customize_sim_id: 0    
    };

    this.changeLargeImage = this.changeLargeImage.bind(this);
    this.changeLargeCoordImage = this.changeLargeCoordImage.bind(this);
    this.OrderSample = this.OrderSample.bind(this);
    this.buttonWidth = this.buttonWidth.bind(this);
    this.closemodal = this.closemodal.bind(this);
    this.viewLargeImageMobile = this.viewLargeImageMobile.bind(this);
  }

  componentWillMount() {
    axios
      .get("/wp-json/wp/v2/productinfo/?slug=" + this.state.productName)
      .then((res) => {
        this.setState(
          {
            products: res.data,
            colorid:
              res.data[0].acf.product_details_colorways[0]
                .product_details_colorway_color_swatch.id,
            backgroundImage:
              res.data[0].acf.product_details_colorways[0]
                .product_details_colorway_color_swatch.url,
            selectedColor:
              res.data[0].acf.product_details_colorways[0]
                .product_details_colorways_color_name,
            productDesignID: res.data[0].acf.design_id,
            isSignatureNow: res.data[0].acf.signature_now_product,
            sparkID: res.data[0].acf.product_details_colorways[0].spark_id,           
          },
          () => {
            if (this.state.productDesignID === "") {
              this.setState({ productDesignID: 0 });
            }
            if (this.state.coordinates_customize_id === "") {
              this.setState({ coordinates_customize_id: 0 });
            }
            if (this.state.coordinates_customize_sim_id === "") {
              this.setState({ coordinates_customize_sim_id: 0 });
            }

            this.getResources(this.state.products);
          }
        );
        console.log(this.state.products);
        //var cartcount = parseInt(localStorage.getItem('cartitemcount'))

        //var newcartcount = cartcount+ 1
        //localStorage.setItem('cartitemcount', newcartcount)
      })
      .catch((errors) => console.log(errors));
  }

  getResources(product) {
    const warranties = this.state.warrantiesFound;
    const installations = this.state.installationsFound;
    const adhesives = this.state.adhesivesFound;
    const cads = this.state.cadsFound;
    const tests = this.state.testsFound;
    let productResources = [];
    productResources = product[0].acf.product_details_resources;
    if (productResources) {
      productResources.forEach(function(resource) {
        if (resource.product_details_resources_resource_type === "Warranties") {
          warranties.push(resource);
        }

        if (
          resource.product_details_resources_resource_type ===
          "Installation/Maintenance"
        ) {
          installations.push(resource);
        }

        if (resource.product_details_resources_resource_type === "Adhesives") {
          adhesives.push(resource);
        }

        if (resource.product_details_resources_resource_type === "CAD") {
          cads.push(resource);
        }

        if (resource.product_details_resources_resource_type === "Testing") {
          tests.push(resource);
        }
      });
    }
    this.setState(
      {
        warrantiesFound: warranties,
        installationsFound: installations,
        adhesivesFound: adhesives,
        cadsFound: cads,
        testsFound: tests
      },
      () => {
        console.log("warranties", this.state.warrantiesFound);
        console.log("installations", this.state.installationsFound);
        console.log("adhesives", this.state.adhesivesFound);
        console.log("cads", this.state.cadsFound);
        console.log("tests", this.state.testsFound);
      }
    );
  }

  componentDidUpdate() {}

  viewLargeImageMobile(
    imageType,
    newImage,
    colorid = this.state.colorid,
    colorName,
    styleId = 0,
    productDesignID = 0
  ) {
    this.setState({
      backgroundImage: newImage,
      selectedColor: colorName,
      colorid: colorid,
      styleId: styleId,
      imageType: imageType,
      open: true,
    });
  }

  OrderSample() {
    var cartproduct = {};
    cartproduct.id = this.state.colorid;
    cartproduct.name = this.state.productName;
    cartproduct.color = this.state.selectedColor;
    cartproduct.imageURL = this.state.backgroundImage;
    cartproduct.quantity = 1;
    cartproduct.styleId = this.state.styleId;
    var shoppingcart = {};

    if (localStorage && localStorage.getItem("shoppingcart")) {
      shoppingcart = JSON.parse(localStorage.getItem("shoppingcart"));
      const doesExist = shoppingcart.find((o) => o.color === cartproduct.color);
      if (doesExist === undefined) {
        if (cartproduct.color) {
          shoppingcart.unshift(cartproduct);
          localStorage.setItem("shoppingcart", JSON.stringify(shoppingcart));
        }
      }
    } else {
      shoppingcart = [];
      if (cartproduct.color !== null) {
        shoppingcart.unshift(cartproduct);
        localStorage.setItem("shoppingcart", JSON.stringify(shoppingcart));
      }
    }
    localStorage.setItem("cartitemcount", shoppingcart.length);
    this.setState({ open: true });

    /*ReactGA.event({
      category: "Samples Cart",
      action: this.state.productName + " added to samples cart",
      label: this.state.selectedColor,
    });*/
  }

  changeLargeImage(
    imageType,
    newImage,
    colorid = this.state.colorid,
    colorName,
    styleId = 0,
    productDesignID = 0,
    sparkid = this.state.sparkID,
  ) {
    this.setState({
      backgroundImage: newImage,
      selectedColor: colorName,
      colorid: colorid,
      styleId: styleId,
      imageType: imageType,
      sparkID: sparkid,
    });
  }
    changeLargeCoordImage(
      imageType,
      newImage,
      coordinates_customize_id,
      coordinates_customize_sim_id      
    ) {
      this.setState({
        imageType: imageType,
        backgroundImage: newImage,        
        coordinates_customize_sim_id: coordinates_customize_sim_id,
        coordinates_customize_id: coordinates_customize_id
        
      });
  }

  closemodal() {
    this.setState({ open: false });
  }

  showHide(num) {
    if (num === 0) {
      this.setState({
        showSwatch: [true, false, false],
        swatchChangerClass: ["active-link", "nonactive-link", "nonactive-link"],
      });
    }
    if (num === 1) {
      this.setState({
        showSwatch: [false, true, false],
        swatchChangerClass: ["nonactive-link", "active-link", "nonactive-link"],
      });
    }
    if (num === 2) {
      this.setState({
        showSwatch: [false, false, true],
        swatchChangerClass: ["nonactive-link", "nonactive-link", "active-link"],
      });
    }
  }

  showHideMobile(num) {
    if (num === 0) {
      this.setState({
        showSwatchMobile: [true, false, false],
        swatchChangerClassMobile: [
          "active-link",
          "nonactive-link",
          "nonactive-link",
        ],
      });
    }
    if (num === 1) {
      this.setState({
        showSwatchMobile: [false, true, false],
        swatchChangerClassMobile: [
          "nonactive-link",
          "active-link",
          "nonactive-link",
        ],
      });
    }
    if (num === 2) {
      this.setState({
        showSwatchMobile: [false, false, true],
        swatchChangerClassMobile: [
          "nonactive-link",
          "nonactive-link",
          "active-link",
        ],
      });
    }
  }

  buttonWidth(labelLength) {
    return labelLength * 8;
  }

  render() {
    if (this.state.products === null) return null;
    const {
      SignatureFlooringSettings,
      backgroundImage,
      products,
      showSwatch,
      showSwatchMobile,
      swatchChangerClass,
      swatchChangerClassMobile,
      colorid,
      warrantiesFound,
      installationsFound,
      adhesivesFound,
      cadsFound,
      testsFound
    } = this.state;
    const notBroadloom = ["Rugs", "LVT", "WPC", "Loose Lay", "Entryway System", "SPC", "Rubber Wall Base"];
    const notRugs = ["Broadloom", "Carpet Tile", "LVT", "WPC", "Loose Lay", "Entryway System", "Cove Base", "SPC", "Rubber Wall Base"];
    const notHardSurface = ["Broadloom", "Carpet Tile", "Rugs", "Entryway System", "Cove Base"];
    const notEntryway = ["Broadloom", "Rugs", "LVT", "WPC", "Loose Lay", "Carpet Tile", "Cove Base", "SPC", "Rubber Wall Base"];
    return (
      <div>
        <div className="product-page-desktop"> 
          <div className="product-page">
            {products.map((product) => (
              <div className="product-page-wrapper" key={product.id}>
                <div className="product-top">
                  <div className="product-breadcrumb">
                    <a
                      href={
                        SignatureFlooringSettings.path +
                        product.acf.product_type +
                        "/" +
                        product.acf.product_category
                      }
                    >
                      <i className="fa fa-arrow-left" /> Back to{" "}
                      {product.acf.product_category}
                    </a>
                  </div>
                  <div className="product-swatches">
                    <div className="product-swatches left">
                      <div className="product-title">
                        <h1>{product.title.rendered}</h1>
                      </div>
                      <div className="product-swatch-changer">
                        <a
                          href="##"
                          onClick={() => this.showHide(0)}
                          className={swatchChangerClass[0]}
                        >
                          Room Scenes
                        </a>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {product.acf.product_category === "Rugs" ? (
                          <a
                            href="##"
                            className={swatchChangerClass[1]}
                            onClick={() => this.showHide(1)}
                          >
                            Sizes
                          </a>
                        ) : (
                          <a
                            href="##"
                            className={swatchChangerClass[1]}
                            onClick={() => this.showHide(1)}
                          >
                            Colorways
                          </a>
                        )}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {product.acf.coordinates && (
                          <a
                            href="##"
                            className={swatchChangerClass[2]}
                            onClick={() => this.showHide(2)}
                          >
                            Coordinates
                          </a>
                        )}
                      </div>
                      {showSwatch[0] && (
                        <div className="colourway-roomscenes">
                          <div className="product-swatches-small">
                            {product.acf.product_details_roomscenes.map(
                              (roomscenes) => (
                                <div
                                  className="product-swatches-small swatch-box"
                                  key={
                                    roomscenes
                                      .product_details_roomscenes_room_scene.id
                                  }
                                >
                                  <a
                                    href="##"
                                    onClick={() =>
                                      this.changeLargeImage(
                                        "rs",
                                        roomscenes
                                          .product_details_roomscenes_room_scene
                                          .url,
                                        product.acf.design_id
                                      )
                                    }
                                  >
                                    <img
                                      src={
                                        roomscenes
                                          .product_details_roomscenes_room_scene
                                          .url
                                      }
                                      height={80}
                                      width={80}
                                      alt={
                                        roomscenes.product_details_color_name
                                      }
                                    />
                                    <p>
                                      {
                                        roomscenes.product_details_roomscenes_color_name
                                      }
                                      <br />
                                      <span>
                                        {
                                          roomscenes.product_details_roomscenes_color_id
                                        }
                                      </span>
                                    </p>
                                  </a>
                                </div>
                              )
                            )}
                          </div>
                          {product.acf.custom_verbiage && (
                          <div>
                            <p>
                            {product.acf.custom_verbiage}  
                            </p>
                          </div>
                          )}
                        </div>
                      )}
                      {showSwatch[1] && (
                        <div className="colourway-swatches">
                          <div className="product-swatches-small">
                            {product.acf.product_details_colorways.map(
                              (colorswatchs) => (
                                <div
                                  className="product-swatches-small swatch-box"
                                  key={
                                    colorswatchs
                                      .product_details_colorway_color_swatch.id
                                  }
                                >
                                  <a
                                    href="##"
                                    onClick={() =>
                                      this.changeLargeImage(
                                        "cw",
                                        colorswatchs
                                          .product_details_colorway_color_swatch
                                          .url,
                                        colorswatchs
                                          .product_details_colorway_color_swatch
                                          .id,
                                        colorswatchs.product_details_colorways_color_name,
                                        product.acf.style_number,
                                        product.acf.design_id,
                                        colorswatchs.spark_id
                                      )
                                    }
                                  >
                                    <img
                                      src={
                                        colorswatchs
                                          .product_details_colorway_color_swatch
                                          .url
                                      }
                                      height={80}
                                      width={80}
                                      alt={
                                        colorswatchs.product_details_colorways_color_name
                                      }
                                    />
                                    <p>
                                      {
                                        colorswatchs.product_details_colorways_color_name
                                      }
                                      <br />
                                      <span>
                                        {
                                          colorswatchs.product_details_colorway_color_id
                                        }
                                      </span>
                                    </p>
                                  </a>
                                </div>
                              )
                            )}
                          </div>
						  
						  {/* Show Custom Colorway Verbiage, if it has been populated */}
						  {product.acf.custom_colorways_verbiage && (
                          <div>
                            <p>
                            {product.acf.custom_colorways_verbiage}  
                            </p>
                          </div>
                          )}
						  
                          {product.acf.product_category !== "Rugs" && (
                            <div
                              className="sample-button-div"
                              style={{ textAlign: "left" }}
                            >
                              <button
                                className="sample-button"
                                id="orderSampleButton"
                                data-id={colorid}
                                onClick={this.OrderSample}
                              >
                                Order Sample
                              </button>
                              <Popup
                                open={this.state.open}
                                onClose={this.closemodal}
                                modal
                                lockScroll={true}
                                position="top center"
                              >
                                {(close) => <SampleCart close={close} />}
                              </Popup>
                            </div>
                          )}
                          {product.acf.product_category === "Rugs" && (
                            <a href="/salesreps">
                              <button
                                className="rug-button"
                                id="orderSampleButton"
                              >
                                Find a Rep
                              </button>
                            </a>
                          )}
                        </div>
                      )}
                      {showSwatch[2] && (
                        <div className="coordinate-swatches">
                          {product.acf.coordinates && (
                            <div className="product-swatches-small">
                              {product.acf.coordinates.map((coord) => (
                                <div
                                  className="product-swatches-small swatch-box"
                                  key={coord.room_scene.id}
                                >
                                <a
                                    href="##"
                                    onClick={() =>
                                      this.changeLargeCoordImage(
                                        "coord",
                                        coord
                                          .room_scene
                                          .url,
                                        coord.coordinates_customize_id,
                                        coord.coordinates_customize_sim_id,
                                      )
                                    }
                                  >
                                    <img
                                      src={coord.room_scene.url}
                                      height={80}
                                      width={80}
                                      alt={coord.style_name}
                                    />
                                    <p>{coord.style_name}</p>
                                  </a>
                                </div>
                              ))}
                            </div>
                          )}
                          {product.acf.coordinate_sheet && (
                            <div
                              className="sample-button-div"
                              style={{ textAlign: "left" }}
                            >
                              <a
                                href={product.acf.coordinate_sheet.url}
                                target="_new"
                                className="sample-button"
                                style={{
                                  textAlign: "center",
                                  textDecoration: "none",
                                }}
                              >
                                Coordinate Sheet
                              </a>
                            </div>
                          )}
                          {product.acf.custom_verbiage && (
                          <div>
                            <p>
                            {product.acf.custom_verbiage}  
                            </p>
                          </div>
                          )}                          
                        </div>                
                      )}
                    </div>
                      <ProductLargeImage
                        backgroundImage={backgroundImage}
                        productDesignID={this.state.productDesignID}
                        imageType={this.state.imageType}
                        isSignatureNow={this.state.isSignatureNow}
                        sparkID={this.state.sparkID}
                        coordinates_customize_sim_id={this.state.coordinates_customize_sim_id}
                        coordinates_customize_id={this.state.coordinates_customize_id}
                      />
                  </div>
                </div>
                {/* End Product Top*/}
                {/* Product Description and Brochure*/}
                <div
                  className="product-description"
                  style={{
                    background:
                      'linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.5)),url("' +
                      product.acf.product_details_roomscenes[0]
                        .product_details_roomscenes_room_scene.url +
                      '")',
                    backgroundAttachment: "fixed",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    height: "600px",
                  }}
                >
                  <div className="product-description-text">
                    <p
                      className="product-description-heading"
                      style={{ textAlign: "center" }}
                    >
                      {product.acf.product_details_description_paragraph_1}
                    </p>
                    <p
                      className="product-description-tag"
                      style={{ textAlign: "center" }}
                      dangerouslySetInnerHTML={{
                        __html:
                          product.acf.product_details_description_paragraph_2,
                      }}
                    />
                    <p
                      className="product-description-brochure"
                      style={{ textAlign: "center" }}
                    >
                      {product.acf.brochure && (
                        <a
                          href={product.acf.brochure.url}
                          target="_new"
                          className="brochure-button"
                        >
                          Download Product Brochure
                        </a>
                      )}&nbsp;
                      {product.acf.additional_buttons && 
                        <>
                        {product.acf.additional_buttons.map((item) => (
                          <>
                          <a
                          href={item.button_file.url}
                          target="_new"
                          className="brochure-button"
                          style={{marginRight: '5px', width: this.buttonWidth(item.button_label.length)}}
                        >
                          {item.button_label}
                        </a>
                        </>
                        ))}
                        </>
                      }&nbsp;
                       {product.acf.video_link && (
                         <a
                          href={product.acf.video_link}
                          target="_new"
                          className="brochure-button"
                        >
                          View Video
                        </a>
                      )}
                    </p>
                  </div>
                </div>
                {/* End Product Description and Brochure */}
                {/* Begin Product Specs */}
                <div className="product-specs">
                  <div className="product-specs-header">
                    <h2>Specifications</h2>
                  </div>
                  <div className="product-specs-details">
                    {notBroadloom.indexOf(product.acf.product_category) ===
                      -1 && (
                      <Table size="lg" borderless>
                        <tbody>
                          <tr>
                            <td className="spec-title">Construction</td>
                            <td className="spec-data">
                              {product.acf.product_details_construction}
                            </td>
                            <td className="spec-title">Finished Pile Height</td>
                            <td className="spec-data">
                              {product.acf.product_details_finished_pile_height}
                            </td>
                          </tr>
                          <tr>
                            <td className="spec-title">Gauge</td>
                            <td className="spec-data">
                              {product.acf.product_details_guage}
                            </td>
                            {//Check if message failed
                            product.acf.product_category === "Carpet Tile" ? (
                              <td className="spec-title">Sizes</td>
                            ) : (
                              <td className="spec-title">Width</td>
                            )}
                            <td
                              className="spec-data"
                              dangerouslySetInnerHTML={{
                                __html: product.acf.product_details_width,
                              }}
                            />
                          </tr>
                          <tr>
                            <td className="spec-title">Pile Fiber</td>
                            <td className="spec-data">
                              {product.acf.product_details_pile_fiber}
                            </td>
                            <td className="spec-title">Pattern Repeat</td>
                            <td className="spec-data">
                              {product.acf.product_details_pattern_repeat}
                            </td>
                          </tr>
                          <tr>
                            <td className="spec-title">Tufted Face Weight</td>
                            <td className="spec-data">
                              {product.acf.product_details_tufted_face_weight}
                            </td>
                            <td className="spec-title">Primary Backing</td>
                            <td className="spec-data">
                              {product.acf.product_details_primary_backing}
                            </td>
                          </tr>
                          <tr>
                            <td className="spec-title">Dye Method</td>
                            <td className="spec-data">
                              {product.acf.product_details_dye_method}
                            </td>
                            <td className="spec-title">Secondary Backing</td>
                            <td className="spec-data">
                              {product.acf.product_details_secondary_backing}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    )}

                    {notEntryway.indexOf(product.acf.product_category) ===
                      -1 && (
                      <Table size="lg" borderless>
                        <tbody>
                          <tr>
                            <td className="spec-title">Construction</td>
                            <td className="spec-data">
                              {product.acf.product_details_construction}
                            </td>
                            <td className="spec-title">Sizes</td>
                            <td className="spec-data">
                              6’7” or 13’2” or Custom Sizes Available
                            </td>
                          </tr>
                          <tr>
                            <td className="spec-title">Pile Height</td>
                            <td className="spec-data">
                              {product.acf.product_details_guage}
                            </td>
                            <td className="spec-title">Total Height</td>
                            <td>0.47" Approximately</td>
                          </tr>
                          <tr>
                            <td className="spec-title">Pile Fiber</td>
                            <td className="spec-data">
                              {product.acf.product_details_pile_fiber}
                            </td>
                            <td className="spec-title">Pile Weight</td>
                            <td className="spec-data">
                              {product.acf.product_details_tufted_face_weight}
                            </td> 
                          </tr>
                          <tr>
                            <td className="spec-title">Edging</td>
                            <td className="spec-data">
                              High Profile Vinyl Available
                            </td>
                            
                            <td className="spec-title">Total Weight</td>
                            <td className="spec-data">
                              78 oz. per sq. yd.
                            </td>
                          </tr>
						  <tr>
                            <td className="spec-title">Secondary Backing</td>
                            <td className="spec-data">
                              {product.acf.product_details_secondary_backing}
                            </td>
							
							<td className="spec-title">&nbsp;</td>
                            <td className="spec-data">
                              &nbsp;
                            </td>
                          </tr>
                          
                        </tbody>
                      </Table>
                    )}

                    {notRugs.indexOf(product.acf.product_category) === -1 && (
                      <div className="rug-specs-wrapper">
                        <div className="rug=specs-left">
                          <p className="rug-spec-title">
                            Construction{" "}
                            <span className="rug-spec-data">
                              {product.acf.product_details_construction}
                            </span>
                          </p>
                          <p className="rug-spec-title">
                            Sizes
                            <span className="rug-spec-data">
                              {product.acf.rug_sizes.map((rs) => (
                                <div key={rs.size}>{rs.size}</div>
                              ))}
                            </span>
                          </p>
                        </div>
                        <div className="rug-specs-right">
                          <p className="rug-spec-title">
                            Guage{" "}
                            <span className="rug-spec-data">
                              {product.acf.product_details_guage}
                            </span>
                          </p>
                          <p className="rug-spec-title">
                            Pile Fiber{" "}
                            <span className="rug-spec-data">
                              {product.acf.product_details_pile_fiber}
                            </span>
                          </p>
                          <p className="rug-spec-title">
                            Tufted Face Weight{" "}
                            <span className="rug-spec-data">
                              {product.acf.product_details_tufted_face_weight}
                            </span>
                          </p>
                          <p className="rug-spec-title">
                            Dye Method{" "}
                            <span className="rug-spec-data">
                              {product.acf.product_details_dye_method}
                            </span>
                          </p>
                        </div>
                      </div>
                    )}
                    {notHardSurface.indexOf(product.acf.product_category) ===
                      -1 && (
                      <div className="hs-specs-wrapper">
                        <div className="hs=specs-left">
                          <p className="hs-spec-title">
                            Size{" "}
                            <span className="hs-spec-data">
                              {product.acf.hardsurface_size}
                            </span>
                          </p>
                          <p className="hs-spec-title">
                            Construction{" "}
                            <span className="hs-spec-data">
                              {product.acf.product_details_construction}
                            </span>
                          </p>
                          
						  {/* Only Show This Spec Title and Data If It Has Been Populated In The WordPress Product Record */}
						  {product.acf.wear_layers_thickness && (
						  <p className="hs-spec-title">
                            Wear Layers Thickness{" "}
                            <span className="hs-spec-data">
                              {product.acf.wear_layers_thickness}
                            </span>
                          </p>
						  )}
                          
						  <p className="hs-spec-title">
                            Overall Thickness{" "}
                            <span className="hs-spec-data">
                              {product.acf.overall_thickness}
                            </span>
                          </p>
						  
                        </div>						
                        <div className="hs-specs-right">
                          
						  {/* Only Show This Spec Title and Data If It Has Been Populated In The WordPress Product Record */}
						  {product.acf.bevel && (
						  <p className="hs-spec-title">
                            Bevel{" "}
                            <span className="hs-spec-data">
                              {product.acf.bevel}
                            </span>
                          </p>
						  )}
						  
						  {/* Only Show This Spec Title and Data If It Has Been Populated In The WordPress Product Record */}
						  {product.acf.texture && (
                          <p className="hs-spec-title">
                            Texture{" "}
                            <span className="hs-spec-data">
                              {product.acf.texture}
                            </span>
                          </p>
						  )}
						  
						  {/* Only Show This Spec Title and Data If It Has Been Populated In The WordPress Product Record */}
						  {product.acf.install_methods && (
                          <p className="hs-spec-title">
                            Install Method{" "}
                            <span className="hs-spec-data">
                              {product.acf.install_methods}
                            </span>
                          </p>
						  )}
						  
						  {/* Only Show This Spec Title and Data If It Has Been Populated In The WordPress Product Record */}
						  {product.acf.finish && (
                          <p className="hs-spec-title">
                            Finish{" "}
                            <span className="hs-spec-data">
                              {product.acf.finish}
                            </span>
                          </p>
						  )}
						  
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="product-specs-download">
                    {product.acf.spec_sheet && (
                      <a
                        href={product.acf.spec_sheet.url}
                        target="_new"
                        className="product-specs-download-button"
                      >
                        Download Full Specs
                      </a>
                    )}
                  </div>
                </div>
                {/* End Product Specs */}
                {/* Begin Product Resources */}
                <div className="product-resources">
                  <div className="product-resources-header">
                    <h2>Resources</h2>
                  </div>
                  <div className="product-resources-details">
                    <div className="product-resource-details-column">
                      <h5 className="spec-title">Warranties</h5>
                      {warrantiesFound.map((warranty) => (
                        <p
                          className="spec-data"
                          key={warranty.product_details_resources_resource_name}
                        >
                          <a
                            href={
                              warranty.product_details_resources_resource_file
                            }
                            target="_new"
                          >
                            {warranty.product_details_resources_resource_name}
                          </a>
                        </p>
                      ))}
                      { cadsFound.length > 0 &&
                        <>
                        <h5 className="spec-title">CADS</h5>
                        {cadsFound.map((cad) => (
                          <p
                            className="spec-data"
                            key={cad.product_details_resources_resource_name}
                          >
                            <a
                              href={
                                cad.product_details_resources_resource_file
                              }
                              target="_new"
                            >
                              {cad.product_details_resources_resource_name}
                            </a>
                          </p>
                        ))}
                        </>
                      }

                    </div>
                    <div className="product-resource-details-column">
                      <h5 className="spec-title">Installation/Maintenance</h5>
                      {installationsFound.map((installation) => (
                        <p
                          className="spec-data"
                          key={
                            installation.product_details_resources_resource_name
                          }
                        >
                          <a
                            href={
                              installation.product_details_resources_resource_file
                            }
                            target="_new"
                          >
                            {
                              installation.product_details_resources_resource_name
                            }
                          </a>
                        </p>
                      ))}

                      { testsFound.length > 0 &&
                        <>
                        <h5 className="spec-title">Testing</h5>
                        {testsFound.map((test) => (
                          <p
                            className="spec-data"
                            key={test.product_details_resources_resource_name}
                          >
                            <a
                              href={
                                test.product_details_resources_resource_file
                              }
                              target="_new"
                            >
                              {test.product_details_resources_resource_name}
                            </a>
                          </p>
                        ))}
                        </>
                      }

                    </div>
                    {adhesivesFound.length > 0 && (
                      <div className="product-resource-details-column">
                        <h5 className="spec-title">Adhesives</h5>
                        {adhesivesFound.map((adhesive) => (
                          <p
                            className="spec-data"
                            key={
                              adhesive.product_details_resources_resource_name
                            }
                          >
                            <a
                              href={
                                adhesive.product_details_resources_resource_file
                              }
                              target="_new"
                            >
                              {adhesive.product_details_resources_resource_name}
                            </a>
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="product-page-mobile">
          <div className="mobile-product-page">
            {products.map((product) => (
              <div className="product-page-wrapper-mobile" key={product.id}>
                <div className="product-top-mobile">
                  <div className="product-breadcrumb-mobile">
                    <a
                      href={
                        SignatureFlooringSettings.path +
                        product.acf.product_type +
                        "/" +
                        product.acf.product_category
                      }
                    >
                      <i className="fa fa-arrow-left" /> Back to{" "}
                      {product.acf.product_category}
                    </a>
                  </div>
                  <div className="product-title-mobile">
                    <h1>{product.title.rendered}</h1>
                  </div>
                </div>
                <div className="product-swatch-changer-mobile">
                  <a
                    href="##"
                    onClick={() => this.showHideMobile(0)}
                    className={swatchChangerClassMobile[0]}
                  >
                    Room Scenes
                  </a>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {product.acf.product_category === "Rugs" ? (
                    <a
                      href="##"
                      className={swatchChangerClassMobile[1]}
                      onClick={() => this.showHideMobile(1)}
                    >
                      Sizes
                    </a>
                  ) : (
                    <a
                      href="##"
                      className={swatchChangerClassMobile[1]}
                      onClick={() => this.showHideMobile(1)}
                    >
                      Colorways
                    </a>
                  )}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {product.acf.coordinates && (
                    <a
                      href="##"
                      className={swatchChangerClassMobile[2]}
                      onClick={() => this.showHideMobile(2)}
                    >
                      Coordinates
                    </a>
                  )}
                </div>
                {showSwatchMobile[0] && (
                  <div className="colourway-roomscenes-mobile">
                    <div className="product-swatches-small-mobile">
                      {product.acf.product_details_roomscenes.map(
                        (roomscenes) => (
                          <div
                            className="product-swatches-small swatch-box"
                            key={
                              roomscenes.product_details_roomscenes_room_scene
                                .id
                            }
                          >
                            <a href="##">
                              {/*<a href="##" onClick={() => this.changeLargeImage('rs',roomscenes.product_details_roomscenes_room_scene.url, product.acf.design_id)}>*/}
                              <img
                                src={
                                  roomscenes
                                    .product_details_roomscenes_room_scene.url
                                }
                                height={127}
                                width={127}
                                alt={roomscenes.product_details_color_name}
                              />
                              <p>
                                {
                                  roomscenes.product_details_roomscenes_color_name
                                }
                                <br />
                                <span>
                                  {
                                    roomscenes.product_details_roomscenes_color_id
                                  }
                                </span>
                              </p>
                            </a>
                          </div>
                        )
                      )}
                    </div>
                    {product.acf.custom_verbiage && (
                          <div>
                            <p>
                            {product.acf.custom_verbiage}  
                            </p>
                          </div>
                    )}
                  </div>
                )}
                {showSwatchMobile[1] && (
                  <div className="colourway-swatches-mobile">
                    <div className="product-swatches-small-mobile">
                      {product.acf.product_details_colorways.map(
                        (colorswatchs) => (
                          <div
                            className="product-swatches-small-mobile swatch-box"
                            key={
                              colorswatchs.product_details_colorway_color_swatch
                                .id
                            }
                          >
                            <a
                              href="##"
                              onClick={() =>
                                this.viewLargeImageMobile(
                                  "cw",
                                  colorswatchs
                                    .product_details_colorway_color_swatch.url,
                                  colorswatchs
                                    .product_details_colorway_color_swatch.id,
                                  colorswatchs.product_details_colorways_color_name,
                                  product.acf.style_number,
                                  product.acf.design_id
                                )
                              }
                            >
                              {/*<a href="##" onClick={this.OrderSample}>*/}
                              <img
                                src={
                                  colorswatchs
                                    .product_details_colorway_color_swatch.url
                                }
                                height={127}
                                width={127}
                                alt={
                                  colorswatchs.product_details_colorways_color_name
                                }
                              />
                              <p>
                                {
                                  colorswatchs.product_details_colorways_color_name
                                }
                                <br />
                                <span>
                                  {
                                    colorswatchs.product_details_colorway_color_id
                                  }
                                </span>
                              </p>
                            </a>
                            {
                              <Popup
                                open={this.state.open}
                                onClose={this.closemodal}
                                modal
                                lockScroll={true}
                                position="top center"
                              >
                                {(close) => (
                                  <SampleCartMobile
                                    close={close}
                                    backgroundImage={backgroundImage}
                                    selectedColor={this.state.selectedColor}
                                    productName={product.title.rendered}
                                    colorid={this.state.colorid}
                                    productDesignID={this.state.productDesignID}
                                    imageType={this.state.imageType}
                                    styleId={this.state.styleId}
                                  />
                                )}
                              </Popup>
                            }
                          </div>
                        )
                      )}
                    </div>
					
					{/* Show Custom Colorway Verbiage, if it has been populated */}
					  {product.acf.custom_colorways_verbiage && (
					  <div>
						<p>
						{product.acf.custom_colorways_verbiage}  
						</p>
					  </div>
					  )}
					
                  </div>			  
                )}
                {showSwatchMobile[2] && (
                  <div className="coordinate-swatches-mobile">
                    {product.acf.coordinates && (
                      <div className="product-swatches-small-mobile">
                        {product.acf.coordinates.map((coord) => (
                          <div
                            className="product-swatches-small swatch-box"
                            key={coord.room_scene.id}
                          >
                            <a href="##">
                              {/*<a href={SignatureFlooringSettings.path + "productinfo/" + coord.style_link}>*/}
                              <img
                                src={coord.room_scene.url}
                                height={127}
                                width={127}
                                alt={coord.style_name}
                              />
                              <p>{coord.style_name}</p>
                            </a>
                          </div>
                        ))}
                      </div>
                    )}
                    {product.acf.custom_verbiage && (
                          <div>
                            <p>
                            {product.acf.custom_verbiage}  
                            </p>
                          </div>
                    )}
                  </div>
                )}
                <div
                  className="product-description"
                  style={{
                    background:
                      'linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.5)),url("' +
                      product.acf.product_details_roomscenes[0]
                        .product_details_roomscenes_room_scene.url +
                      '")',
                    backgroundAttachment: "fixed",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    height: "600px",
                  }}
                >
                  <div className="product-description-text-mobile">
                    <p
                      className="product-description-heading"
                      style={{ textAlign: "center", fontSize: "20px" }}
                    >
                      {product.acf.product_details_description_paragraph_1}
                    </p>
                    <p
                      className="product-description-tag"
                      style={{ textAlign: "center" }}
                      dangerouslySetInnerHTML={{
                        __html:
                          product.acf.product_details_description_paragraph_2,
                      }}
                    />
                    <p
                      className="product-description-brochure"
                      style={{ textAlign: "center" }}
                    >
                      {product.acf.brochure && (
                        <a
                          href={product.acf.brochure.url}
                          target="_new"
                          className="brochure-button"
                        >
                          Download Product Brochure
                        </a>
                      )}
                    </p>
                  </div>
                </div>
                <div className="product-specs-mobile">
                  <div
                    className="product-specs-header"
                    style={{ textAlign: "center" }}
                  >
                    <h2>Specifications</h2>
                  </div>
                  {notBroadloom.indexOf(product.acf.product_category) ===
                      -1 && (
                      <Table size="lg" borderless>
                        <tbody>
                          <tr>
                            <td className="spec-title">Construction</td>
                            <td className="spec-data">
                              {product.acf.product_details_construction}
                            </td>
                            <td className="spec-title">Finished Pile Height</td>
                            <td className="spec-data">
                              {product.acf.product_details_finished_pile_height}
                            </td>
                          </tr>
                          <tr>
                            <td className="spec-title">Gauge</td>
                            <td className="spec-data">
                              {product.acf.product_details_guage}
                            </td>
                            {//Check if message failed
                            product.acf.product_category === "Carpet Tile" ? (
                              <td className="spec-title">Sizes</td>
                            ) : (
                              <td className="spec-title">Width</td>
                            )}
                            <td
                              className="spec-data"
                              dangerouslySetInnerHTML={{
                                __html: product.acf.product_details_width,
                              }}
                            />
                          </tr>
                          <tr>
                            <td className="spec-title">Pile Fiber</td>
                            <td className="spec-data">
                              {product.acf.product_details_pile_fiber}
                            </td>
                            <td className="spec-title">Pattern Repeat</td>
                            <td className="spec-data">
                              {product.acf.product_details_pattern_repeat}
                            </td>
                          </tr>
                          <tr>
                            <td className="spec-title">Tufted Face Weight</td>
                            <td className="spec-data">
                              {product.acf.product_details_tufted_face_weight}
                            </td>
                            <td className="spec-title">Primary Backing</td>
                            <td className="spec-data">
                              {product.acf.product_details_primary_backing}
                            </td>
                          </tr>
                          <tr>
                            <td className="spec-title">Dye Method</td>
                            <td className="spec-data">
                              {product.acf.product_details_dye_method}
                            </td>
                            <td className="spec-title">Secondary Backing</td>
                            <td className="spec-data">
                              {product.acf.product_details_secondary_backing}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    )}


{notEntryway.indexOf(product.acf.product_category) ===
                    -1 && (
                    <div>
                      <div className="spec-group-mobile">
                        <span className="spec-title-mobile">Construction</span>
                        <br />
                        <span className="spec-data-mobile">
                          {product.acf.product_details_construction}
                        </span>
                      </div>
                      <div className="spec-group-mobile">
                        <span className="spec-title-mobile">Sizes</span>
                        <br />
                        <span className="spec-data-mobile">
                          6'7" or 13'2"
                        </span>
                      </div>
                      <div className="spec-group-mobile">
                        <span className="spec-title-mobile">Gauge</span>
                        <br />
                        <span className="spec-data-mobile">
                          {product.acf.product_details_guage}
                        </span>
                      </div>
                      <div className="spec-group-mobile">
                        <span className="spec-title-mobile">Pile Fiber</span>
                        <br />
                        <span className="spec-data-mobile">
                          {product.acf.product_details_pile_fiber}
                        </span>
                      </div>
                      <div className="spec-group-mobile">
                        <span className="spec-title-mobile">
                          Tufted Pile Weight
                        </span>
                        <br />
                        <span className="spec-data-mobile">
                          {product.acf.product_details_tufted_face_weight}
                        </span>
                      </div>
                      <div className="spec-group-mobile">
                        <span className="spec-title-mobile">
                          Total Thickness
                        </span>
                        <br />
                        <span className="spec-data-mobile">
                          .47"
                        </span>
                      </div>
                      <div className="spec-group-mobile">
                        <span className="spec-title-mobile">
                          Secondary Backing
                        </span>
                        <br />
                        <span className="spec-data-mobile">
                          {product.acf.product_details_secondary_backing}
                        </span>
                      </div>
                      <div className="spec-group-mobile">
                        <span className="spec-title-mobile">
                          Edging
                        </span>
                        <br />
                        <span className="spec-data-mobile">
                          High Profile Vinyl
                        </span>
                      </div>
                    </div>
                  )}

                  

                  {notRugs.indexOf(product.acf.product_category) === -1 && (
                    <div>
                      <div className="spec-group-mobile">
                        <span className="spec-title-mobile">Construction</span>
                        <br />
                        <span className="spec-data-mobile">
                          {product.acf.product_details_construction}
                        </span>
                      </div>
                      <div className="spec-group-mobile">
                        <span className="spec-title-mobile">Sizes</span>
                        <br />
                        <span className="spec-data-mobile">
                          {product.acf.rug_sizes.map((rs) => (
                            <div key={rs.size}>{rs.size}</div>
                          ))}
                        </span>
                      </div>
                      <div className="spec-group-mobile">
                        <span className="spec-title-mobile">Gauge</span>
                        <br />
                        <span className="spec-data-mobile">
                          {product.acf.product_details_guage}
                        </span>
                      </div>
                      <div className="spec-group-mobile">
                        <span className="spec-title-mobile">Pile Fiber</span>
                        <br />
                        <span className="spec-data-mobile">
                          {product.acf.product_details_pile_fiber}
                        </span>
                      </div>
                      <div className="spec-group-mobile">
                        <span className="spec-title-mobile">
                          Tufted Face Weight
                        </span>
                        <br />
                        <span className="spec-data-mobile">
                          {product.acf.product_details_tufted_face_weight}
                        </span>
                      </div>
                      <div className="spec-group-mobile">
                        <span className="spec-title-mobile">Dye Method</span>
                        <br />
                        <span className="spec-data-mobile">
                          {product.acf.product_details_dye_method}
                        </span>
                      </div>
                    </div>
                  )}
                  {notHardSurface.indexOf(product.acf.product_category) ===
                    -1 && (
                    <div>
                      <div className="spec-group-mobile">
                        <span className="spec-title-mobile">Size</span>
                        <br />
                        <span className="spec-data-mobile">
                          {product.acf.hardsurface_size}
                        </span>
                      </div>
                      <div className="spec-group-mobile">
                        <span className="spec-title-mobile">Construction</span>
                        <br />
                        <span className="spec-data-mobile">
                          {product.acf.product_details_construction}
                        </span>
                      </div>
					  
					  {/* Only Show This Spec Title and Data If It Has Been Populated In The WordPress Product Record */}
					  {product.acf.wear_layers_thickness && (
                      <div className="spec-group-mobile">                        
						<span className="spec-title-mobile">
                          Wear Layers Thickness
                        </span>
                        <br />
                        <span className="spec-data-mobile">
                          {product.acf.wear_layers_thickness}
                        </span>
                      </div>
					  )}
					  
                      <div className="spec-group-mobile">
                        <span className="spec-title-mobile">
                          Overall Thickness
                        </span>
                        <br />
                        <span className="spec-data-mobile">
                          {product.acf.overall_thickness}
                        </span>
                      </div>
					  
					  {/* Only Show This Spec Title and Data If It Has Been Populated In The WordPress Product Record */}
					  {product.acf.bevel && (
                      <div className="spec-group-mobile">
                        <span className="spec-title-mobile">Bevel</span>
                        <br />
                        <span className="spec-data-mobile">
                          {product.acf.bevel}
                        </span>
                      </div>
					  )}
					  
					  {/* Only Show This Spec Title and Data If It Has Been Populated In The WordPress Product Record */}
					  {product.acf.texture && (
                      <div className="spec-group-mobile">
                        <span className="spec-title-mobile">Texture</span>
                        <br />
                        <span className="spec-data-mobile">
                          {product.acf.texture}
                        </span>
                      </div>
					  )}
					  
					  {/* Only Show This Spec Title and Data If It Has Been Populated In The WordPress Product Record */}
					  {product.acf.install_methods && (
                      <div className="spec-group-mobile">
                        <span className="spec-title-mobile">
                          Install Method
                        </span>
                        <br />
                        <span className="spec-data-mobile">
                          {product.acf.install_methods}
                        </span>
                      </div>
					  )}
					 
					 {/* Only Show This Spec Title and Data If It Has Been Populated In The WordPress Product Record */}
					  {product.acf.finish && (
                      <div className="spec-group-mobile">
                        <span className="spec-title-mobile">Finish</span>
                        <br />
                        <span className="spec-data-mobile">
                          {product.acf.finish}
                        </span>
                      </div>
					  )}
					  
                    </div>
                  )}
                  <div className="product-specs-download">
                    {product.acf.spec_sheet && (
                      <a
                        href={product.acf.spec_sheet.url}
                        target="_new"
                        className="product-specs-download-button"
                      >
                        Download Full Specs
                      </a>
                    )}
                  </div>
                  <div className="product-resources-mobile">
                    <div
                      className="product-resources-header"
                      style={{ textAlign: "center" }}
                    >
                      <h2>Resources</h2>
                    </div>
                    <div className="resource-sub-header-mobile">Warranties</div>
                    {warrantiesFound.map((warranty) => (
                      <div
                        className="spec-group-mobile"
                        key={warranty.product_details_resources_resource_name}
                      >
                        <span className="spec-data-mobile">
                          <a
                            style={{
                              fontFamily: "Adelle Sans",
                              fontSize: "12px",
                              color: "#25282A",
                              textDecoration: "none",
                            }}
                            href={
                              warranty.product_details_resources_resource_file
                            }
                            target="_new"
                          >
                            {warranty.product_details_resources_resource_name}
                          </a>
                        </span>
                      </div>
                    ))}

                    { cadsFound.length > 0 &&
                    <>
                    <br/>
                    <div className="resource-sub-header-mobile">CADS</div>
                    {cadsFound.map((cad) => (
                      <div
                        className="spec-group-mobile"
                        key={cad.product_details_resources_resource_name}
                      >
                        <span className="spec-data-mobile">
                          <a
                            style={{
                              fontFamily: "Adelle Sans",
                              fontSize: "12px",
                              color: "#25282A",
                              textDecoration: "none",
                            }}
                            href={
                              cad.product_details_resources_resource_file
                            }
                            target="_new"
                          >
                            {cad.product_details_resources_resource_name}
                          </a>
                        </span>
                      </div>
                    ))}
                    </>
                    } 

                    <br />
                    <div className="resource-sub-header-mobile">
                      Installation/Maintenance
                    </div>
                    {installationsFound.map((installation) => (
                      <div
                        className="spec-group-mobile"
                        key={
                          installation.product_details_resources_resource_name
                        }
                      >
                        <span className="spec-data-mobile">
                          <a
                            style={{
                              fontFamily: "Adelle Sans",
                              fontSize: "12px",
                              color: "#25282A",
                              textDecoration: "none",
                            }}
                            href={
                              installation.product_details_resources_resource_file
                            }
                            target="_new"
                          >
                            {
                              installation.product_details_resources_resource_name
                            }
                          </a>
                        </span>
                      </div>
                    ))}
                    { testsFound.length > 0 &&
                    <>
                    <br/>
                    <div className="resource-sub-header-mobile">Testing</div>
                    {testsFound.map((test) => (
                      <div
                        className="spec-group-mobile"
                        key={test.product_details_resources_resource_name}
                      >
                        <span className="spec-data-mobile">
                          <a
                            style={{
                              fontFamily: "Adelle Sans",
                              fontSize: "12px",
                              color: "#25282A",
                              textDecoration: "none",
                            }}
                            href={
                              test.product_details_resources_resource_file
                            }
                            target="_new"
                          >
                            {test.product_details_resources_resource_name}
                          </a>
                        </span>
                      </div>
                    ))}
                    </>
                    } 
                    <br />
                    {adhesivesFound.length > 0 && (
                      <div>
                        <div className="resource-sub-header-mobile">
                          Adhesives
                        </div>
                        {adhesivesFound.map((adhesive) => (
                          <div
                            className="spec-group-mobile"
                            key={
                              adhesive.product_details_resources_resource_name
                            }
                          >
                            <span className="spec-data-mobile">
                              <a
                                style={{
                                  fontFamily: "Adelle Sans",
                                  fontSize: "12px",
                                  color: "#25282A",
                                  textDecoration: "none",
                                }}
                                href={
                                  adhesive.product_details_resources_resource_file
                                }
                                target="_new"
                              >
                                {
                                  adhesive.product_details_resources_resource_name
                                }
                              </a>
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Product;
